import React from 'react';

import { appServerURL } from 'config/paths';
import Input from 'components/Input';
import Form from 'components/Form';
import Button from 'components/Button';
import Card from 'components/Card';
import Header from 'components/Header';
import Hero from 'components/Hero';
import Page from 'components/Page';
import Section from 'components/Section';

export default class PricingPage extends React.Component {
  handleSubmitEmail = e => {
    e.preventDefault();
    const emailInput = e.target.querySelector('input[type=email]');
    if (emailInput && emailInput.value) {
      window.location.href = `${appServerURL}/sign_up?email=${emailInput.value}`;
    }
  };

  render() {
    return (
      <Page id="pricing">
        <Hero className="hero--pricing px3 xs-px2">
          <div className="mw100 mx-auto text-center">
            <Header as="h2" className="hero--pricing__h2 xs-h25 xs-mt4 mb0 mt5">
              Simple pricing
            </Header>
            <p className="h5 hero--pricing__p mb4 xs-large">
              Just choose the right plan to <strong>fuel</strong> the growth
            </p>
          </div>
        </Hero>

        <Section className="s-pricing px3 xs-px2 xs-pt4">
          <div className="s-pricing__container mw100 mx-auto py4">
            <div className="s-pricing__container__switch-wrap"></div>
            <div className="s-pricing__container__card-wrap flex col-12 xs-flex-column">
              <Card shadowEffect="hover" className="s-pricing__container__card">
                <div className="s-pricing__container__card__inner">
                  <div className="s-pricing__container__card__inner__header-wrap">
                    <Header className="mb0" as="h3">
                      Free
                    </Header>
                    <p className="h3 s-pricing__container__card__inner__p--price">
                      $0
                    </p>
                  </div>
                  <div className="s-pricing__container__card__inner__desc-wrap">
                    <p className="s-pricing__container__card__inner__p--desc">
                      From 1 to 5
                    </p>
                    <p className="s-pricing__container__card__inner__p--desc">
                      Unlimited posts
                    </p>
                    <p className="s-pricing__container__card__inner__p--desc">
                      5GB storage
                    </p>
                  </div>
                  <div className="s-pricing__container__card__inner__button-wrap">
                    <Button
                      className="s-pricing__container__card__inner__button"
                      theme="gray"
                      round
                    >
                      Get started
                    </Button>
                  </div>
                </div>
              </Card>
              <Card shadowEffect="hover" className="s-pricing__container__card">
                <div className="s-pricing__container__card__inner">
                  <div className="s-pricing__container__card__inner__header-wrap">
                    <Header className="mb0" as="h3">
                      Pro
                    </Header>
                    <p className="h3 s-pricing__container__card__inner__p--price">
                      $5
                    </p>
                    <small className="s-pricing__container__card__inner__small">
                      per user per month
                    </small>
                  </div>
                  <div className="s-pricing__container__card__inner__desc-wrap">
                    <p className="s-pricing__container__card__inner__p--desc">
                      For a team above 5
                    </p>
                    <p className="s-pricing__container__card__inner__p--desc">
                      All free features
                    </p>
                    <p className="s-pricing__container__card__inner__p--desc">
                      Unlimited storage
                    </p>
                  </div>
                  <div className="s-pricing__container__card__inner__button-wrap">
                    <Button
                      className="s-pricing__container__card__inner__button"
                      theme="gray"
                      round
                    >
                      Start a project
                    </Button>
                  </div>
                </div>
              </Card>
              <Card shadowEffect="hover" className="s-pricing__container__card">
                <div className="s-pricing__container__card__inner">
                  <div className="s-pricing__container__card__inner__header-wrap">
                    <Header className="mb0" as="h3">
                      Enterprise
                    </Header>
                    <p className="h3 s-pricing__container__card__inner__p--price">
                      +50
                    </p>
                  </div>
                  <div className="s-pricing__container__card__inner__desc-wrap">
                    <p className="s-pricing__container__card__inner__p--desc">
                      For a team above 50
                    </p>
                    <p className="s-pricing__container__card__inner__p--desc">
                      Please contact us for <b>details</b>
                    </p>
                  </div>
                  <div className="s-pricing__container__card__inner__button-wrap">
                    <Button
                      className="s-pricing__container__card__inner__button"
                      theme="gray"
                      round
                    >
                      Contact
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
            <Header className="fw-normal text-center" as="h5">
              Fair plans that scale along your stage
            </Header>
          </div>
        </Section>

        <Section className="s-pricing-features px3 xs-px2 xs-pt4">
          <div className="s-pricing-features__container mw100 mx-auto"></div>
        </Section>

        <Section className="s-faq px3 xs-px2 xs-pt4">
          <div className="s-faq__container mw100 mx-auto">FAQ...</div>
        </Section>

        <Section className="s-try-it-now px3 xs-px2 xs-pt4">
          <div className="s-try-it-now__container mw100 mx-auto">
            <div className="s-potential__header-container">
              <Header className="mb1 xs-h4 xs-center xs-my3" as="h3">
                Try it now
              </Header>
              <p className="h6 mb3">Please don’t waste your time and effort</p>
              <Form
                className="s-potential__form flex"
                onSubmit={this.handleSubmitEmail}
              >
                <Input
                  className="s-potential__input-email mr2 mb1 xs-mr1 xs-col-8"
                  type="email"
                  placeholder="Enter your email"
                />
                <Button className="s-potential__button-signup xs-col-4">
                  <span>
                    Sign up<span className="xs-hide"> for free</span>
                  </span>
                </Button>
              </Form>
              <p className="mt2">
                According to your growth, please take advantages of paid plans
              </p>
            </div>
          </div>
        </Section>
      </Page>
    );
  }
}
