import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { setCookie, COOKIE_NAMES } from 'utils/cookie';
import { ArrowLeftSVG } from 'resources';

const EmailSent = ({ email, onClickBackBtn = () => {} }) => {
  let closeTimerKey = null;
  const [closeTimeout, setCloseTimeout] = useState(5);
  useEffect(() => {
    setCookie(COOKIE_NAMES.SIGN_UP_BY_EXTENSION, 'true', 0, 30);
    let t = 5;
    closeTimerKey = setInterval(() => {
      t -= 1;
      setCloseTimeout(t);
      if (t === 0) {
        clearInterval(closeTimerKey);
        window.close();
      }
    }, 1000);
  }, []);
  const handleClickBack = () => {
    if (closeTimerKey) {
      clearInterval(closeTimerKey);
      closeTimerKey = null;
    }
    onClickBackBtn();
  };
  return (
    <div className="email-sent">
      <ArrowLeftSVG
        className="email-sent__back-btn"
        onClick={handleClickBack}
      />
      <h1 className="email-sent__title">Check your e-mail</h1>
      <p className="email-sent__about">
        We've sent an email to <b>{email}</b>. It will expire shortly, so please
        verify it right now.
      </p>
      <p className="email-sent__timer">
        This popup closes automatically after {closeTimeout} seconds.
      </p>
      <video
        className="email-sent__video"
        src="https://alphamon-asset.s3.ap-northeast-2.amazonaws.com/img/sign_in/email-verify.mp4"
        muted
        autoPlay
      />
    </div>
  );
};

EmailSent.propTypes = {
  email: PropTypes.string.isRequired,
  onClickBackBtn: PropTypes.func,
};

export default EmailSent;
