import React from 'react';
import styled from 'styled-components/macro';

import { mediaMax, mediaMin } from 'themes/media';
import { LogEventFns } from 'analytics/ga';

import HeroDescription from 'views/Common/HeroDescription';
import HeroTitle from 'views/Common/HeroTitle';
import SignInToAdditor from 'views/Common/SignInToAdditor';

import AnimatedCursors from './AnimatedCursors';

const HeroWrapper = styled.div`
  padding: calc(10vh + 60px) 16px 0;
  height: 100vh;
  width: 100%;
  position: relative;
  scroll-snap-align: start;
  z-index: 5;

  ${mediaMax.lg`
    height: auto;
    margin-bottom: 80px;
  `}

  ${HeroTitle} {
    width: 324px;
    margin: 0 auto;

    ${mediaMin.xs`
      width: 100%;
      max-width: 530px;
    `}

    ${mediaMin.md`
      max-width: none;
    `}

    span {
      white-space: nowrap;
    }
  }
`;

function HeroSection() {
  return (
    <HeroWrapper>
      <HeroTitle>
        Stay <span>in-sync</span> while
        <br /> distributed
      </HeroTitle>
      <HeroDescription>
        The one and living source of truth for modern collaborators
        <span className="xs-hide">
          ,
          <br /> from remote workers to cross-functional teams.
        </span>
      </HeroDescription>
      <SignInToAdditor
        onClickEmailInput={LogEventFns.main.clickTopEmailInput}
        onSubmit={LogEventFns.main.clickTopGetStartedButton}
        onClickGoogleButton={LogEventFns.main.clickGoogleSignInButton}
        onClickAppleButton={LogEventFns.main.clickAppleAppStoreButton}
        onClickSlackButton={LogEventFns.main.clickSlackSignInButton}
      />
      <AnimatedCursors />
    </HeroWrapper>
  );
}

export default HeroSection;
