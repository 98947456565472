import React from 'react';
import PropTypes from 'prop-types';

const Page = ({ id = '', className = 'page', children = null }) => {
  return (
    <main id={id} className={`${className}`}>
      {children}
    </main>
  );
};

Page.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Page;
