import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Input = React.forwardRef((props, ref) => {
  const className = cn('input', props.className);

  return (
    <input
      type={props.type}
      className={className}
      placeholder={props.placeholder}
      onChange={props.onChange}
      ref={ref}
    />
  );
});

Input.propTypes = {
  type: PropTypes.oneOf(['email', 'text', 'password']),
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  theme: PropTypes.oneOf(['white', 'gray', 'default']),
  onChange: PropTypes.func,
};

Input.defaultProps = {
  type: 'text',
  className: 'input',
  placeholder: '',
  theme: 'default',
  onChange: () => {},
};

export default Input;
