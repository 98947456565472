import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { opacity, position } from 'styled-system';
import _ from 'lodash';

import Lottie from 'components/Lottie';
import { mediaMin } from 'themes/media';

import Chapter, { DISAPPEARED_REASONS } from '../ScrollInteraction/Chapter';
import TopVideoWrapper from '../ScrollInteraction/TopVideoWrapper';
import BottomVideoWrapper from '../ScrollInteraction/BottomVideoWrapper';
import chapters from '../chapters';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1680px;
  margin: 0 auto -30px;
  padding-bottom: 30px;
`;

const Container = styled.div`
  // 마지막 챕터가 스크롤 되기 위한 패딩 추가
  margin-bottom: -30vw;
  padding-bottom: 30vw;

  @media (min-width: 2000px) {
    margin-top: -30vw;
  }
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  ${opacity};
`;

const LottieWrapper = styled.div`
  width: 30vw;
  max-width: 500px;
  height: 100vh;
  position: sticky;
  pointer-events: none;
  top: 0;
  transition: left 0.5s, opacity 0.5s;
  ${position};
  ${opacity};

  & > div {
    position: absolute;
    top: calc(50% + 12px);
    left: 0;

    ${mediaMin.lg`
      top: calc(50% + 8px);
    `}

    ${mediaMin.xlg`
      top: calc(50% - 28px);
    `}
  }
`;

const topVideoFromScale = 0.8; // topVideo 초기 scale
const topVideoToScale = 0.5; // topVideo 이동 후 scale

const topVideoFromTranslateX = 0; // topVideo 초기 x 위치 (%)
const topVideoToTranslateX = -18; // topVideo 이동 후 x 위치 (%)

const topVideoFromTranslateY = 0; // topVideo 초기 y 위치 (%)
const topVideoToTranslateY = -50; // topVideo 이동 후 y 위치 (%)

const bottomVideoFromTranslateX = 60; // bottomVideo 초기 x 위치 (%)
const bottomVideoToTranslateX = 0; // bottomVideo 이동 후 x 위치 (%)

function ScrollInteraction() {
  const videoRefs = useRef({});

  const [isTopVideoWrapperVisible, setIsTopVideoWrapperVisible] = useState(
    true,
  );
  const [
    isBottomVideoWrapperVisible,
    setIsBottomVideoWrapperVisible,
  ] = useState(false);

  const [
    bottomVideoWrapperTransformX,
    setBottomVideoWrapperTransformX,
  ] = useState(bottomVideoToTranslateX);

  const [currentChapterId, setCurrentChapterId] = useState();
  const [currentLottie, setCurrentLottie] = useState(chapters[0].lotties[0]);
  const [isLottieVisible, setIsLottieVisible] = useState(false);
  const [lottieWrapperLeft, setLottieWrapperLeft] = useState(50);

  useEffect(() => {
    const topVideoElement = document.getElementById('top-video-wrapper');

    Object.assign(topVideoElement.style, {
      transform: `translate(${topVideoFromTranslateX}%, ${topVideoFromTranslateY}%) scale(${topVideoFromScale})`,
    });

    const handleScroll = _.throttle(() => {
      const { scrollTop } = document.body;

      if (scrollTop < window.innerHeight / 2) {
        initializeVideo();
      }

      // Hero 에서 Chapter 1 로 전환되는 동안의 스크롤 길이.
      // 커질수록 영상이 중앙에서 좌측으로 안착하는 데 필요한 스크롤 양이 늘어난다.
      const scrollLengthFromHeroToChapter1 = 400;

      const nextTopVideoScale = Math.max(
        topVideoFromScale -
          scrollTop *
            ((topVideoFromScale - topVideoToScale) /
              scrollLengthFromHeroToChapter1),
        topVideoToScale,
      );
      const nextTopVideoTranslateX = Math.max(
        topVideoFromTranslateX -
          scrollTop *
            ((topVideoFromTranslateX - topVideoToTranslateX) /
              scrollLengthFromHeroToChapter1),
        topVideoToTranslateX,
      );
      const nextTopVideoTranslateY = Math.max(
        topVideoFromTranslateY -
          scrollTop *
            ((topVideoFromTranslateY - topVideoToTranslateY) /
              scrollLengthFromHeroToChapter1),
        topVideoToTranslateY,
      );

      Object.assign(topVideoElement.style, {
        transform: `translate(${nextTopVideoTranslateX}%, ${nextTopVideoTranslateY}%) scale(${nextTopVideoScale})`,
      });
    }, 16);

    document.body.addEventListener('scroll', handleScroll);
    return () => {
      document.body.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add('scroll-snap');
  }, []);

  useEffect(() => {
    const workflowSection5 = document.getElementById('chapter-5');

    function handleWheel(e) {
      const scrollSnapScrollTop = workflowSection5.offsetTop;
      if (
        document.body.scrollTop >= scrollSnapScrollTop &&
        e.deltaY > 0 &&
        document.body.classList.contains('scroll-snap')
      ) {
        // workflowSection5 아래로 스크롤을 내렸을 때에는 scroll-snap-type 을 html 태그에 적용하지 않는다.
        document.body.classList.remove('scroll-snap');
      } else if (
        document.body.scrollTop < scrollSnapScrollTop &&
        !document.body.classList.contains('scroll-snap')
      ) {
        // safari 에서 scroll-snap 을 추가했을 때, 스크롤이 종종 맨위로 올라가는 버그가 있음
        document.body.classList.add('scroll-snap');
      }
    }

    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      if (
        _.includes(
          [chapters[0].id, chapters[1].id, chapters[2].id],
          currentChapterId,
        )
      ) {
        const chapter3Element = document.getElementById('chapter-3');
        setLottieWrapperLeft(
          chapter3Element.children[0].getBoundingClientRect().left,
        );
      } else if (
        _.includes([chapters[3].id, chapters[4].id], currentChapterId)
      ) {
        const chapter4Element = document.getElementById('chapter-4');
        setLottieWrapperLeft(
          chapter4Element.children[0].getBoundingClientRect().left,
        );
      }
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [currentChapterId]);

  useEffect(() => {
    if (
      _.includes(
        [chapters[0].id, chapters[1].id, chapters[2].id],
        currentChapterId,
      )
    ) {
      const chapter3Element = document.getElementById('chapter-3');
      setIsTopVideoWrapperVisible(true);
      setIsBottomVideoWrapperVisible(false);
      setBottomVideoWrapperTransformX(bottomVideoFromTranslateX);
      setLottieWrapperLeft(
        chapter3Element.children[0].getBoundingClientRect().left,
      );
    } else if (_.includes([chapters[3].id, chapters[4].id], currentChapterId)) {
      const chapter4Element = document.getElementById('chapter-4');
      setIsTopVideoWrapperVisible(false);
      setIsBottomVideoWrapperVisible(true);
      setBottomVideoWrapperTransformX(bottomVideoToTranslateX);
      setLottieWrapperLeft(
        chapter4Element.children[0].getBoundingClientRect().left,
      );
    } else if (!currentChapterId) {
      setIsLottieVisible(false);
    }
  }, [currentChapterId]);

  useEffect(() => {
    if (!currentChapterId) {
      return () => {};
    }

    const video = videoRefs.current[currentChapterId];

    function handleTimeupdate() {
      const currentChapter = _.find(chapters, ['id', currentChapterId]);
      // lotties 배열은 나중에 보여야 하는 것이 앞에 오도록 정렬되어 있다.
      // 나중에 보여야 할 lottie 가 먼저 발견되면 해당 lottie 로 세팅하고 loop 에서 빠져나온다.
      _.some(currentChapter.lotties, lottie => {
        if (video.currentTime >= lottie.shouldPlayAt) {
          setCurrentLottie(lottie);
          return true;
        }
        return false;
      });
    }

    video.addEventListener('timeupdate', handleTimeupdate);
    return () => {
      video.removeEventListener('timeupdate', handleTimeupdate);
    };
  }, [currentChapterId]);

  useEffect(() => {
    const chapter3Element = document.getElementById('chapter-3');
    setLottieWrapperLeft(
      chapter3Element.children[0].getBoundingClientRect().left,
    );
  }, []);

  const initializeVideo = () => {
    _.forEach(videoRefs.current, video => {
      if (video.currentTime > 0) {
        video.currentTime = 0;
      }
      if (!video.paused) {
        video.pause();
      }
    });

    const topVideoElement = document.getElementById('top-video-wrapper');
    Object.assign(topVideoElement.style, {
      transform: `translate(${topVideoFromTranslateX}%, ${topVideoFromTranslateY}%) scale(${topVideoFromScale})`,
    });

    setCurrentChapterId(null);
    setIsTopVideoWrapperVisible(true);
    setIsBottomVideoWrapperVisible(false);
    setCurrentLottie(chapters[0].lotties[0]);
  };

  const handleAppearedChapter = chapter => {
    setIsLottieVisible(true);

    setCurrentChapterId(chapter.id);
    _.forEach(videoRefs.current, video => {
      video.pause();
    });

    const video = videoRefs.current[chapter.id];
    video.currentTime = 0;
    video.play();
  };

  const handleChapterDisappeared = (chapter, reason) => {
    if (
      chapter === _.head(chapters) &&
      reason === DISAPPEARED_REASONS.SCROLL_UP
    ) {
      setIsLottieVisible(false);
    } else if (
      chapter === _.last(chapters) &&
      reason === DISAPPEARED_REASONS.SCROLL_DOWN
    ) {
      setIsLottieVisible(false);
    }
  };

  return (
    <Wrapper>
      <LottieWrapper
        opacity={isLottieVisible ? 1 : 0}
        left={`${lottieWrapperLeft}px`}
      >
        <Lottie
          animationData={currentLottie.data}
          speed={0.8}
          autoplay
          loop={currentLottie.loop}
        />
      </LottieWrapper>
      <TopVideoWrapper isVisible={isTopVideoWrapperVisible}>
        <Video
          ref={element => (videoRefs.current[chapters[0].id] = element)}
          width="100%"
          muted
          playsInline
          src={chapters[0].video}
          opacity={
            !currentChapterId || currentChapterId === chapters[0].id ? 1 : 0
          }
        />
        <Video
          ref={element => (videoRefs.current[chapters[1].id] = element)}
          width="100%"
          muted
          playsInline
          src={chapters[1].video}
          opacity={currentChapterId === chapters[1].id ? 1 : 0}
        />
        <Video
          ref={element => (videoRefs.current[chapters[2].id] = element)}
          width="100%"
          muted
          playsInline
          src={chapters[2].video}
          opacity={currentChapterId === chapters[2].id ? 1 : 0}
        />
      </TopVideoWrapper>
      <BottomVideoWrapper
        isVisible={isBottomVideoWrapperVisible}
        translateX={bottomVideoWrapperTransformX}
      >
        <Video
          ref={element => (videoRefs.current[chapters[3].id] = element)}
          width="100%"
          muted
          playsInline
          src={chapters[3].video}
          opacity={currentChapterId !== chapters[4].id ? 1 : 0}
        />
        <Video
          ref={element => (videoRefs.current[chapters[4].id] = element)}
          width="100%"
          muted
          playsInline
          src={chapters[4].video}
          opacity={currentChapterId === chapters[4].id ? 1 : 0}
        />
      </BottomVideoWrapper>
      <Container>
        {_.map(chapters, chapter => (
          <Chapter
            key={chapter.id}
            chapter={chapter}
            onAppeared={handleAppearedChapter}
            onDisappeared={handleChapterDisappeared}
            isLeft={_.includes([chapters[3], chapters[4]], chapter)}
          />
        ))}
      </Container>
    </Wrapper>
  );
}

export default ScrollInteraction;
