import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { THEME, Link } from './index';
import { ReactComponent as ChromeAppStoreSvg } from './extension-store-button.svg';
import { ReactComponent as ChromeAppStoreBrandSvg } from './extension-store-button-brand.svg';

function ChromeWebStoreButton({ onClick = _.noop, theme = THEME.LIGHT.name }) {
  return (
    <Link
      href="https://chrome.google.com/webstore/detail/additor-the-simplest-book/hfllajanfnlimffhkjbondolipoimcgn"
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
      color={THEME[theme].color}
      borderColor={THEME[theme].borderColor}
      hoverBackgroundColor={THEME[theme].hoverBackgroundColor}
    >
      {theme === THEME.BRAND.name ? (
        <ChromeAppStoreBrandSvg />
      ) : (
        <ChromeAppStoreSvg />
      )}
    </Link>
  );
}

ChromeWebStoreButton.propTypes = {
  onClick: PropTypes.func,
  theme: PropTypes.oneOf([THEME.BRAND.name, THEME.LIGHT.name]),
};

export default ChromeWebStoreButton;
