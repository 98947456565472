import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import LayoutContext from 'containers/Root/LayoutContext';
import NameLogo from 'components/NameLogo';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import Page from 'components/Page';
import MobileNavigationPopup from 'components/MobileNavigationPopup';

import styled from 'styled-components/macro';
import colors from 'themes/colors';
import helpers from 'themes/helpers';

import SignUpPanel from './PanelSignUp';
import { ReactComponent as SvgMobileGNB } from 'views/gnb.svg';
const MobileGNBIcon = styled(SvgMobileGNB)`
  color: ${colors.brand500};
  width: 24px;
  height: 24px;
  margin-left: 20px;
  cursor: pointer;
  ${helpers.transition('background-color')};
  border-radius: 4px;
  &:hover {
    background-color: ${colors.gray80};
  }
`;

class SignUp extends React.Component {
  state = {
    mobileGNBVisible: false,
  };

  componentDidMount() {
    this.context.updateLayout({
      theme: 'white',
      hideHeader: true,
      hideFooter: true,
    });
  }

  componentWillUnmount() {
    this.context.updateLayout({
      hideHeader: false,
      hideFooter: false,
    });
  }

  isFromExtension = () => {
    const {
      location: { search },
    } = this.props;
    const urlSearchParams = new URLSearchParams(search);
    const isFromExtension = urlSearchParams.get('from');
    return isFromExtension === 'extension';
  };

  handleClickMobileGNBIcon = () => {
    this.setState({
      mobileGNBVisible: true,
    });
  };

  handleClickCloseMobileGNB = () => {
    this.setState({
      mobileGNBVisible: false,
    });
  };

  render() {
    const { mobileGNBVisible } = this.state;
    return (
      <Page className="sign-up-page" id="sign-up-page">
        <div className="sign-up-page__panel-wrap">
          <div className="sign-up-page__namelogo-wrap">
            <Link className="sign-up-page__namelogo" to="/home">
              <NameLogo />
            </Link>
          </div>
          <div className="sign-up-page__menu-wrap">
            <Menu className="list sign-up-page__list--mobile">
              <MenuItem className="sign-up-page__listitem">
                <Link to="/sign-up" className="sign-up-page__link--signin">
                  Sign up
                </Link>
              </MenuItem>
              <MenuItem className="sign-up-page__listitem mr3">
                <MobileGNBIcon onClick={this.handleClickMobileGNBIcon} />
              </MenuItem>
            </Menu>
          </div>
          {mobileGNBVisible && (
            <MobileNavigationPopup onClose={this.handleClickCloseMobileGNB} />
          )}
          <div className="sign-up-page__panel">
            <SignUpPanel />
          </div>
        </div>
        <div className="sign-up-page__main-image-wrap">
          <div className="sign-up-page__menu-wrap">
            <Menu className="list sign-up-page__list">
              <MenuItem className="sign-up-page__listitem">
                <Link to="/product" className="sign-up-page__link">
                  Product
                </Link>
              </MenuItem>
              <MenuItem className="sign-up-page__listitem">
                <Link to="/slack-integration" className="sign-up-page__link">
                  Integration
                </Link>
              </MenuItem>
              <MenuItem className="sign-up-page__listitem">
                <Link to="/pricing" className="sign-up-page__link">
                  Pricing
                </Link>
              </MenuItem>
              <MenuItem className="sign-up-page__listitem">
                <Link to="/about" className="sign-up-page__link">
                  About us
                </Link>
              </MenuItem>
              <MenuItem className="sign-up-page__listitem">
                <Link to="/sign-in" className="sign-up-page__link--signin">
                  Sign in
                </Link>
              </MenuItem>
              <MenuItem className="sign-up-page__listitem">
                <Link to="/sign-up" className="sign-up-page__link--signup">
                  Sign up
                </Link>
              </MenuItem>
            </Menu>
          </div>
          {this.isFromExtension() ? (
            <video
              autoPlay
              loop
              muted
              src="https://alphamon-asset.s3.ap-northeast-2.amazonaws.com/img/extension/sign-up-from-extension.mp4"
            />
          ) : (
            <img
              alt=""
              src="https://alphamon-asset.s3.ap-northeast-2.amazonaws.com/img/sign-up-main.png"
            />
          )}
        </div>
        <div className="sign-up-page__main-image-wrap-bg" />
      </Page>
    );
  }
}

SignUp.contextType = LayoutContext;

SignUp.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(SignUp);
