import React from 'react';
import styled from 'styled-components/macro';

import { mediaMin } from 'themes/media';
import colors from 'themes/colors';
import { ReactComponent as Check } from './assets/check-purple.svg';

const Wrapper = styled.section``;
const Title = styled.h2`
  font-family: ObjectSans, sans-serif;
  font-size: 36px;
  font-weight: 900;
  text-align: center;
  color: ${colors.gray900};
  margin: 0 0 70px;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 200px repeat(4, 1fr);
  height: 60px;
  padding-left: 20px;
  border-top: 1px solid ${colors.gray100};
  &:last-of-type {
    border-bottom: 1px solid ${colors.gray100};
  }
  ${mediaMin.md`
    grid-template-columns: 250px repeat(4, 1fr);
  `}
  ${mediaMin.lg`
    grid-template-columns: 300px repeat(4, 1fr);
  `}
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ alignLeft }) => (alignLeft ? 'flex-start' : 'center')};
  font-family: ObjectSans, sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: ${colors.gray900};

  &:nth-of-type(2n) {
    background-color: ${colors.gray50};
  }
`;

const HeaderCell = styled(Cell)`
  font-size: 14px;
  font-weight: bold;
  &:nth-of-type(2n) {
    background-color: white;
  }
  ${mediaMin.md`
    font-size: 20px;
    font-weight: 900;
  `}
`;

const header = ['Feature', 'Air', 'Standard', 'Pro', 'Enterprise'];
const featureList = [
  ['No registration', true, false, false, false],
  ['Free', true, true, false, false],
  ['Workspace', false, true, true, true],
  ['Channel Management', false, true, true, true],
  ['Page Management', false, true, true, true],
  ['Set Permission to Sharing', false, true, true, true],
  ['Invite Guests', false, true, true, true],
  ['Notification', false, true, true, true],
  ['API Integration', false, true, true, true],
  ['Workspace Permission', false, false, true, true],
  ['Unlimited Storage', false, false, true, true],
  ['Unlimited Channel/Page', false, false, true, true],
  ['Unlimited Version History', false, false, true, true],
  ['Guest Permission', false, false, true, true],
  ['Premium Features', false, false, true, true],
  ['Organization Settings', false, false, false, true],
  ['Team Settings', false, false, false, true],
  ['Complicated Permissions', false, false, false, true],
  ['IP Access Management', false, false, false, true],
  ['Single Sign-On (SSO)', false, false, false, true],
  ['Private Saas', false, false, false, true],
];

function FeatureComparison() {
  return (
    <Wrapper>
      <Title>Compare our plans in detail</Title>
      <Row>
        {header.map((tiers, index) => (
          <HeaderCell key={index} alignLeft={index === 0}>
            {tiers}
          </HeaderCell>
        ))}
      </Row>
      {featureList.map((feature, index) => (
        <Row key={index}>
          {feature.map((feature, i) =>
            i === 0 ? (
              <Cell key={i} alignLeft>
                {feature}
              </Cell>
            ) : feature ? (
              <Cell key={i}>
                <Check />
              </Cell>
            ) : (
              <Cell key={i} />
            ),
          )}
        </Row>
      ))}
    </Wrapper>
  );
}

export default FeatureComparison;
