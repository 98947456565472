import React from 'react';
import styled from 'styled-components/macro';

import colors from 'themes/colors';
import { mediaMax, mediaMin } from 'themes/media';
import { LogEventFns } from 'analytics/ga';

import HeroTitle from 'views/Common/HeroTitle';
import { S3ImageURL } from 'config/paths';

const Content = styled.div`
  background-color: ${colors.white};
  text-align: center;
  font-size: 18px;
  padding: calc(10vh + 70px) 20px 100px;

  ${mediaMin.xs`
    padding-top: calc(10vh + 60px);
    padding-left: 40px;
    padding-right: 40px;
  `};

  ${mediaMin.md`
    padding-left: 80px;
    padding-right: 80px;
  `};

  ${HeroTitle} {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    font-size: inherit;
    font-weight: 400;
    line-height: 1.5;
    margin: 0 0 40px 0;
  }

  .about__cheers {
    margin-bottom: 72px;
  }

  a {
    font-size: inherit;
    font-weight: 900;
    color: ${colors.brand500};
  }
`;

const StyledHeroTitle = styled(HeroTitle)`
  margin-bottom: 70px;
  ${mediaMax.md`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}

  ${mediaMax.xs`
    padding: 0;
  `}
`;

const CheersWrapper = styled.span`
  position: relative;
  z-index: 1;
  white-space: nowrap;

  &:before {
    position: absolute;
    content: '';

    width: 264px;
    height: 16px;
    left: -10px;
    bottom: 2px;
    z-index: -1;
    background-color: ${colors.yellow100};
  }

  ${mediaMin.xs`
    &:before {
      width: 384px;
      height: 22px;
      left: -14px;
      bottom: 6px;
    }
  `}

  ${mediaMin.sm`
    &:before {
      width: 416px;
      height: 22px;
      left: -14px;
      bottom: 6px;
    }
  `}

  ${mediaMin.md`
    &:before {
      width: 456px;
      height: 22px;
      left: -14px;
      bottom: 6px;
    }
  `}

  ${mediaMin.lg`
    &:before {
      width: 496px;
      height: 22px;
      left: -14px;
      bottom: 6px;
    }
  `}
`;

const AboutImg = styled.img`
  width: 100%;
  max-width: 700px;
  margin-bottom: 70px;
  border-radius: 5px;
`;

const AboutImgSrc = `${S3ImageURL}/landing/about/about.jpg`;

function About() {
  return (
    <Content>
      <StyledHeroTitle>
        Build the future of <CheersWrapper>knowledge</CheersWrapper> work
      </StyledHeroTitle>
      <AboutImg src={AboutImgSrc} />
      <p>
        Work has always existed but has been changed. It will be same in the
        future.
        <br />
        Human finds one’s identity or value by work. It’s not a sad story but a
        fact.
        <br />
        The waste of time or effort during work means losing oneself.
      </p>
      <p>
        We believe in the better future than the present.
        <br />
        We want to make people believe in it, too.
        <br />
        To make it happen, we create it.
      </p>
      <p>
        We make the <b>future-classic</b>,
        <br />
        and you’ll grow with it.
      </p>
      <p className="about__cheers">Cheers!</p>
      <p>
        If you are also a <b>future pirate</b>,
        <br />
        please feel free to contact us.
      </p>
      <a
        href="mailto:support@additor.io"
        onClick={LogEventFns.aboutUs.clickEmailLink}
      >
        support@additor.io
      </a>
    </Content>
  );
}

About.propTypes = {};

export default About;
