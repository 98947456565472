import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import colors from 'themes/colors';
import helpers from 'themes/helpers';
import { ReactComponent as SvgClose } from 'views/close.svg';
import { ReactComponent as SvgSymbol } from 'views/additor-symbol.svg';

// eslint-disable-next-line no-unused-vars
const Link = styled(({ hovered, highlight, semiHighlight, ...props }) => (
  <RouterLink {...props} />
))`
  font-family: ObjectSans, sans-serif;
  font-weight: 500;
  white-space: nowrap;
  font-size: 16px;
  line-height: 2.25;
  ${helpers.transition('opacity', 'background-color')};
  opacity: ${({ hovered }) => (hovered ? '1' : '.45')};
  border-radius: ${({ highlight }) => (highlight ? '18px' : '33px')};
  padding: ${({ highlight }) => (highlight ? '0 20px' : '0 30px 20px 0')};
  height: ${({ highlight }) => (highlight ? '36px' : '66px')};
  display: inline-flex;
  background-color: ${({ highlight }) =>
    highlight ? colors.brand500 : colors.transparent};
  color: ${({ highlight, semiHighlight }) =>
    semiHighlight
      ? colors.brand500
      : highlight
      ? colors.white
      : colors.gray800};

  &:hover {
    text-decoration: none;
    background-color: ${({ highlight }) =>
      highlight ? colors.brand700 : colors.transparent};
  }
`;

const LinkWrapper = styled.div`
  display: flex;
`;

const Links = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 33px 36px;
  z-index: 10;
  background-color: ${colors.white};
`;

const CloseIcon = styled(SvgClose)`
  color: ${colors.gray700};
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 38px;
  right: 30px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.gray80};
  }
`;
const Symbol = styled(SvgSymbol)`
  color: ${colors.brand500};
  width: 40px;
  height: 40px;
  margin-bottom: 30px;
`;

// FIXME: signup, signin 에서 header를 따로 사용하고 있기 때문에 (예전코드) 모바일의 네비게이션을 따로 보여주기 위해 이 컴포넌트를 사용함.
//  추후에 signin / signup 페이지가 개선되면 사라질 예정.
function MobileNavigationPopup({ onClose }) {
  const [hoveredLink, setHoveredLink] = useState('');
  const handleMouseEnter = useCallback(e => {
    setHoveredLink(e.target.pathname);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setHoveredLink('');
  }, []);
  return (
    <Links>
      <CloseIcon onClick={onClose} />
      <RouterLink onClick={onClose} to="/home">
        <Symbol />
      </RouterLink>
      <LinkWrapper>
        <Link
          to="/product"
          onClick={onClose}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          hovered={!hoveredLink || hoveredLink === '/product'}
        >
          Product
        </Link>
      </LinkWrapper>
      <LinkWrapper>
        <Link
          to="/slack-integration"
          onClick={onClose}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          hovered={!hoveredLink || hoveredLink === '/slack-integration'}
        >
          Integration
        </Link>
      </LinkWrapper>
      <LinkWrapper>
        <Link
          to="/pricing"
          onClick={onClose}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          hovered={!hoveredLink || hoveredLink === '/pricing'}
        >
          Pricing
        </Link>
      </LinkWrapper>
      <LinkWrapper>
        <Link
          to="/about"
          onClick={onClose}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          hovered={!hoveredLink || hoveredLink === '/about'}
        >
          About us
        </Link>
      </LinkWrapper>
      <LinkWrapper>
        <Link
          semiHighlight
          to="/sign-in"
          onClick={onClose}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          hovered={!hoveredLink || hoveredLink === '/sign-in'}
        >
          Sign in
        </Link>
      </LinkWrapper>
      <LinkWrapper highlight>
        <Link
          highlight
          to="/sign-up"
          onClick={onClose}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          hovered={!hoveredLink || hoveredLink === '/sign-up'}
        >
          Sign up
        </Link>
      </LinkWrapper>
    </Links>
  );
}

MobileNavigationPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default MobileNavigationPopup;
