import { cdnImageBaseURL } from 'config/paths';

export const internalCommunicationTabs = [
  {
    id: 'join-leave-channel',
    name: 'Join or leave channels',
    description:
      "Members can join the channels that matter to one's workflow or leave them if needed.",
    imgSrc: `${cdnImageBaseURL}/landing/product/internal-communication-1.png`,
  },
  {
    id: 'private-channel',
    name: 'Keep security with private channels',
    description:
      'When you need to limit access or permission, make private channels and keep them secure.',
    imgSrc: `${cdnImageBaseURL}/landing/product/internal-communication-2.png`,
  },
];

export const externalCommunicationTabs = [
  {
    id: 'invite',
    name: 'Invite external collaborators',
    description:
      'We often collaborate with clients, freelancers, or partners, and you can invite them as guests.',
    imgSrc: `${cdnImageBaseURL}/landing/product/external-communication-1.png`,
  },
  {
    id: 'public-page',
    name: 'Share pages publicly',
    description:
      "It's possible to publish your great content to the public by setting permissions and passwords.",
    imgSrc: `${cdnImageBaseURL}/landing/product/external-communication-2.png`,
  },
];
