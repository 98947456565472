import { useRef, useState } from 'react';
import useIntersectionObserver from './useIntersectionObserver';

const SLIDE_INTERVAL_TIME = 5000;

// tabMenuRef.current element 가 화면에 들어왔을 때 slideShow 를 시작함.
function useSlideShow({
  tabMenuRef,
  slideIntervalTime = SLIDE_INTERVAL_TIME,
  tabLength,
}) {
  const [isStartedSlideShow, setIsStartedSlideShow] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const intervalId = useRef();

  const startSlideShow = () => {
    intervalId.current = setInterval(() => {
      setCurrentTabIndex(prev => (prev + 1) % tabLength);
    }, slideIntervalTime);
  };

  const stopSlideShow = () => {
    clearInterval(intervalId.current);
    intervalId.current = null;
  };

  useIntersectionObserver({
    targetElementRef: tabMenuRef,
    onIntersectingTrue: () => {
      setIsStartedSlideShow(true);
      startSlideShow();
    },
    once: true,
    onBeforeCleanUp: () => {
      if (intervalId.current) {
        stopSlideShow();
      }
    },
  });

  const onClickTab = tabIndex => {
    if (intervalId.current) {
      stopSlideShow();
    }
    setCurrentTabIndex(tabIndex);
    startSlideShow();
  };

  return {
    isStartedSlideShow,
    currentTabIndex,
    onClickTab,
    startSlideShow,
    stopSlideShow,
    intervalId,
  };
}

export default useSlideShow;
