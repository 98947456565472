import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Form, FormGroup, Input } from 'components/ContextForm';
import { postGetStartedFind, putGetStarted } from 'api/getStarted';
import getStartedWithApple from 'api/getStartedWithApple';
import { validate as isEmail } from 'isemail';
import { ReactComponent as AppleIconSVG } from 'resources/svg/apple-logo.svg';
import titleDecoration from 'resources/img/signin-decoration.png';
import IconFavGoogle from 'resources/img/fav-google.png';
import IconFavSlack from 'resources/img/fav-slack.png';
import { getGoogleUser } from 'utils/googleAuth';
import { redirectToSlackOAuth } from 'utils/slackAuth';
import { setCookie } from 'utils/cookie';
import getAppHostName from 'utils/getAppHostName';
import { LogEventFns } from 'analytics/ga';

function validateEmail(email) {
  if (!isEmail(email)) {
    return 'The email address is in an invalid format.';
  }
  return '';
}

class PanelFindWorkspace extends React.Component {
  timer = null;
  state = {
    motionPlay: true,
  };

  componentDidMount() {
    this.timer = window.setTimeout(
      () => this.setState({ motionPlay: false }),
      3250,
    );
  }

  componentWillUnmount() {
    if (this.timer != null) {
      window.clearTimeout(this.timer);
    }
  }

  handleOnClickBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleOnSubmitForm = async (e, isValid, { email }) => {
    e.preventDefault();
    LogEventFns.signIn.clickSignInButton();
    if (!isValid) {
      return;
    }
    const res = await postGetStartedFind(email);
    if (res.ok) {
      this.props.history.push(`/mail-sent?email=${encodeURIComponent(email)}`);
    }
  };

  handleOnClickGoogleAuth = async () => {
    const googleUser = await getGoogleUser();
    this.handleOnGoogleSignin(googleUser);
    LogEventFns.signIn.clickGoogleSignInButton();
  };

  handleOnGoogleSignin = async googleUser => {
    const {
      ok,
      verificationToken,
      verifiedUserName,
      ...res
    } = await putGetStarted(googleUser.getAuthResponse().id_token, 'google');
    if (ok) {
      setCookie('verified_user_name', verifiedUserName, 0, 60);
      setCookie('verification', verificationToken, 0, 60);
      window.location.href = `${getAppHostName()}/check-in`;
    } else if (res.status === 409 || res.status === 410) {
      this.setState({ invalidSignInInfo: true });
    }
  };

  handleOnClickSlackAuth = () => {
    LogEventFns.signIn.clickSlackSignInButton();
    redirectToSlackOAuth();
  };

  handleClickAppleAuth = async () => {
    LogEventFns.signIn.clickAppleSignInButton();
    try {
      const data = await window.AppleID.auth.signIn();
      if (data) {
        getStartedWithApple(data.authorization.id_token, data.user);
      }
    } catch (e) {
      // ignore error
    }
  };

  render() {
    return (
      <div className="sign-in-page__panel sign-in-page__panel--find">
        <h1 className="sign-in-page__title sign-in-page__panel--find__title">
          <span>
            <img className="sign-in-page__title__deco" src={titleDecoration} />
            Sign in to your workspaces
          </span>
        </h1>
        <p className="sign-in-page__description sign-in-page__panel--find__description">
          Find the workspaces you joined after verifying your email.
        </p>
        <Form name="findWorkspace" onSubmit={this.handleOnSubmitForm}>
          <FormGroup name="email" className="sign-in-page__panel__form-group">
            <p className="sign-in-page__panel--find__form-label">
              Enter your <b>email</b>
            </p>
            <div
              className="sign-in-page__panel__form-control-wrap"
              onClick={LogEventFns.signIn.clickEmailInput}
            >
              <Input
                name="email"
                type="email"
                placeholder="you@example.com"
                className="sign-in-page__panel__form-control"
                validator={validateEmail}
                autoFocus
              />
            </div>
          </FormGroup>
          <button type="submit" className="sign-in-page__panel__btn-submit">
            Sign in
          </button>
        </Form>
        <div className="sign-in-page__panel--signin__or">or</div>
        <button
          type="button"
          className="sign-in-page__panel--signin__btn-google"
          onClick={this.handleOnClickGoogleAuth}
        >
          <span className="icon" aria-hidden={true}>
            <img src={IconFavGoogle} />
          </span>
          Sign in with Google
        </button>
        <button
          type="button"
          className="sign-in-page__panel--signin__btn-slack"
          onClick={this.handleOnClickSlackAuth}
        >
          <span className="icon" aria-hidden={true}>
            <img src={IconFavSlack} />
          </span>
          Sign in with Slack
        </button>
        <button
          type="button"
          className="sign-in-page__panel--signin__btn-apple"
          onClick={this.handleClickAppleAuth}
        >
          <span className="icon" aria-hidden={true}>
            <AppleIconSVG width={20} height={20} />
          </span>
          Sign in with Apple
        </button>
      </div>
    );
  }
}

PanelFindWorkspace.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(PanelFindWorkspace);
