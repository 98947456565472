const hostName = window.location.host;

export const appServerURL = `https://app.${hostName}`;
export const imageURL =
  'https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image';
export const videoURL =
  'https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/video';
export const S3ImageURL =
  'https://alphamon-asset.s3.ap-northeast-2.amazonaws.com/img';

export const AMPLITUDE_KEY =
  process.env.REACT_APP_CIRCLE_BRANCH === 'master'
    ? '39b586661c7b4691a3291de170206d3d'
    : process.env.REACT_APP_CIRCLE_BRANCH === 'staging'
    ? '2034857c071ae6cab495aa5d6eb526fb'
    : 'af9a54c6d087ab766f49ca8ce0dcca5a';

export const cdnVideoBaseURL = 'https://assets.additor.io/videos';
export const cdnImageBaseURL = 'https://assets.additor.io/images';

export const GAPI_API_KEY = process.env.REACT_APP_GAPI_API_KEY;
export const GAPI_CLIENT_ID = process.env.REACT_APP_GAPI_CLIENT_ID;
