import { cdnVideoBaseURL } from 'config/paths';

const tabs = [
  {
    id: 'knowledge-library-video',
    title: 'Knowledge library',
    description:
      "Great knowledge or insight worth spreading in your team. They're already ready-to-use.",
    src: `${cdnVideoBaseURL}/landing/knowledge-library.mp4`,
  },
  {
    id: 'in-app-highlighter-video',
    title: 'In-app highlighter',
    description:
      'Without distraction, you can read and highlight web pages or PDFs simultaneously as you write.',
    src: `${cdnVideoBaseURL}/landing/in-app-highlighter.mp4`,
  },
];

export default tabs;
