import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { opacity } from 'styled-system';

import colors from 'themes/colors';

const Wrapper = styled.div`
  position: sticky;
  top: 50%;
  left: calc(50% - min(7vw, 114px));
  width: 50%;
  max-width: 840px;
  transition: transform 0.5s, opacity 0.5s;
  transform: translate(
    ${({ translateX }) => translateX}%,
    ${({ translateY }) => translateY}%
  );
  border-radius: 20px;
  ${opacity};

  &:before {
    display: block;
    padding-bottom: 56.25%;
    content: '';
    border-radius: 20px;
    box-shadow: 0 -10px 90px ${colors.black15};
  }
`;

function BottomVideoWrapper({
  children,
  isVisible,
  scale = 0.5,
  translateY = -50,
  translateX,
}) {
  return (
    <Wrapper
      scale={scale}
      translateX={translateX}
      translateY={translateY}
      opacity={isVisible ? 1 : 0}
    >
      {children}
    </Wrapper>
  );
}

BottomVideoWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
  translateX: PropTypes.number.isRequired,
  scale: PropTypes.number,
  translateY: PropTypes.number,
};

export default BottomVideoWrapper;
