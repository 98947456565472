import React from 'react';
import PropTypes from 'prop-types';

const Image = props => {
  return <img alt={props.alt} className={props.className} {...props} />;
};

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
};

Image.defaultProps = {
  alt: 'img',
  className: 'image',
};

export default Image;
