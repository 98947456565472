import getRequestBaseURL from './getRequestBaseURL';


export async function fetchGraphqlWorkspace(eid) {
  const query = `
query Fetch1($eid: String) {
  fetch(type: 11, eid: $eid) {
    ...on Workspace {
      id  eid name  desc
    }
  }
}`;

  const res = await fetch(
    `${getRequestBaseURL()}/_graphql`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        query,
        variables: { eid },
      }),
    },
  );

  if (res.ok) {
    return { ok: true, ...await res.json() };
  }
  return { ok: false, status: res.status };
}
