import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'themes/colors';
import ProgressBar, { DIRECTION } from 'views/Common/ProgressBar';

const StyledTabMenuItem = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  margin: 0 0 20px;
`;

const TextWrapper = styled.div`
  border-radius: 5px;
  margin: 0 0 0 11px;
  padding: 8px 12px;
  &:hover {
    background-color: ${colors.gray80};
  }
`;

const Title = styled.p`
  margin: 0;
  font-size: 17px;
  font-weight: 900;
  color: ${({ isSelected }) => (isSelected ? colors.brand500 : colors.gray900)};
`;

const Description = styled.p`
  margin: 10px 0 0;
  font-size: 16px;
  line-height: 1.9;
  color: ${colors.gray900};
`;

function TabMenuItem({ name, description, isSelected, onClick }) {
  return (
    <StyledTabMenuItem onClick={onClick}>
      <ProgressBar
        duration={5000}
        direction={DIRECTION.VERTICAL}
        isInProgress={isSelected}
      />
      <TextWrapper>
        <Title isSelected={isSelected}>{name}</Title>
        <Description>{description}</Description>
      </TextWrapper>
    </StyledTabMenuItem>
  );
}

TabMenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TabMenuItem;
