import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

import Alert from './Alert';
import Dim from './Dim';

const MODAL_PARENT_DIV_ID = 'modal-parent-div';

function handleKeydown(e) {
  if (e.key === 'Escape') {
    resetModals();
  }
}

export function showAlertModal({ alertProps }) {
  window.addEventListener('keydown', handleKeydown);

  ReactDOM.render(
    <>
      <Dim onClick={resetModals} />
      <Alert
        {...alertProps}
        onClickButton={() => {
          if (_.isFunction(alertProps.onClickButton)) {
            alertProps.onClickButton();
          }
          resetModals();
        }}
      />
    </>,
    document.getElementById(MODAL_PARENT_DIV_ID),
  );
}

export function resetModals() {
  window.removeEventListener('keydown', handleKeydown);
  const modalParent = document.getElementById(MODAL_PARENT_DIV_ID);
  ReactDOM.unmountComponentAtNode(modalParent);
}
