import React from 'react';
import styled from 'styled-components/macro';

import colors from 'themes/colors';
import { mediaMin } from 'themes/media';
import HeroTitle from 'views/Common/HeroTitle';
import HeroDescription from 'views/Common/HeroDescription';

import PriceList from './PriceList';
import FeatureComparison from './FeatureComparison';

const StyledHeroTitle = styled(HeroTitle)`
  margin-bottom: 16px;

  ${mediaMin.xs`
    margin-bottom: 25px;
  `}
`;

const StyledHeroDescription = styled(HeroDescription)`
  margin: 0 0 120px;
`;

const Content = styled.div`
  background-color: ${colors.white};
  margin: 0 auto;
  padding: calc(10vh + 70px) 20px 220px;

  ${mediaMin.xs`
    padding-top: calc(10vh + 60px);
  `}
  
  ${mediaMin.sm`
    padding-left: 80px;
    padding-right: 80px;
    max-width: 864px;
  `}
  ${mediaMin.lg`
    padding-left: 0;
    padding-right: 0;
    max-width: 1040px;
  `}
  ${mediaMin.xlg`
    max-width: 1280px;
  `}
`;

function Pricing() {
  return (
    <Content>
      <StyledHeroTitle>Simple Pricing</StyledHeroTitle>
      <StyledHeroDescription>
        Additor helps you succeed.
        <br />
        And our pricing follows your success.
      </StyledHeroDescription>
      <PriceList />
      <FeatureComparison />
    </Content>
  );
}

export default Pricing;
