import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import getStartedAir from 'api/getStartedAir';
import { mediaMin } from 'themes/media';
import colors from 'themes/colors';
import { LogEventFns } from 'analytics/ga';

import { ReactComponent as Plus } from './assets/plus.svg';
import { ReactComponent as Check } from './assets/check.svg';
import { ReactComponent as SuccessIcon } from './assets/success.svg';

import EmailForm from './EmailForm';

const Wrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 20px;
  margin-bottom: 200px;
  ${mediaMin.sm`
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  `}
  ${mediaMin.xlg`
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
  `}
`;

const PriceItem = styled.li`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 4px 8px 32px -2px rgba(0, 0, 0, 0.1);
  padding: 47px 30px 30px;
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  ${mediaMin.xs`
    height: ${({ showEmailForm }) => (showEmailForm ? 'unset' : '650px')};
  `}
  ${mediaMin.xlg`
    height: ${({ showEmailForm }) => (showEmailForm ? 'unset' : '700px')};
  `}
`;

const Tier = styled.section`
  display: flex;
  align-items: center;
  font-family: 'SFProDisplay', 'Open Sans', 'Apple SD Gothic Neo',
    'Noto Sans KR', sans-serif;
  font-size: 25px;
  line-height: 25px;
  font-weight: 600;
  color: ${({ color }) => color};
  margin-bottom: 10px;
  ${mediaMin.xs`
    margin-bottom: 21px;
  `}
`;

const CommingSoon = styled.span`
  padding: 0 17px;
  font-family: 'SFProDisplay', 'Open Sans', 'Apple SD Gothic Neo',
    'Noto Sans KR', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: ${colors.brand500};
  background-color: #fff5c5;
  border-radius: 20px;
  margin-left: 15px;
  ${mediaMin.xs`
    font-size: 15px;
  `}
`;

const Price = styled.section`
  font-family: 'SFProDisplay', 'Open Sans', 'Apple SD Gothic Neo',
    'Noto Sans KR', sans-serif;
  font-size: 50px;
  font-weight: 900;
  color: ${({ color }) => color};
  margin-bottom: 30px;
  ${mediaMin.xs`
    margin-bottom: 57px;
  `}
`;
const Unit = styled.span`
  font-family: 'SFProDisplay', 'Open Sans', 'Apple SD Gothic Neo',
    'Noto Sans KR', sans-serif;
  font-size: 17px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.6);
  margin-left: 30px;
`;

const Features = styled.ul``;
const Feature = styled.li`
  display: flex;
  align-items: center;
  font-family: ObjectSans, sans-serif;
  font-size: 18px;
  color: ${({ color }) => color};
  margin-bottom: 10px;
  svg {
    color: ${({ iconColor }) => iconColor};
  }
`;

const Footer = styled.section`
  margin-top: 36px;
  ${mediaMin.xs`
    margin-top: auto;
  `}
`;
const EmailSent = styled.div`
  display: flex;
  margin-bottom: 20px;
  font-family: ObjectSans, sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.white};
  > svg {
    flex: 0 0 24px;
    margin-right: 12px;
  }
`;
const ActionButton = styled.button`
  cursor: pointer;
  outline: none;
  border: 0;
  border-radius: 3px;
  width: 100%;
  height: 50px;
  background-color: ${({ buttonBgColor }) => buttonBgColor};

  color: ${({ color }) => color};
  font-family: ObjectSans, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;

  &:hover {
    ${({ buttonBgColor }) =>
      buttonBgColor === colors.brand500
        ? `
            background-color: ${colors.brand700};
          `
        : `
            opacity: 0.9;
          `};
  }
`;

function PriceList() {
  const history = useHistory();
  const [showEmailForm, setShowEmailForm] = useState();
  const [emailSubmitted, setEmailSubmitted] = useState();
  const priceList = useRef([
    {
      tierName: 'Air',
      tierColor: colors.darkairbrand,
      price: 0,
      features: [
        'No registration',
        'Free',
        'Simple knowledge sharing',
        'Cheers',
      ],
      featureColor: colors.gray900,
      FeatureIcon: Check,
      featureIconColor: colors.darkairbrand,
      buttonLabel: 'Get Started',
      buttonBgColor: colors.darkairbrand,
      buttonTextColor: 'white',
      onClick: async () => {
        LogEventFns.pricing.clickAirGetStartedButton();
        const { eid } = await getStartedAir();
        const { host } = window.location;
        const hostName = host.startsWith('localhost')
          ? 'alphamon.xyz'
          : host.replace('www.', '');
        window.location.href = `https://air.${hostName}/page/${eid}`;
      },
    },
    {
      tierName: 'Standard',
      tierColor: colors.gray900,
      price: 0,
      features: [
        'Workspace',
        'Channel Management',
        'Page Management',
        'Set Permission to Sharing',
        'Invite Guests',
        'Mention',
        'Notification',
        'API Integration',
      ],
      featureColor: colors.gray900,
      FeatureIcon: Check,
      featureIconColor: colors.brand500,
      buttonLabel: 'Get Started',
      buttonBgColor: colors.brand500,
      buttonTextColor: 'white',
      onClick: () => {
        LogEventFns.pricing.clickStandardGetStartedButton();
        history.push('/sign-up');
      },
    },
    {
      isComingSoon: true,
      backgroundColor: colors.brand500,
      tierName: 'Pro',
      tierColor: colors.white,
      price: '$?',
      features: [
        'Workspace Permission',
        'Unlimited Storage',
        'Unlimited Channel/Page',
        'Unlimited Version History',
        'Guest Permission',
        'Premium Features',
      ],
      featureColor: colors.white,
      FeatureIcon: Plus,
      featureIconColor: colors.white,
      buttonLabel: 'Stay Tuned',
      buttonBgColor: colors.white,
      buttonTextColor: colors.brand500,
      onClick: () => {
        LogEventFns.pricing.clickProStayTunedButton();
        setEmailSubmitted(null);
        setShowEmailForm('Pro');
      },
    },
    {
      backgroundColor: colors.darkairbrand,
      tierName: 'Enterprise',
      tierColor: colors.white,
      price: 'Custom',
      features: [
        'Organization Settings',
        'Team Settings',
        'Complicated Permissions',
        'IP Access Management',
        'Single Sign-On (SSO)',
        'Private SaaS',
      ],
      featureColor: colors.white,
      FeatureIcon: Plus,
      featureIconColor: colors.white,
      buttonLabel: 'Contact Us',
      buttonBgColor: colors.white,
      buttonTextColor: colors.darkairbrand,
      onClick: () => {
        LogEventFns.pricing.clickEnterpriseContactUsButton();
        setEmailSubmitted(null);
        setShowEmailForm('Enterprise');
      },
    },
  ]);

  const handleSubmitEmail = tierName => () => {
    setShowEmailForm(false);
    setEmailSubmitted(tierName);
  };

  return (
    <Wrapper>
      {priceList.current.map(
        ({
          isComingSoon,
          backgroundColor,
          tierName,
          tierColor,
          price,
          features,
          featureColor,
          FeatureIcon,
          featureIconColor,
          buttonLabel,
          buttonBgColor,
          buttonTextColor,
          onClick,
        }) => (
          <PriceItem
            key={tierName}
            backgroundColor={backgroundColor}
            showEmailForm={showEmailForm === tierName}
          >
            <Tier color={tierColor}>
              {tierName}
              {isComingSoon && <CommingSoon>Coming Soon</CommingSoon>}
            </Tier>
            <Price color={tierColor}>
              {price >= 0 ? `$${price}` : price}
              {isComingSoon && <Unit>Per user/ per month</Unit>}
            </Price>
            <Features>
              {features.map(feature => (
                <Feature
                  key={feature}
                  color={featureColor}
                  iconColor={featureIconColor}
                >
                  <FeatureIcon />
                  {feature}
                </Feature>
              ))}
            </Features>
            {showEmailForm === tierName ? (
              <EmailForm
                tier={tierName}
                onSubmit={handleSubmitEmail(tierName)}
              />
            ) : (
              <Footer>
                {emailSubmitted === tierName && (
                  <EmailSent>
                    <SuccessIcon /> The email has been sent successfully !
                  </EmailSent>
                )}
                <ActionButton
                  buttonBgColor={buttonBgColor}
                  color={buttonTextColor}
                  onClick={onClick}
                >
                  {buttonLabel}
                </ActionButton>
              </Footer>
            )}
          </PriceItem>
        ),
      )}
    </Wrapper>
  );
}

export default PriceList;
