import React, { useRef } from 'react';
import styled from 'styled-components/macro';

import { mediaMin } from 'themes/media';
import SectionDescription from 'views/Common/SectionDescription';
import SectionTitle from 'views/Common/SectionTitle';
import useSlideShow from 'views/Contents/customHooks/useSlideShow';
import { LogEventFns } from 'analytics/ga';

import { externalCommunicationTabs } from './tabs';
import TabMenuItem from './TabMenuItem';
import {
  StyledSectionWrapper,
  LeftTopFigure,
  ContentWrapper,
  ImageWrapper,
  StyledImage,
  TabMenu,
} from './styled';

const ExternalCommunicationSectionWrapper = styled(StyledSectionWrapper)`
  ${mediaMin.md`
    flex-direction: row;
  `};
`;

const StyledContentWrapper = styled(ContentWrapper)`
  ${mediaMin.md`
    margin-right: 80px;
  `};
`;

const StyledLeftTopFigure = styled(LeftTopFigure)`
  left: -66px;
  top: -85px;
  border-radius: 100% 0 0;
`;

function ExternalCommunicationSection() {
  const tabMenuRef = useRef();

  const { isStartedSlideShow, currentTabIndex, onClickTab } = useSlideShow({
    tabMenuRef,
    tabLength: externalCommunicationTabs.length,
  });

  const handleClickTab = tabIndex => {
    switch (tabIndex) {
      case 0:
        LogEventFns.product.clickInviteExternalCollaboratorsButton();
        break;
      case 1:
        LogEventFns.product.clickSharePagesPubliclyButton();
        break;
    }
    onClickTab(tabIndex);
  };

  return (
    <ExternalCommunicationSectionWrapper id="external-collaboration">
      <StyledContentWrapper>
        <SectionTitle>Collaborate with external parties better</SectionTitle>
        <SectionDescription>
          Inter-organizational collaboration is getting common. Just deal with
          it with the right tool.
        </SectionDescription>
        <TabMenu ref={tabMenuRef}>
          {externalCommunicationTabs.map(
            ({ id, name, description }, tabIndex) => {
              return (
                <TabMenuItem
                  key={id}
                  name={name}
                  description={description}
                  isSelected={
                    isStartedSlideShow && tabIndex === currentTabIndex
                  }
                  onClick={() => handleClickTab(tabIndex)}
                />
              );
            },
          )}
        </TabMenu>
      </StyledContentWrapper>
      <ImageWrapper>
        <StyledLeftTopFigure />
        {externalCommunicationTabs.map(({ id, imgSrc }, tabIndex) => (
          <StyledImage
            key={id}
            src={imgSrc}
            alt="internal-communication"
            display={tabIndex === currentTabIndex ? 'block' : 'none'}
          />
        ))}
      </ImageWrapper>
    </ExternalCommunicationSectionWrapper>
  );
}

export default ExternalCommunicationSection;
