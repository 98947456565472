import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components/macro';
import { color } from 'styled-system';

import ProgressBar from 'views/Common/ProgressBar';
import colors from 'themes/colors';
import { mediaMin } from 'themes/media';

const IconWrapper = styled.div`
  display: flex;
  height: 80px;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
  color: ${colors.brand500};
  border-radius: 8px;
  ${color};
`;

const Name = styled.div`
  height: 40px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 500;
  ${color};
`;

const Wrapper = styled.div`
  cursor: pointer;

  &:hover ${IconWrapper} {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
  }
`;

const ContentWrapper = styled.div`
  margin: 0 5px 16px;

  ${mediaMin.xs`
    margin: 0 10px 16px;
  `};

  ${mediaMin.sm`
    margin: 0 5px 16px;
  `};

  ${mediaMin.md`
    margin: 0 10px 16px;
  `};
`;

const StyledProgressBar = styled(ProgressBar)`
  display: none;

  ${mediaMin.sm`
    display: block;
  `};
`;

function Tab({ id, icon, name, isSelected = false, onClick = _.noop }) {
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    function handleLoadedMetadata(e) {
      setDuration(e.target.duration * 1000);
    }
    const videoElement = document.querySelector(`[data-id="${id}"]`);
    videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    return () => {
      videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  }, []);

  const handleClick = () => {
    if (isSelected) return;
    onClick(id);
  };

  return (
    <Wrapper
      onClick={handleClick}
      hoverBackgroundColor={isSelected ? colors.brand50 : colors.gray80}
    >
      <ContentWrapper>
        <IconWrapper
          backgroundColor={isSelected ? colors.brand50 : colors.transparent}
        >
          {icon}
        </IconWrapper>
        <Name color={isSelected ? colors.brand500 : colors.gray900}>
          {name}
        </Name>
      </ContentWrapper>
      <StyledProgressBar duration={duration} isInProgress={isSelected} />
    </Wrapper>
  );
}

Tab.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.node.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Tab;
