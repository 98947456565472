const helpers = {
  centerFlex: `
display: flex;
align-items: center;
justify-content: center;
`,
  transition: (...props) => `
transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
transition-duration: .2s;
transition-property: ${props.join(', ')};
`,
  movingGradient: (color0, color40, color60, color100) => `
  background: -webkit-linear-gradient(left , ${color0} 0%, ${color40} 40%, ${color60} 60%, ${color100} 100%) repeat;
  -webkit-background-size: 50% 100%;
  -webkit-animation-name: moving-gradient;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  `,
};

export default helpers;
