import React, { useState, useCallback } from 'react';

import { ReactComponent as GoogleLogoWhite } from 'resources/svg/google-white.svg';
import { ReactComponent as SlackIconSVG } from 'resources/svg/slack-logo.svg';
import { ReactComponent as AppleIconSVG } from 'resources/svg/apple-logo.svg';

import { postGetStartedFind, putGetStarted } from 'api/getStarted';
import { getStartedWithAppleFromExtension } from 'api/getStartedWithApple';
import getAppHostName from 'utils/getAppHostName';
import { getGoogleUser } from 'utils/googleAuth';
import { redirectToSlackOAuth } from 'utils/slackAuth';
import { setCookie } from 'utils/cookie';
import {
  sendMessageToChromeExtension,
  EXTERNAL_MESSAGE_TYPES,
} from 'utils/sendMessageToChromeExtension';

import EmailSent from './EmailSent';

const ChromeExtensionSignIn = () => {
  const [mailSent, setMailSent] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [email, setEmail] = useState('');
  const [isVisibleEmailInput, setEmailInputVisibility] = useState(false);
  const handleOnClickGoogleAuth = async e => {
    e.preventDefault();
    const googleUser = await getGoogleUser();
    handleOnGoogleSignin(googleUser);
  };
  const handleOnGoogleSignin = async googleUser => {
    // eslint-disable-next-line no-undef
    ga('send', 'event', 'extension sign in', 'with google account');
    const {
      ok,
      verificationToken,
      verifiedUserName,
      ...res
    } = await putGetStarted(googleUser.getAuthResponse().id_token, 'google');
    if (ok) {
      setCookie('verification', verificationToken, 0, 60);
      setCookie('verified_user_name', verifiedUserName, 0, 60);
      sendMessageToChromeExtension({
        type: EXTERNAL_MESSAGE_TYPES.OPEN_TAB,
        url: `${getAppHostName()}/check-in?from=extension`,
      });
      window.close();
    } else if (res.status === 409 || res.status === 410) {
      // handle error
    }
  };
  const handleOnClickSlackAuth = useCallback(() => {
    redirectToSlackOAuth({
      isExtension: true,
    });
  }, []);
  const handleClickAppleAuth = async () => {
    try {
      const data = await window.AppleID.auth.signIn();
      if (data) {
        getStartedWithAppleFromExtension(
          data.authorization.id_token,
          data.user,
        );
      }
    } catch (e) {
      // ignore error
    }
  };
  const handleClickEmail = () => {
    setEmailInputVisibility(true);
  };
  const handleEmailChange = e => {
    setEmail(e.target.value);
    setErrorMsg('');
  };
  const handleEmailSubmit = async e => {
    e.preventDefault();
    const isEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9]{1,63})$/i;
    if (isEmail.test(email)) {
      // eslint-disable-next-line no-undef
      ga('send', 'event', 'extension sign in', 'with email');
      await postGetStartedFind(email);
      setMailSent(true);
    } else {
      setErrorMsg('The email address is in an invalid format.');
    }
  };

  if (mailSent) {
    return (
      <EmailSent email={email} onClickBackBtn={() => setMailSent(false)} />
    );
  }

  return (
    <div className="crx-sign-in">
      <div className="crx-sign-in__title">
        Hack the productivity to organize your web research
      </div>
      <img
        className="crx-sign-in__main-img"
        src="https://alphamon-asset.s3.ap-northeast-2.amazonaws.com/img/extension/extension-sign-in.png"
      />
      <button
        className="crx-sign-in__google-btn"
        onClick={handleOnClickGoogleAuth}
      >
        <GoogleLogoWhite width={20} />
        Connect with Google
      </button>
      <button
        className="crx-sign-in__slack-btn"
        onClick={handleOnClickSlackAuth}
      >
        <SlackIconSVG width={20} />
        Connect with Slack
      </button>
      <button className="crx-sign-in__apple-btn" onClick={handleClickAppleAuth}>
        <span className="icon" aria-hidden={true}>
          <AppleIconSVG width={20} height={20} />
        </span>
        Connect with Apple
      </button>
      {!isVisibleEmailInput ? (
        <p className="crx-sign-in__email-guide">
          You can also <a onClick={handleClickEmail}>continue with email</a>
        </p>
      ) : (
        <form onSubmit={handleEmailSubmit}>
          <label className="crx-sign-in__email-label">Email Address</label>
          <input
            type="text"
            value={email}
            placeholder="Enter your email"
            className="crx-sign-in__email-input"
            autoFocus
            onChange={handleEmailChange}
          />
          {errorMsg && <p className="crx-sign-in__error-msg">{errorMsg}</p>}
          <button className="crx-sign-in__submit-btn">Get Started</button>
        </form>
      )}
      <p className="crx-sign-in__footer-guide">
        {"It doesn't matter whether you are a new user or already signed up."}
      </p>
    </div>
  );
};

export default ChromeExtensionSignIn;
