import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { getGetStarted } from 'api/getStarted';
import { deleteCredentialsPassword } from 'api/credentialsPassword';
import { Form, FormConsumer, FormGroup, Input } from 'components/ContextForm';
import titleDecoration from 'resources/img/signin-decoration.png';
import resetPasswordMailSent from 'resources/img/reset_password_mail_sent.png';
import inputCheckInvalid from 'resources/svg/input-check-invalid.svg';
import inputCheckValid from 'resources/svg/input-check-valid.svg';
import inputCheck from 'resources/svg/input-check.svg';

import SignInContext from './SignInContext';

class PanelFindPassword extends React.Component {
  emailInputRef = React.createRef();

  state = {
    emailSent: '',
  };

  componentDidMount() {
    const { location } = this.props;
    if (location.state && location.state.email) {
      this.emailInputRef.current.setState({
        value: location.state.email,
      });
    }
  }

  handleOnSubmitForm = async (e, isValid, formValue) => {
    e.preventDefault();
    e.persist();
    if (isValid) {
      const { workspace } = this.context;
      await deleteCredentialsPassword({
        workspaceEid: workspace.eid,
        email: formValue.email,
      });
      this.setState({
        emailSent: formValue.email,
      });
    }
  };

  validEmail = async email => {
    if (!email) {
      return 'Email is required.';
    }
    const { workspace } = this.context;
    const { id } = workspace;
    const { ok, ...res } = await getGetStarted(id, email);
    if (ok) {
      return null;
    }
    if (res.status === 404) {
      return 'There’s no account with this email in this workspace.';
    }
    return 'Something went wrong!';
  };

  renderInputValid = name => {
    return (
      <FormConsumer>
        {({ formData }) => {
          if (formData[name] && !!formData[name].error) {
            return (
              <img className="valid-icon" src={inputCheckInvalid} alt={name} />
            );
          }
          if (formData[name] && !!formData[name].isValid) {
            return (
              <img className="valid-icon" src={inputCheckValid} alt={name} />
            );
          }
          return <img className="valid-icon" src={inputCheck} alt={name} />;
        }}
      </FormConsumer>
    );
  };

  render() {
    const { emailSent } = this.state;
    return (
      <div className="sign-in-page__panel sign-in-page__panel--find-password">
        <h1 className="sign-in-page__title">
          <span>
            Find your password
            <img className="sign-in-page__title__deco" src={titleDecoration} />
          </span>
        </h1>
        {emailSent ? (
          <div className="sign-in-page__panel--find-password__sent">
            <img src={resetPasswordMailSent} />
            <p>
              We've sent a link to <b>{emailSent}</b>.<br />
              Please check it out right now.
            </p>
          </div>
        ) : (
          <div className="sign-in-page__panel--find-password__form">
            <Form name="findPassword" onSubmit={this.handleOnSubmitForm}>
              <FormGroup
                name="email"
                className="sign-in-page__panel__form-group"
              >
                <p className="sign-in-page__panel__label">
                  Enter your <b>email</b>
                </p>
                <div className="sign-in-page__panel__form-control-wrap">
                  <Input
                    ref={this.emailInputRef}
                    name="email"
                    type="text"
                    placeholder="you@example.com"
                    className="sign-in-page__panel__form-control"
                    validator={this.validEmail}
                    validateTimeout={1000}
                    validateOnBlur
                    autoFocus
                  />
                  {this.renderInputValid('email')}
                </div>
              </FormGroup>
              <button
                type="submit"
                className="sign-in-page__panel__btn-submit sign-in-page__panel--find-password__btn-submit"
              >
                Send password reset link
              </button>
            </Form>
          </div>
        )}
      </div>
    );
  }
}

PanelFindPassword.propTypes = {
  location: PropTypes.object.isRequired,
};

PanelFindPassword.contextType = SignInContext;
export default withRouter(PanelFindPassword);
