import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import {
  postEntranceWithPassword,
  postEntranceWithGoogle,
  postEntranceWithApple,
} from 'api/entrance';
import { fetchGraphqlWorkspace } from 'api/fetchGraphqlWorkspace';
import { getGetStarted, postGetStartedSignIn } from 'api/getStarted';
import { getGoogleUser } from 'utils/googleAuth';
import { redirectToSlackOAuth } from 'utils/slackAuth';
import { COOKIE_NAMES, COOKIE_EXPIRED, setCookie } from 'utils/cookie';
import { Form, FormGroup, Input, FormConsumer } from 'components/ContextForm';

import IconFavGoogle from 'resources/img/fav-google.png';
import IconFavSlack from 'resources/img/fav-slack.png';
import titleDecoration from 'resources/img/signin-decoration.png';
import { ReactComponent as inputCheck } from 'resources/svg/input-check.svg';
import { ReactComponent as inputCheckValid } from 'resources/svg/input-check-valid.svg';
import { ReactComponent as inputCheckInvalid } from 'resources/svg/input-check-invalid.svg';
import { ReactComponent as AppleIconSVG } from 'resources/svg/apple-logo.svg';

import SignInContext from './SignInContext';

class PanelSignIn extends React.Component {
  emailInputRef = React.createRef();

  state = {
    hasPassword: false,
    invalidPassword: false,
    invalidGoogleAccount: false,
    invalidSlackAccount: false,
  };

  async componentDidMount() {
    const {
      location: { search },
      history,
    } = this.props;

    const { workspace, selectWorkspace } = this.context;

    const urlSearchParams = new URLSearchParams(search);
    const workspaceEid = urlSearchParams.get('workspace');
    if (workspaceEid) {
      if (!workspace) {
        const response = await fetchGraphqlWorkspace(workspaceEid);
        if (!response.ok || !response.data.fetch) {
          history.replace('/sign-in');
          return;
        }
        const workspace = response.data.fetch;
        selectWorkspace(workspace);
      }
    } else {
      history.replace('/sign-in');
      return;
    }

    const isGoogleLogin = urlSearchParams.get('googleLogin');
    if (isGoogleLogin) {
      this.handleOnClickGoogleSignIn();
    }

    const isSlackError = urlSearchParams.get('error');
    if (isSlackError) {
      this.setState({
        invalidSlackAccount: true,
      });
    }

    history.replace(`/sign-in/sign-in-to-workspace?workspace=${workspaceEid}`);
  }

  handleOnClickGoogleSignIn = async () => {
    const googleUser = await getGoogleUser();
    this.handleOnGoogleSignin(googleUser);
  };

  handleOnGoogleSignin = async googleUser => {
    const { workspace } = this.context;
    const { id } = workspace;
    const res = await postEntranceWithGoogle(
      id,
      googleUser.getAuthResponse().id_token,
    );
    if (res.ok) {
      const { exchangeToken } = res;
      setCookie(
        COOKIE_NAMES.EXCHANGE_TOKEN,
        exchangeToken,
        COOKIE_EXPIRED.EXCHANGE_TOKEN.cDay,
      );
      window.location.href = res.redirect;
    } else if (res.status === 410) {
      this.setState({ invalidGoogleAccount: true });
    }
  };

  handleOnClickSlackSignIn = () => {
    const { workspace } = this.context;
    const { id } = workspace;
    redirectToSlackOAuth({
      workspaceId: id,
    });
  };

  handleClickAppleAuth = async () => {
    try {
      const data = await window.AppleID.auth.signIn();
      const { workspace } = this.context;
      const { id } = workspace;
      const res = await postEntranceWithApple(
        id,
        data.authorization.id_token,
        data.user,
      );
      if (res.ok) {
        const { exchangeToken } = res;
        setCookie(
          COOKIE_NAMES.EXCHANGE_TOKEN,
          exchangeToken,
          COOKIE_EXPIRED.EXCHANGE_TOKEN.cDay,
        );
        window.location.href = res.redirect;
      } else if (res.status === 410) {
        this.setState({ invalidGoogleAccount: true });
      }
    } catch (e) {
      // ignore error
    }
  };

  validEmail = async email => {
    if (!email) {
      return 'Email is required.';
    }

    const { workspace } = this.context;
    const { id } = workspace;
    const { ok, secretAssigned, ...res } = await getGetStarted(id, email);
    if (ok) {
      if (secretAssigned) {
        this.setState({ hasPassword: true });
      }
      return null;
    }
    if (res.status === 404) {
      return 'There’s no account with this email in this workspace.';
    }

    return '';
  };

  validatePassword = pw => {
    if (pw) {
      return null;
    }
    return 'Password is required.';
  };

  handleOnSubmit = async (e, isValid, formData) => {
    e.preventDefault();
    if (isValid) {
      const { hasPassword } = this.state;
      const { workspace } = this.context;
      const { id, eid } = workspace;
      if (hasPassword) {
        const res = await postEntranceWithPassword(
          id,
          formData.email,
          formData.password,
        );
        if (res.ok) {
          const { exchangeToken } = res;
          setCookie(
            COOKIE_NAMES.EXCHANGE_TOKEN,
            exchangeToken,
            COOKIE_EXPIRED.EXCHANGE_TOKEN.cDay,
          );
          window.location.href = res.redirect;
        } else if (res.status === 409) {
          this.setState({ invalidPassword: true });
        }
      } else {
        const res = await postGetStartedSignIn(formData.email, eid);
        if (res.ok) {
          this.props.history.push(
            `/mail-sent?email=${encodeURIComponent(formData.email)}`,
          );
        }
      }
    }
  };

  handleClickForgotPassword = () => {
    const { history } = this.props;
    history.push('/sign-in/find-password', {
      email: this.emailInputRef.current.value,
    });
  };

  handleChangePassword = () => {
    this.setState({
      invalidPassword: false,
    });
  };

  renderInputValid = name => {
    return (
      <FormConsumer>
        {({ formData }) => {
          if (formData[name] && !!formData[name].error) {
            return (
              <img
                className="valid-icon"
                src={inputCheckInvalid}
                alt="invalid"
              />
            );
          }
          if (formData[name] && !!formData[name].isValid) {
            return (
              <img className="valid-icon" src={inputCheckValid} alt="valid" />
            );
          }
          return <img className="valid-icon" src={inputCheck} alt="check" />;
        }}
      </FormConsumer>
    );
  };

  render() {
    const { workspace } = this.context;
    if (workspace == null) {
      return null;
    }
    const { eid, name } = workspace;
    const {
      hasPassword,
      invalidPassword,
      invalidGoogleAccount,
      invalidSlackAccount,
    } = this.state;
    return (
      <div className="sign-in-page__panel--signin">
        <h1 className="sign-in-page__title sign-in-page__panel--signin__title">
          <span>
            <img
              className="sign-in-page__title__deco"
              src={titleDecoration}
              alt="deco"
            />
            Sign in to {name}
          </span>
        </h1>
        <p className="sign-in-page__panel--signin__url">{eid}.additor.io</p>
        <div className="sign-in-page__panel--signin__form">
          <Form name="findWorkspace" onSubmit={this.handleOnSubmit}>
            <FormGroup
              name="email"
              className="sign-in-page__panel__form-group sign-in-page__panel--signin__form-group"
            >
              <p className="sign-in-page__panel__label">
                Enter your <b>email</b>
              </p>
              <div className="sign-in-page__panel__form-control-wrap">
                <Input
                  innerRef={this.emailInputRef}
                  name="email"
                  type="text"
                  placeholder="you@example.com"
                  className="sign-in-page__panel__form-control"
                  validator={this.validEmail}
                  validateTimeout={1000}
                  autoFocus
                />
                {this.renderInputValid('email')}
              </div>
            </FormGroup>
            {hasPassword && (
              <FormGroup
                name="password"
                className="sign-in-page__panel__form-group sign-in-page__panel--signin__form-group"
                error={invalidPassword ? 'Invalid Password' : ''}
              >
                <p className="sign-in-page__panel__label" />
                <div className="sign-in-page__panel__form-control-wrap">
                  <Input
                    name="password"
                    type="password"
                    className="sign-in-page__panel__form-control"
                    placeholder="Password"
                    validator={this.validatePassword}
                    validateOnBlur
                    onChange={this.handleChangePassword}
                  />
                </div>
              </FormGroup>
            )}
            <button
              type="submit"
              className="sign-in-page__panel__btn-submit sign-in-page__panel--signin__btn-submit"
            >
              Sign in
            </button>
          </Form>
        </div>
        <div className="sign-in-page__panel--signin__or">or</div>
        <button
          type="button"
          className="sign-in-page__panel--signin__btn-google"
          onClick={this.handleOnClickGoogleSignIn}
        >
          <span className="icon" aria-hidden={true}>
            <img src={IconFavGoogle} alt="google" />
          </span>
          Sign in with Google
        </button>
        {invalidGoogleAccount && (
          <p className="sign-in-page__panel--signin__error-google">
            There’s no matched account with this google account in this
            workspace.
          </p>
        )}
        <button
          type="button"
          className="sign-in-page__panel--signin__btn-slack"
          onClick={this.handleOnClickSlackSignIn}
        >
          <span className="icon" aria-hidden={true}>
            <img src={IconFavSlack} alt="slack" />
          </span>
          Sign in with Slack
        </button>
        <button
          type="button"
          className="sign-in-page__panel--signin__btn-apple"
          onClick={this.handleClickAppleAuth}
        >
          <span className="icon" aria-hidden={true}>
            <AppleIconSVG width={20} height={20} />
          </span>
          Sign in with Apple
        </button>
        {invalidSlackAccount && (
          <p className="sign-in-page__panel--signin__error-slack">
            There’s no matched account with this slack account in this
            workspace.
          </p>
        )}
        <p className="sign-in-page__panel--signin__forgot">
          <a onClick={this.handleClickForgotPassword}>
            <span>Forgot password?</span>
          </a>
          <span className="sign-in-page__panel--signin__forgot__dot" />
          <Link to="/sign-in">
            <span>Forgot which email you used?</span>
          </Link>
        </p>
      </div>
    );
  }
}

PanelSignIn.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(PanelSignIn);
PanelSignIn.contextType = SignInContext;
