import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import EmailInput from 'views/Common/SignInToAdditor/EmailInput';
import SigninWithOAuth from 'views/Common/SignInToAdditor/SigninWithOAuth';

const SignInWrapper = styled.div`
  width: 100%;
`;

function SignInToAdditor({
  onClickEmailInput,
  onSubmit,
  onClickSlackButton,
  onClickAppleButton,
  onClickGoogleButton,
  flatOAuthButton = false,
}) {
  return (
    <SignInWrapper>
      <EmailInput onClickEmailInput={onClickEmailInput} onSubmit={onSubmit} />
      <SigninWithOAuth
        flat={flatOAuthButton}
        onClickSlackButton={onClickSlackButton}
        onClickAppleButton={onClickAppleButton}
        onClickGoogleButton={onClickGoogleButton}
      />
    </SignInWrapper>
  );
}

SignInToAdditor.propTypes = {
  onClickEmailInput: PropTypes.func,
  onSubmit: PropTypes.func,
  onClickSlackButton: PropTypes.func,
  onClickAppleButton: PropTypes.func,
  onClickGoogleButton: PropTypes.func,
  flatOAuthButton: PropTypes.bool,
};

export default SignInToAdditor;
