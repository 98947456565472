import React from 'react';
import { Link } from 'react-router-dom';

import NameLogo from 'components/NameLogo';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import styled from 'styled-components/macro';
import colors from 'themes/colors';
import helpers from 'themes/helpers';
import { ReactComponent as SvgMobileGNB } from 'views/gnb.svg';
import MobileNavigationPopup from '../../components/MobileNavigationPopup';

import LayoutContext from './LayoutContext';

const MobileGNBIcon = styled(SvgMobileGNB)`
  color: ${colors.brand500};
  width: 24px;
  height: 24px;
  margin-left: 20px;
  cursor: pointer;
  ${helpers.transition('background-color')};
  border-radius: 4px;
  &:hover {
    background-color: ${colors.gray80};
  }
`;

class Header extends React.Component {
  state = {
    mobileGNBVisible: false,
  };

  handleClickMobileGNBIcon = () => {
    this.setState({
      mobileGNBVisible: true,
    });
  };

  handleClickCloseMobileGNB = () => {
    this.setState({
      mobileGNBVisible: false,
    });
  };

  handleMouseEnterLink = () => {};

  handleMouseLeaveLink = () => {};

  render() {
    const { mobileGNBVisible } = this.state;
    if (this.context.layout.hideHeader) {
      return null;
    }

    const { theme } = this.context.layout;

    return (
      <>
        <header
          className={`header root__header root__header--${theme} px3 xs-px0`}
          role="banner"
        >
          <div className="root__header__container mw100 mx-auto xs-px2">
            <Link className="root__header__namelogo" to="/home">
              <NameLogo />
            </Link>
            <Menu className="list root__header__list--mobile">
              <MenuItem className="root__header__listitem mr3">
                <Link
                  to="/sign-up"
                  className="root__header__listitem__link--signup"
                >
                  Sign up
                </Link>
              </MenuItem>
              <MenuItem className="root__header__listitem mr3">
                <MobileGNBIcon onClick={this.handleClickMobileGNBIcon} />
              </MenuItem>
            </Menu>
            <Menu className="list root__header__list">
              <MenuItem className="root__header__listitem mr3">
                <Link to="/product" className="root__header__listitem__link">
                  Product
                </Link>
              </MenuItem>
              <MenuItem className="root__header__listitem mr3">
                <Link
                  to="/slack-integration"
                  className="root__header__listitem__link"
                >
                  Integration
                </Link>
              </MenuItem>
              <MenuItem className="root__header__listitem mr3">
                <Link to="/pricing" className="root__header__listitem__link">
                  Pricing
                </Link>
              </MenuItem>
              <MenuItem className="root__header__listitem mr3">
                <Link to="/about" className="root__header__listitem__link">
                  About us
                </Link>
              </MenuItem>
              <MenuItem className="root__header__listitem mr3">
                <Link
                  to="/sign-in"
                  className="root__header__listitem__link--signin"
                >
                  Sign in
                </Link>
              </MenuItem>
              <MenuItem className="root__header__listitem--signup">
                <Link
                  to="/sign-up"
                  className="root__header__listitem__link--signup"
                >
                  Sign up
                </Link>
              </MenuItem>
            </Menu>
          </div>
        </header>
        {mobileGNBVisible && (
          <MobileNavigationPopup onClose={this.handleClickCloseMobileGNB} />
        )}
      </>
    );
  }
}

Header.propTypes = {};

Header.defaultProps = {};

Header.contextType = LayoutContext;

export default Header;
