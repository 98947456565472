import React from 'react';
import Header from 'components/Header';
import Hero from 'components/Hero';
import Section from 'components/Section';
import Page from 'components/Page';
import LayoutContext from 'containers/Root/LayoutContext';
import { LogEventFns } from 'analytics/ga';
import { ReactComponent as SlackIconSVG } from 'resources/svg/slack-logo.svg';

import HeroImgSrc from './assets/slack-hero.png';
import Divider from './Divider';

class SlackPage extends React.Component {
  render() {
    return (
      <Page id="slack">
        <Hero className="hero--slack py5 mw100 mx-auto">
          <div className="hero__main-phrases-wrapper">
            <Header className="hero__h1 xs-mb0">
              Perfect harmony
              <br />
              for productivity hackers
            </Header>
            <p className="hero__description">
              Make your workflow more efficient with Additor  which is the
              missing half of Slack
            </p>
            <a
              href="https://additor.slack.com/apps/ABSCS04FL-additor"
              target="_blank"
              rel="noreferrer noopener"
              onClick={LogEventFns.slackIntegration.clickAddToSlackButton}
            >
              <button className="hero__slack-button">
                <SlackIconSVG />
                <span>Add to Slack</span>
              </button>
            </a>
          </div>
          <div className="hero__img-wrapper">
            <img
              alt=""
              className="hero__shape__container__img"
              src={HeroImgSrc}
            />
          </div>
        </Hero>
        <div className="hero__block-wrapper">
          <img
            className="hero__block1"
            src="https://alphamon-asset.s3.ap-northeast-2.amazonaws.com/img/landing/slack-hero-block1.png"
          />
        </div>
        <Section className="s-grab-idea xs-pt1 pt4 px3 xs-px2 text-center mw100">
          <Header as="h2">Grab the ideas and make them work</Header>
          <p className="s-grab-idea__description">
            Spark ideas in Slack and make them well-organized structure in
            Additor
          </p>
          <div className="s-grab-idea__video-wrapper">
            <video
              autoPlay
              loop
              muted
              playsInline
              src="https://alphamon-asset.s3.ap-northeast-2.amazonaws.com/img/landing/slack-integration.mp4"
            />
          </div>
        </Section>
        <Divider />
        <Section className="s-message-to-document xs-pt1 pt4 px3 xs-px2 text-center mw100">
          <Header as="h2">Make Slack messages documented</Header>
          <p className="s-message-to-document__description">
            Important knowledge deserves to be organized
          </p>
          <div className="s-message-to-document__img-wrapper">
            <img src="https://alphamon-asset.s3.ap-northeast-2.amazonaws.com/img/landing/slack-message-to-document.png" />
          </div>
        </Section>
        <Divider />
        <Section className="s-note-command xs-pt1 pt4 px3 xs-px2 text-center mw100">
          <Header as="h2">Make a note with a simple command</Header>
          <p className="s-note-command__description">
            Don't let your invaluable idea disappear
          </p>
          <div className="s-note-command__img-wrapper">
            <img src="https://alphamon-asset.s3.ap-northeast-2.amazonaws.com/img/landing/slack-note-command.png" />
          </div>
        </Section>
        <Divider />
        <Section className="s-get-noti xs-pt1 pt4 px3 xs-px2 mw100">
          <div className="s-get-noti__phrase-wrapper">
            <Header as="h2">
              Get notifications
              <br />
              within Slack
            </Header>
            <p className="s-get-noti__description">
              Check the important updates
              <br />  that matter to you without being distracted.
            </p>
          </div>
          <div className="s-get-noti__img-wrapper">
            <img src="https://alphamon-asset.s3.ap-northeast-2.amazonaws.com/img/landing/slack-get-notification.png" />
          </div>
        </Section>
      </Page>
    );
  }
}

SlackPage.contextType = LayoutContext;

export default SlackPage;
