import React from 'react';
import PropTypes from 'prop-types';

const MenuItem = ({
  className = 'listitem',
  onClick = () => {},
  children = null,
}) => {
  return (
    <li className={className} onClick={onClick}>
      {children}
    </li>
  );
};

MenuItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default MenuItem;
