import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Header = ({
  as = 'h1',
  sub = false,
  className = '',
  children = null,
}) => {
  const Element = sub ? 'div' : as;
  const classname = cn(as, className);
  return <Element className={classname}>{children}</Element>;
};

Header.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
  sub: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Header;
