import React from 'react';

import { appServerURL } from 'config/paths';
import Icon from 'components/Icon';
import Image from 'components/Image';
import Link from 'components/Link';
import Button from 'components/Button';
import Header from 'components/Header';
import Hero from 'components/Hero';
import Page from 'components/Page';
import Section from 'components/Section';

export default class ProductPage extends React.Component {
  render() {
    return (
      <Page className="page" id="integration">
        <Hero>
          <div className="hero__content">
            <header className="hero__header">
              <Header className="hero__header__h1">Additor for Slack</Header>
              <Header sub className="hero__header__sub-header">
                Perfectly compatible knowledge wiki with Slack
              </Header>
              <Button
                className="hero__header__button-signup"
                icon
                round
                theme="white"
              >
                <Icon name="slack" />
                Connect with Slack
              </Button>
              <p className="hero__header__p">
                <span className="hero__header__span">
                  Already using Additor?
                </span>
                <Link
                  theme="gray"
                  className="hero__header__a"
                  href={`${appServerURL}/sign_in`}
                >
                  Sign in
                </Link>
              </p>
              <p className="hero__header__p">
                <span className="hero__header__span">
                  Sign up with Google or email first?
                </span>
                <Link
                  href={`${appServerURL}/sign_up`}
                  className="hero__header__a"
                >
                  Sign up
                </Link>
              </p>
            </header>
            <div className="hero__illustration">
              <Image
                className="hero__illustration__image"
                src="https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/illustration/slack-integration.png"
                width={375}
              />
            </div>
          </div>
        </Hero>
        <Section>
          <div className="wrap-center">
            <Header as="h3">Turn Slack messages into organized content</Header>
            <Header as="h6">
              Your important knowledge already has gone away
            </Header>
          </div>
        </Section>
        <Section>
          <div className="wrap-center">
            <Header as="h3">Get notification within Slack channels</Header>
            <Header as="h6">Keep aligned to the collaborative workflow</Header>
          </div>
        </Section>
        <Section>
          <div className="wrap-center">
            <Header as="h3">Try it now</Header>
            <Header as="h6">It's free and works across many devices.</Header>
          </div>
        </Section>
      </Page>
    );
  }
}
