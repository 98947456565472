import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Switch, Route, Redirect } from 'react-router';
import { withRouter } from 'react-router';

import Page from 'components/Page';
import LayoutContext from 'containers/Root/LayoutContext';
import { LogEventFns } from 'analytics/ga';

import textureTriangle from 'resources/img/texture-triangle.png';
import textureCircleAndSquare from 'resources/img/circle-and-square.png';
import signInGirl from 'resources/img/signin_girl.png';
import signInLeftHand from 'resources/img/signin_left_hand.png';
import signInRightHand from 'resources/img/signin_right_hand.png';
import { ReactComponent as LinkArrow } from 'resources/svg/link-arrow.svg';

import SignInPanel from './PanelSignIn';
import PanelSignInToWorkspace from './PanelSignInToWorkspace';
import PanelSignInWithEmail from './PanelSignInWithEmail';
import FindWSPanel from './PanelFindWorkspace';
import FindPasswordPanel from './PanelFindPassword';
import { SignInContextProvider } from './SignInContext';

class SignIn extends React.Component {
  state = {
    selectedWorkspace: null,
  };

  componentDidMount() {
    this.context.updateLayout({
      theme: 'purple',
      hideHeader: false,
      hideFooter: true,
    });
  }

  componentWillUnmount() {
    this.context.updateLayout({
      theme: 'white',
      hideFooter: false,
    });
  }

  selectWorkspace = selectedWorkspace => {
    this.setState({ selectedWorkspace });
  };

  render() {
    const {
      location: { pathname },
    } = this.props;
    const { selectedWorkspace: workspace } = this.state;
    return (
      <Page className="sign-in-page" id="sign-in-page">
        <div className="sign-in-page__panel-wrap">
          <div className="sign-in-page__panel">
            <SignInContextProvider
              value={{
                workspace,
                selectWorkspace: this.selectWorkspace,
              }}
            >
              <Switch>
                <Route path="/sign-in" exact component={PanelSignInWithEmail} />
                <Route
                  path="/sign-in/workspace"
                  component={PanelSignInToWorkspace}
                />
                <Route path="/sign-in/find-workspace" component={FindWSPanel} />
                <Route
                  path="/sign-in/sign-in-to-workspace"
                  component={SignInPanel}
                />
                <Route
                  path="/sign-in/find-password"
                  component={FindPasswordPanel}
                />
                <Redirect to="/sign-in" />
              </Switch>
            </SignInContextProvider>
          </div>
          <div
            className="bg-texture bg-texture--triangle"
            style={{ backgroundImage: `url(${textureTriangle})` }}
          />
          <div
            className="bg-texture bg-texture--circle"
            style={{ backgroundImage: `url(${textureCircleAndSquare})` }}
          />
          {pathname !== '/sign-in/find-workspace' && (
            <>
              <div
                className="bg-girl bg-girl--body"
                style={{ backgroundImage: `url(${signInGirl})` }}
              />
              <div
                className="bg-girl bg-girl--left-hand"
                style={{ backgroundImage: `url(${signInLeftHand})` }}
              />
              <div
                className="bg-girl bg-girl--right-hand"
                style={{ backgroundImage: `url(${signInRightHand})` }}
              />
            </>
          )}
          <div className="sign-in-page__footer">
            {pathname === '/sign-in' && (
              <p className="sign-in-page__footer__p">
                Know your workspace URL?&nbsp;
                <Link
                  className="sign-in-page__footer__a"
                  to="/sign-in/workspace"
                  onClick={LogEventFns.signIn.clickDirectlySignInButton}
                >
                  Directly sign in to it here <LinkArrow />
                </Link>
              </p>
            )}
            {(pathname === '/sign-in/workspace' ||
              pathname === '/sign-in/sign-in-to-workspace') && (
              <p className="sign-in-page__footer__p">
                Don’t know your workspace’s URL?&nbsp;
                <Link className="sign-in-page__footer__a" to="/sign-in">
                  Find the workspaces with email <LinkArrow />
                </Link>
              </p>
            )}
            <p className="sign-in-page__footer__p sign-in-page__footer__p--create-ws">
              Wanna start your own project?&nbsp;
              <Link
                className="sign-in-page__footer__a"
                to="/sign-up"
                onClick={LogEventFns.signIn.clickCreateANewWorkspaceButton}
              >
                Create a new workspace here <LinkArrow />
              </Link>
            </p>
          </div>
        </div>
      </Page>
    );
  }
}

SignIn.propTypes = {
  location: PropTypes.object.isRequired,
};

SignIn.contextType = LayoutContext;

export default withRouter(SignIn);
