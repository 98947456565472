import styled from 'styled-components/macro';
import { layout } from 'styled-system';
import colors from 'themes/colors';
import { mediaMin } from 'themes/media';
import SectionDescription from 'views/Common/SectionDescription';
import SectionTitle from 'views/Common/SectionTitle';
import { SectionWrapper } from '../styled';

export const StyledSectionWrapper = styled(SectionWrapper)`
  display: flex;
  flex-direction: column;

  ${mediaMin.md`
    justify-content: space-between;
  `};
`;

export const ContentWrapper = styled.div`
  margin-bottom: 20px;

  ${mediaMin.md`
    max-width: 430px;
    margin-bottom: 0;
  `};

  ${mediaMin.lg`
    max-width: 528px;
  `};

  ${SectionTitle}, ${SectionDescription} {
    ${mediaMin.md`
      text-align: inherit;
    `};
  }
`;

export const LeftTopFigure = styled.div`
  position: absolute;
  width: 132px;
  height: 132px;
  background-color: ${colors.subbrand300};
  display: none;

  ${mediaMin.lg`
    display: block;  
  `};
`;

export const ImageWrapper = styled.div`
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: 100%;

  ${mediaMin.md`
    max-width: 491px;
  `};

  ${mediaMin.lg`
    max-width: 500px;
  `};
`;

export const StyledImage = styled.img`
  position: relative;
  width: 100%;
  z-index: 1;
  ${layout};
`;

export const TabMenu = styled.div``;
