import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Button = ({
  icon = false,
  textAlign = 'center',
  theme = 'default',
  minimal = false,
  round = false,
  shadowEffect = 'hover',
  onClick = () => {},
  className = '',
  children = null,
}) => {
  const classname = cn('button', className, {
    'button--icon': icon,
    'button--white': theme === 'white',
    'button--full-white': theme === 'full-white',
    'button--gray': theme === 'gray',
    'button--minimal': minimal,
    'button--round': round,
    'button--shadow-on-hover': shadowEffect === 'hover',
    [`text--${textAlign}`]: true,
  });
  return (
    <button className={classname} onClick={onClick}>
      {children}
    </button>
  );
};

Button.propTypes = {
  icon: PropTypes.bool,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  theme: PropTypes.oneOf(['white', 'gray', 'full-white', 'default']),
  shadowEffect: PropTypes.oneOf(['hover', 'click', 'none']),
  minimal: PropTypes.bool,
  round: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Button;
