import React, { useState, useEffect } from 'react';

import Flat from './Flat';
import ScrollInteraction from './ScrollInteraction';

const DESKTOP_SIZE = 1200;

function WorkflowSection() {
  const [isSmallerThanDesktopSize, setIsSmallerThanDesktopSize] = useState(
    window.innerWidth <= DESKTOP_SIZE,
  );

  useEffect(() => {
    function handleResize() {
      setIsSmallerThanDesktopSize(window.innerWidth <= DESKTOP_SIZE);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isSmallerThanDesktopSize) {
      // ScrollInteraction 을 보여주게 되는 경우 스크롤을 맨위에 위치시켜야 올바르게 보임
      window.scrollTo(0, 0);
    }
  }, [isSmallerThanDesktopSize]);

  return isSmallerThanDesktopSize ? <Flat /> : <ScrollInteraction />;
}

export default WorkflowSection;
