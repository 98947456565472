export default async function getStartedAir() {
  const { host } = window.location;
  const hostName = host.startsWith('localhost')
    ? 'alphamon.xyz'
    : host.replace('www.', '');

  const baseUrl = `https://air.${hostName}/api/v2`;
  const res = await fetch(`${baseUrl}/pages/on-air`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'access-control-allow-credentials': true,
    },
  });
  if (res.ok) {
    const { eid } = await res.json();
    return { ok: true, eid };
  }
  return { ok: false, status: res.status };
}
