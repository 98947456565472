import {
  sendMessageToChromeExtension,
  EXTERNAL_MESSAGE_TYPES,
} from 'utils/sendMessageToChromeExtension';
import { putGetStarted } from './getStarted';
import { setCookie } from '../utils/cookie';
import getAppHostName from '../utils/getAppHostName';

export default async function getStartedWithApple(idToken, user) {
  let extraCredential = {};
  if (user) {
    extraCredential.info = {
      name: user.name,
    };
  }
  const { ok, verificationToken, verifiedUserName } = await putGetStarted(
    idToken,
    'apple',
    extraCredential,
  );
  if (ok) {
    setCookie('verification', verificationToken, 0, 60);
    setCookie('verified_user_name', verifiedUserName, 0, 60);
    window.location.href = `${getAppHostName()}/check-in`;
  }
}

export async function getStartedWithAppleFromExtension(idToken, user) {
  let extraCredential = {};
  if (user) {
    extraCredential.info = {
      name: user.name,
    };
  }
  const { ok, verificationToken, verifiedUserName } = await putGetStarted(
    idToken,
    'apple',
    extraCredential,
  );
  if (ok) {
    setCookie('verification', verificationToken, 0, 60);
    setCookie('verified_user_name', verifiedUserName, 0, 60);
    sendMessageToChromeExtension({
      type: EXTERNAL_MESSAGE_TYPES.OPEN_TAB,
      url: `${getAppHostName()}/check-in?from=extension`,
    });
    window.close();
  }
}
