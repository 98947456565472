import React from 'react';
import styled from 'styled-components/macro';
import colors from 'themes/colors';
import { mediaMin } from 'themes/media';
import { LogEventFns } from 'analytics/ga';
import SectionTitle from 'views/Common/SectionTitle';
import SectionDescription from 'views/Common/SectionDescription';
import SignInToAdditor from 'views/Common/SignInToAdditor';

import backgroundPatternSvgUrl from './product-bottom-background-pattern.svg';
import { SectionWrapper } from '../styled';

const StyledSectionWrapper = styled(SectionWrapper)`
  width: 100%;
  padding: 0;
  ${mediaMin.xs`
     padding-top: 160px;
  `};
`;

const BackgroundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1b1659;
  background-image: url(${backgroundPatternSvgUrl});
  background-repeat: repeat;
  padding: 50px 16px 60px;

  ${mediaMin.xs`
    border-radius: 8px;
  `};

  ${mediaMin.md`
    flex-direction: row; 
    padding: 100px 60px 106px;
  `};

  ${SectionTitle}, ${SectionDescription} {
    max-width: 369px;
    margin-left: auto;
    margin-right: auto;
    color: ${colors.white};
    ${mediaMin.md`
      text-align: left;
    `};
  }
  ${SectionDescription} {
    margin-bottom: 0;
  }

  .sign-in-with-oauth__wrapper,
  .email-input__wrapper {
    ${mediaMin.md`
      justify-content: flex-end; 
    `};
  }
`;

const Box = styled.div`
  text-align: center;
  
  & + & {
    margin: 40px 0 0 0;
    ${mediaMin.md`
      margin: 0 0 0 60px;
      flex-grow: 1;
    `}
`;
function BottomCtaSection() {
  return (
    <StyledSectionWrapper>
      <BackgroundWrapper>
        <Box>
          <SectionTitle>Amplify your team productivity</SectionTitle>
          <SectionDescription>
            Don't waste your potential, please
          </SectionDescription>
        </Box>
        <Box>
          <SignInToAdditor
            onClickEmailInput={LogEventFns.product.clickBottomEmailInput}
            onSubmit={LogEventFns.product.clickBottomGetStartedButton}
            onClickSlackButton={LogEventFns.product.clickSlackSignInButton}
            onClickAppleButton={LogEventFns.product.clickAppleSignInButton}
            onClickGoogleButton={LogEventFns.product.clickGoogleSignInButton}
            flatOAuthButton
          />
        </Box>
      </BackgroundWrapper>
    </StyledSectionWrapper>
  );
}

export default BottomCtaSection;
