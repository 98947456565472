import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';

import * as Yup from 'yup';

import sendEmailToSlack from 'api/sendEmailToSlack';

import colors from 'themes/colors';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
`;
const Description = styled.p`
  margin: 0;
  font-family: ObjectSans, sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.white};
`;
const Error = styled.p`
  position: absolute;
  top: 14px;
  left: 0;
  margin: 0;
  font-family: ObjectSans, sans-serif;
  font-size: 13px;
  color: #fc747b;
`;
const InputWrapper = styled.div`
  position: relative;
  padding-top: 42px;
  background-color: transparent;
`;
const Input = styled.input`
  padding: 0 10px;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
  border: 0;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.15);
  font-family: ObjectSans, sans-serif;
  font-size: 18px;
  color: ${colors.white};
  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
`;

const Button = styled.button`
  cursor: pointer;
  outline: none;
  border: 0;
  border-radius: 3px;
  background-color: ${colors.white};
  margin-top: 20px;
  height: 50px;

  font-family: ObjectSans, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: ${({ isProTier }) =>
    isProTier ? colors.brand500 : colors.darkairbrand};

  &:hover {
    opacity: 0.9;
  }
`;

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email('The email-address is in an invalid format.')
    .required('Required'),
});

function EmailForm({ tier, onSubmit }) {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: EmailSchema,
    onSubmit: async values => {
      const { email } = values;
      if (!email) {
        return;
      }
      const message = {
        attachments: [
          {
            fallback: email,
            color: '#7552f6',
            author_name: 'Landing Pricing Page',
            text: `Add to potential user list`,
            fields: [
              {
                title: 'tier',
                value: tier,
                short: false,
              },
              {
                title: 'email',
                value: email,
                short: false,
              },
            ],
          },
        ],
      };
      await sendEmailToSlack(message);
      onSubmit();
    },
  });
  const isProTier = tier === 'Pro';
  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      {isProTier ? (
        <Description>
          Thanks for your interest!
          <br /> We'll let you know first when we release the Pro version.
        </Description>
      ) : (
        <Description>
          Welcome!
          <br />
          If you enter your email, we will contact you as soon as possible.
        </Description>
      )}
      <InputWrapper>
        {formik.errors.email && formik.touched.email ? (
          <Error>{formik.errors.email}</Error>
        ) : null}
        <Input
          autoFocus
          type="input"
          name="email"
          placeholder="Input your email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
      </InputWrapper>
      <Button isProTier={isProTier} type="submit">
        Send
      </Button>
    </StyledForm>
  );
}

EmailForm.propTypes = {
  tier: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EmailForm;
