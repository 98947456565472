// https://gist.github.com/andjosh/6764939
//t = current time
//b = start value
//c = change in value
//d = duration
const easeInOutQuad = function(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

/**
 * 스크롤 이동
 * @param to
 * @param duration
 */
export function scrollTo(to, duration) {
  var start = document.body.scrollTop,
    change = to - start,
    currentTime = 0,
    increment = 20;

  const animateScroll = function() {
    currentTime += increment;
    document.body.scrollTop = easeInOutQuad(
      currentTime,
      start,
      change,
      duration,
    );
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
}
