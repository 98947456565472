import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';

import LayoutContext from './LayoutContext';

class Footer extends React.Component {
  render() {
    if (this.context.layout.hideFooter) {
      return null;
    }

    const { socialItems } = this.props;

    return (
      <footer className="root__footer mt5 py2">
        <div className="root__footer__container mw100 mx-auto">
          <div className="root__footer__social xs-mt2 text-center">
            <p className="root__footer__social__copyright">
              <span>©2020 Additor</span>
              <span> | </span>
              <Link
                className="root__footer__social__copyright__link"
                to="/privacy-policy"
              >
                Privacy
              </Link>
            </p>
            <Menu className="root__footer__social__list flex mx-auto mb2 mt3">
              {socialItems.map(item => (
                <MenuItem
                  key={item.key}
                  className="root__footer__social__listitem"
                >
                  <a
                    href={item.linkTo}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <item.component
                      className={`root__footer__social__logo logo--${item.name}`}
                    />
                  </a>
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  socialItems: PropTypes.array,
};

Footer.defaultProps = {};

Footer.contextType = LayoutContext;

export default Footer;
