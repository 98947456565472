import React from 'react';
import styled from 'styled-components/macro';

import { FilledArrowRight } from 'resources';

import { ReactComponent as AdditorLogo } from '../../additor-symbol.svg';
import { ReactComponent as SvgAsana } from './assets/integration-asana.svg';
import { ReactComponent as SvgDropbox } from './assets/integration-dropbox.svg';
import { ReactComponent as SvgGoogleDrive } from './assets/integration-google-drive.svg';
import { ReactComponent as SvgSlack } from './assets/integration-slack.svg';
import { ReactComponent as SvgJira } from './assets/integration-jira.svg';
import integrationGraphicSrc from './assets/integration-graphic.png';

import { mediaMin } from 'themes/media';
import colors from 'themes/colors';
import { wiggle } from 'themes/keyframes';
import { LogEventFns } from 'analytics/ga';
import SectionTitle from 'views/Common/SectionTitle';
import SectionDescription from 'views/Common/SectionDescription';
import { StyledLink } from './styled';

const IntegrationGoogleDrive = styled(SvgGoogleDrive)`
  position: absolute;
  top: 110px;
  left: 100px;
  animation: ${wiggle(18)} 5s linear infinite both;
`;

const IntegrationSlack = styled(SvgSlack)`
  position: absolute;
  top: 110px;
  right: 54px;
  animation: ${wiggle(18)} 5s linear infinite both;
`;

const IntegrationAsana = styled(SvgAsana)`
  position: absolute;
  bottom: 185px;
  left: 85px;
  animation: ${wiggle(18)} 5s linear infinite both;
`;

const IntegrationJira = styled(SvgJira)`
  position: absolute;
  right: 44px;
  bottom: 185px;
  animation: ${wiggle(18)} 5s linear infinite both;
`;

const IntegrationDropbox = styled(SvgDropbox)`
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  animation: ${wiggle(18)} 5s linear infinite both;
`;

const SectionWrapper = styled.div`
  width: 100%;
  padding: 80px 0 80px;
`;

const ContentsWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  padding: 0 24px 60px;

  ${mediaMin.xs`
    max-width: 1280px;
    padding: 50px 80px 200px;
  `};

  ${IntegrationGoogleDrive}, 
  ${IntegrationSlack}, 
  ${IntegrationAsana}, 
  ${IntegrationJira}, 
  ${IntegrationDropbox} {
    display: none;
    ${mediaMin.sm`
      display: block;
    `}
  }
`;

const MainContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;

  ${mediaMin.xs`
    padding-top: 50px;
  `};
`;

const StyledAdditorLogo = styled(AdditorLogo)`
  display: none;
  color: ${colors.brand500};
  ${mediaMin.sm`
    display: block;   
  `}
`;

const Title = styled(SectionTitle)`
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  br {
    display: block;
  }

  ${mediaMin.xs`
    margin-top: 30px;
    margin-bottom: 10px;
    br {
      display: none;
    }    
  `};
`;

const Description = styled(SectionDescription)`
  text-align: center;
  margin: 0;
  position: relative;
  br {
    display: none;
  }

  ${mediaMin.xs`
    margin: 0;
    br {
      display: block;
    }
  `};
`;

const GoToSlackIntegrationLink = styled(StyledLink)`
  color: ${colors.brand500};
  margin: 30px 0 40px 0;
`;

const MobileIntegrationGraphic = styled.img`
  width: 100%;
  max-width: 300px;
  margin-top: 30px;

  ${mediaMin.xs`
    max-width: 350px;
  `};

  ${mediaMin.sm`
    display: none;   
  `}
`;

function IntegrationSection() {
  return (
    <SectionWrapper>
      <ContentsWrapper>
        <MainContentsWrapper>
          <StyledAdditorLogo />
          <Title>
            Integrate with tools{` `}
            <br />
            you love
          </Title>
          <Description>
            Additor supports your existing workflow.{` `}
            <br />
            Boost your productivity with all, not all-in-one.
          </Description>
          <GoToSlackIntegrationLink
            to="/slack-integration"
            onClick={LogEventFns.main.clickSlackIntegrationButton}
          >
            Go to Slack Integration
            <FilledArrowRight />
          </GoToSlackIntegrationLink>
          <MobileIntegrationGraphic src={integrationGraphicSrc} />
        </MainContentsWrapper>
        <IntegrationGoogleDrive />
        <IntegrationSlack />
        <IntegrationAsana />
        <IntegrationJira />
        <IntegrationDropbox />
      </ContentsWrapper>
    </SectionWrapper>
  );
}

export default IntegrationSection;
