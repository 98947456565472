import styled from 'styled-components/macro';
import { mediaMin } from 'themes/media';
import colors from 'themes/colors';

export const Content = styled.div`
  background-color: ${colors.white};
  padding-top: calc(10vh + 70px);

  ${mediaMin.xs`
    padding-top: calc(10vh + 60px);
  `}
`;

export const SectionWrapper = styled.div`
  font-family: ObjectSans, sans-serif;
  position: relative;
  padding: 100px 16px;

  ${mediaMin.xs`
    padding: 130px 60px;
  `};

  ${mediaMin.sm`
    padding: 130px 80px;
  `};

  ${mediaMin.md`
    padding: 130px 100px;
  `};

  ${mediaMin.lg`
    padding: 130px 0px;
    max-width: 1000px;
    margin: 0 auto;
  `};

  ${mediaMin.xlg`
    max-width: 1200px;
  `}
`;
