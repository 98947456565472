import React from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import { imageURL } from 'config/paths';
import { postGetStarted, putGetStarted } from 'api/getStarted';
import getStartedWithApple from 'api/getStartedWithApple';
import { setCookie } from 'utils/cookie';
import getAppHostName from 'utils/getAppHostName';
import { getGoogleUser } from 'utils/googleAuth';
import { redirectToSlackOAuth } from 'utils/slackAuth';
import { eventLogger } from 'analytics/amplitude';
import cn from 'classnames';

import Input from 'components/Input';
import Form from 'components/Form';
import Button from 'components/Button';
import Card from 'components/Card';
import Header from 'components/Header';
import Hero from 'components/Hero';
import Link from 'components/Link';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import Image from 'components/Image';
import Page from 'components/Page';
import Section from 'components/Section';

import IconFavGoogle from 'resources/img/fav-google.png';
import IconFavSlack from 'resources/img/fav-slack.png';
import { ReactComponent as GTSVG } from 'resources/svg/gt.svg';
import { ReactComponent as ButtonLoadingSVG } from 'resources/svg/button-loading.svg';

const documentImages = [
  `${imageURL}/illustration/get-addit.png`,
  `${imageURL}/illustration/document_to_addit.png`,
  `${imageURL}/illustration/collaboration.png`,
];

class MainPage extends React.Component {
  state = {
    videoNumber: 0,
    documentImage: documentImages[0],
    animationCounter: -1,
    isInvalidEmail: false,
    invalidSignInInfo: false,
    isInvalidEmailTop: false,
    isInvalidEmailBottom: false,
    sendingGetStartedEmail: false,
  };

  constructor(props) {
    super(props);

    this.collectVideo = React.createRef();
    this.organizeVideo = React.createRef();
    this.shareVideo = React.createRef();
    this.inputEmailTop = React.createRef();
    this.inputEmailBottom = React.createRef();
  }

  componentDidMount() {
    eventLogger.onboarding.landedOnLandingPage();
  }

  handleOnClickGoogleAuth = async () => {
    const googleUser = await getGoogleUser();
    this.handleOnGoogleSignin(googleUser);
  };

  handleOnGoogleSignin = async googleUser => {
    const {
      ok,
      verificationToken,
      verifiedUserName,
      ...res
    } = await putGetStarted(googleUser.getAuthResponse().id_token, 'google');
    if (ok) {
      setCookie('verified_user_name', verifiedUserName, 0, 60);
      setCookie('verification', verificationToken, 0, 60);
      window.location.href = `${getAppHostName()}/check-in`;
    } else if (res.status === 409 || res.status === 410) {
      this.setState({ invalidSignInInfo: true });
    }
  };

  handleOnClickSlackAuth = () => {
    redirectToSlackOAuth();
  };

  handleClickAppleAuth = async () => {
    try {
      const data = await window.AppleID.auth.signIn();
      if (data) {
        getStartedWithApple(data.authorization.id_token, data.user);
      }
    } catch (e) {
      // ignore error
    }
  };

  handleChangeInputEmailTop = () => {
    const { isInvalidEmailTop } = this.state;
    if (isInvalidEmailTop) {
      this.setState({ isInvalidEmailTop: false });
    }
  };

  handleChangeInputEmailBottom = () => {
    const { isInvalidEmailBottom } = this.state;
    if (isInvalidEmailBottom) {
      this.setState({ isInvalidEmailBottom: false });
    }
  };

  handleSubmitEmailTop = async e => {
    e.preventDefault();
    this.setState({ sendingGetStartedEmail: true });
    const email = this.inputEmailTop.current.value;
    const isEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9]{1,63})$/i;
    if (isEmail.test(email)) {
      const res = await postGetStarted(email);
      if (res.ok) {
        this.props.history.push(
          `/mail-sent?email=${encodeURIComponent(email)}`,
        );
      }
      this.setState({ sendingGetStartedEmail: false });
    } else {
      this.setState({ isInvalidEmailTop: true, sendingGetStartedEmail: false });
    }
  };

  handleSubmitEmailBottom = async e => {
    e.preventDefault();
    this.setState({ sendingGetStartedEmail: true });
    const email = this.inputEmailBottom.current.value;
    const isEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9]{1,63})$/i;
    if (isEmail.test(email)) {
      const res = await postGetStarted(email);
      if (res.ok) {
        this.props.history.push(
          `/mail-sent?email=${encodeURIComponent(email)}`,
        );
      }
      this.setState({ sendingGetStartedEmail: false });
    } else {
      this.setState({ isInvalidEmailTop: true, sendingGetStartedEmail: false });
    }
  };

  handleEnterImages = () => {
    if (this.state.animationCounter > 0) {
      return;
    }
    const term = 240;
    this.setState({ animationCounter: 0 });
    const intervalId = setInterval(() => {
      this.setState({ animationCounter: this.state.animationCounter + 1 });
      if (this.state.animationCounter === 11) {
        clearInterval(intervalId);
      }
    }, term);
  };

  handleClickVideoButton = videoNumber => () => {
    const videoNames = ['collect', 'organize', 'share'];
    videoNames.forEach(videoName => {
      const videoNode = this[`${videoName}Video`].current;
      videoNode.currentTime = 0;
      // videoNode.setAttribute('autoplay', videoName === index);
    });
    this.setState({ videoNumber });
  };

  render() {
    const {
      videoNumber,
      documentImage,
      animationCounter,
      sendingGetStartedEmail,
    } = this.state;
    return (
      <Page className="page" id="main">
        <Hero className="px3 xs-px2">
          <div className="mw100 mx-auto">
            <div className="hero__content">
              <header className="hero__header lg-col-9 md-col-9 col-12">
                <Header className="hero__header__h1 xs-mb0">
                  Let knowledge flow
                </Header>
                <div className="hero__shape__container">
                  <img
                    alt=""
                    className="hero__shape__container__img"
                    src="https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/illustration/landing-main-hero.png"
                  />
                </div>
                <p className="h5 hero__header__p my1 xs-large">
                  Collect, organize and share content <br className="xs-hide" />{' '}
                  with your internal and external collaborators
                </p>
                <Form
                  className="hero__header__form flex"
                  onSubmit={this.handleSubmitEmailTop}
                  noValidate
                >
                  <Input
                    className="hero__header__input-email mr2 xs-mr1 xs-col-8"
                    type="email"
                    placeholder="Enter your email"
                    ref={this.inputEmailTop}
                    onChange={this.handleChangeInputEmailTop}
                  />
                  <Button
                    className="hero__header__button-signup xs-col-4"
                    type="submit"
                  >
                    {sendingGetStartedEmail ? (
                      <ButtonLoadingSVG />
                    ) : (
                      'Get Started'
                    )}
                  </Button>
                </Form>
                {this.state.isInvalidEmailTop && (
                  <p className="hero__header__email-invalid">
                    The email address is in an invalid format.
                  </p>
                )}
                <p className="hero__header__p">
                  <span className="hero__header__span">
                    Already using Additor?
                  </span>
                  <Link
                    theme="default"
                    className="fs-small hero__header__p__sign-in"
                    to="/sign-in"
                  >
                    Sign in
                  </Link>
                  <button
                    className="hero__header__p__btn-oauth"
                    type="button"
                    onClick={this.handleOnClickGoogleAuth}
                  >
                    <img
                      src={IconFavGoogle}
                      className="icon"
                      alt="Sign in with Google account"
                    />
                  </button>
                  <button
                    className="hero__header__p__btn-oauth"
                    type="button"
                    onClick={this.handleOnClickSlackAuth}
                  >
                    <img
                      src={IconFavSlack}
                      className="icon"
                      alt="Sign in with Slack account"
                    />
                  </button>
                  {/*<button
                    className="hero__header__p__btn-oauth"
                    type="button"
                    onClick={this.handleClickAppleAuth}
                  >
                    <AppleIconSVG width={23} />
                  </button>*/}
                </p>
              </header>
            </div>
          </div>
        </Hero>

        <Section className="s-future px3 xs-px2 xs-pt4">
          <div className="mw100 mx-auto">
            <div className="s-future__header-container sm-center md-center lg-center">
              <Header className="s-future__h3 xs-h4 mt4" as="h3">
                Who’s working in the future?
              </Header>
              <p className="s-future__p color-gray600 h6 mt0 xs-large">
                Trusted by individuals and teams in fast-growing companies
              </p>
            </div>
            <div className="s-future__companies col-12 md-col-10 lg-col-8 mx-auto">
              <Image
                className=""
                width="100%"
                src={`${imageURL}/illustration/companies-wrap.png`}
              />
            </div>
          </div>
        </Section>

        <Section className="s-stuck-in-middle px3 xs-px2 xs-pt4">
          <div className="s-stuck-in-middle__container mw100 mx-auto md-flex lg-flex">
            <div className="s-stuck-in-middle__illustration mr4 xs-width-full">
              <Image src={`${imageURL}/illustration/maze.png`} width="100%" />
            </div>
            <div className="s-stuck-in-middle__content">
              <Header
                className="s-stuck-in-middle__content__h3 xs-h4 xs-mb3 xs-mt0"
                as="h3"
              >
                Always stuck in the middle?
              </Header>
              <p className="s-stuck-in-middle__content__p h4 mt1 mb0 xs-large">
                <strong>6 hours</strong> per week
              </p>
              <p className="s-stuck-in-middle__content__p h4 mt1 xs-large">
                <strong>$ 10,000</strong> per year
              </p>
              <p className="s-stuck-in-middle__content__p h4 my3 xs-large xs-my2">
                per person
              </p>
              <p className="s-stuck-in-middle__content__p h6 xs-large">
                is being wasted by the rigid tools while dealing with
                information
              </p>
            </div>
          </div>
        </Section>

        <Section className="s-fastest-way px3 xs-px2 xs-pt4">
          <div className="mw100 mx-auto">
            <div className="s-fastest-way__header-container sm-center md-center lg-center">
              <Header className="mb0 xs-h4" as="h3">
                The fastest way from ideas to impact
              </Header>
              <p className="h6 color-gray600 my2 xs-large">
                Additor follows your natural workflow. That’s it.
              </p>
            </div>
            <div className="s-fastest-way__demo-tab col-6 lg-col-5 sm-col-9 xs-col-12 my3 mx-auto">
              <Menu className="s-fastest-way__demo-tab__list lg-justify-between fs-large xs-medium fw-bold">
                <MenuItem
                  className={`s-fastest-way__demo-tab__listitem pointer ${
                    videoNumber === 0 ? 'selected' : ''
                  }`}
                  onClick={this.handleClickVideoButton(0)}
                >
                  Collect
                </MenuItem>
                <MenuItem
                  className={`s-fastest-way__demo-tab__listitem pointer ${
                    videoNumber === 1 ? 'selected' : ''
                  }`}
                  onClick={this.handleClickVideoButton(1)}
                >
                  Organize
                </MenuItem>
                <MenuItem
                  className={`s-fastest-way__demo-tab__listitem pointer ${
                    videoNumber === 2 ? 'selected' : ''
                  }`}
                  onClick={() => this.setState({ videoNumber: 2 })}
                >
                  Share
                </MenuItem>
              </Menu>
            </div>
            <div
              className={`s-fastest-way__demo-viewer ${
                videoNumber === 0 ? 'active' : ''
              } col-6 lg-col-12 sm-col-12 xs-col-12`}
            >
              <video
                ref={this.collectVideo}
                src={
                  'https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/video/collect.mp4'
                }
                width="100%"
                height="100%"
                loop
                playsInline
                muted
                autoPlay
              />
            </div>
            <div
              className={`s-fastest-way__demo-viewer ${
                videoNumber === 1 ? 'active' : ''
              } col-6 lg-col-12 sm-col-12 xs-col-12`}
            >
              <video
                ref={this.organizeVideo}
                src={
                  'https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/video/organize.mp4'
                }
                width="100%"
                height="100%"
                loop
                playsInline
                muted
                autoPlay
              />
            </div>
            <div
              className={`s-fastest-way__demo-viewer ${
                videoNumber === 2 ? 'active' : ''
              } col-6 lg-col-12 sm-col-12 xs-col-12`}
            >
              <video
                ref={this.shareVideo}
                src={
                  'https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/video/share.mp4'
                }
                width="100%"
                height="100%"
                loop
                playsInline
                muted
                autoPlay
              />
            </div>
            {/*<div className='s-fastest-way__link-wrap mt3'>
             <Link
             href='/product'
             className='fs-large xs-medium'
             >
             <span>Take the product tour <ArrowRightSVG className='svg svg--right-arrow' /></span>
             </Link>
             </div>*/}
          </div>
        </Section>

        <Section className="s-document px3 xs-px2 xs-pt4">
          <div className="mw100 mx-auto">
            <div className="s-document__header-container sm-center md-center lg-center">
              <Header className="mb0 xs-h4" as="h3">
                Document is not a word-processor or a note
              </Header>
              <p className="h6 color-gray600 my2 xs-large">
                Please take advantages of the most flexible tool
              </p>
            </div>
            <div className="content s-document__content mt3 xs-flex-column">
              <div className="container s-document__content__container lg-col-5 md-col-4 sm-col-4 xs-col-12 xs-flex xs-justify-around">
                <div
                  onMouseEnter={() => {
                    this.setState({ documentImage: documentImages[0] });
                  }}
                  onClick={() => {
                    this.setState({ documentImage: documentImages[0] });
                  }}
                  className="textblock s-document__content__textblock col-12 xs-p1 xs-col-4 xs-center"
                >
                  <Header
                    className="s-document__content__textblock__h5 m0 mt1 sm-large xs-medium xs-m0"
                    as="h5"
                  >
                    Content
                    <span className="xs-hide"> matters</span>
                  </Header>
                  <p className="fs-huge mb0 sm-medium xs-hide">
                    To collaborate beyond the specific format and service
                  </p>
                </div>
                <div
                  onMouseEnter={() => {
                    this.setState({ documentImage: documentImages[1] });
                  }}
                  onClick={() => {
                    this.setState({ documentImage: documentImages[1] });
                  }}
                  className="textblock s-document__content__textblock col-12 xs-p1 xs-col-4 xs-center"
                >
                  <Header
                    className="s-document__content__textblock__h5 m0 mt1 sm-large xs-medium xs-m0"
                    as="h5"
                  >
                    Organizing
                    <span className="xs-hide"> matters</span>
                  </Header>
                  <p className="fs-huge mb0 sm-medium xs-hide">
                    To turn scattered information into organized and sharable
                    content
                  </p>
                </div>
                <div
                  className="textblock s-document__content__textblock col-12 xs-p1 xs-col-4 xs-center"
                  onMouseEnter={() => {
                    this.setState({ documentImage: documentImages[2] });
                  }}
                  onClick={() => {
                    this.setState({ documentImage: documentImages[2] });
                  }}
                >
                  <Header
                    className="s-document__content__textblock__h5 m0 mt1 sm-large xs-medium xs-m0"
                    as="h5"
                  >
                    <span className="xs-hide">Communication matters</span>
                    <span className="sm-hide md-hide lg-hide">Share</span>
                  </Header>
                  <p className="fs-huge mb0 sm-medium xs-hide">
                    To deal with both internal and external collaborators
                    simultaneously
                  </p>
                </div>
              </div>
              <div className="s-document__content__image-slider pl2 xs-center">
                <Image
                  className="s-document__content__image"
                  width="100%"
                  height="100%"
                  src={documentImage}
                />
                {/*<Link
                 to='/'
                 className='fs-large'
                 >
                 <span>See how it works <ArrowRightSVG className='svg svg--right-arrow' /></span>
                 </Link>*/}
              </div>
            </div>
          </div>
        </Section>

        <Section className="s-promising-team px3 xs-px2 xs-pt4">
          <div className="col-12 mw100 mx-auto">
            <div className="s-promising-team__header-container sm-center md-center lg-center">
              <Header className="mb0 xs-h4" as="h3">
                Built for your promising team
              </Header>
              <p className="h6 color-gray600 my2 xs-large">
                Reimagine your daily works and life
              </p>
            </div>
            <div className="s-promising-team__container col-12 mx-auto mt4 xs-mt3 px1 lg-justify-center md-justify-center">
              <Waypoint onEnter={this.handleEnterImages} />

              <div className="s-promising-team__container__wrap-card lg-col-2 md-col-2 sm-col-4 xs-col-6 p1">
                <Card
                  // shadowEffect='hover'
                  className={cn('s-promising-team__container__card pointer', {
                    'card--mk--start':
                      animationCounter > -1 && animationCounter < 5,
                    'card--mk--end': animationCounter > 4,
                  })}
                />
                <p>Marketing</p>
              </div>
              <div className="s-promising-team__container__wrap-card lg-col-2 md-col-2 sm-col-4 xs-col-6 p1">
                <Card
                  // shadowEffect='hover'
                  className={cn('s-promising-team__container__card pointer', {
                    'card--pr--start':
                      animationCounter > 0 && animationCounter < 6,
                    'card--pr--end': animationCounter > 5,
                  })}
                />
                <p>Product and UX</p>
              </div>
              <div className="s-promising-team__container__wrap-card lg-col-2 md-col-2 sm-col-4 xs-col-6 p1">
                <Card
                  // shadowEffect='hover'
                  className={cn('s-promising-team__container__card pointer', {
                    'card--pm--start':
                      animationCounter > 1 && animationCounter < 7,
                    'card--pm--end': animationCounter > 6,
                  })}
                />
                <p>Project Management</p>
              </div>
              <div className="s-promising-team__container__wrap-card lg-col-2 md-col-2 sm-col-4 xs-col-6 p1">
                <Card
                  // shadowEffect='hover'
                  className={cn('s-promising-team__container__card pointer', {
                    'card--fr--start':
                      animationCounter > 2 && animationCounter < 8,
                    'card--fr--end': animationCounter > 7,
                  })}
                />
                <p>Freelancers and agencies</p>
              </div>
              <div className="s-promising-team__container__wrap-card lg-col-2 md-col-2 sm-col-4 xs-col-6 p1">
                <Card
                  // shadowEffect='hover'
                  className={cn('s-promising-team__container__card pointer', {
                    'card--kb--start':
                      animationCounter > 3 && animationCounter < 9,
                    'card--kb--end': animationCounter > 8,
                  })}
                />
                <p>Knowledge Base</p>
              </div>
              <div className="s-promising-team__container__wrap-card sm-col-4 xs-col-6 p1 lg-hide md-hide">
                <Card
                  // shadowEffect='hover'
                  className={cn('s-promising-team__container__card pointer', {
                    'card--hr--start':
                      animationCounter > 4 && animationCounter < 10,
                    'card--hr--end': animationCounter > 9,
                  })}
                />
                <p>Human Resource</p>
              </div>
            </div>
            <div className="text-center mt3">
              {/*<Link
               href='/use-cases'
               className='fs-large'
               >
               <span>Explore use cases <ArrowRightSVG className='svg svg--right-arrow' /></span>
               </Link>*/}
            </div>
          </div>
        </Section>

        <Section className="s-workflows px3 xs-px2 xs-pt4">
          <div className="mw100 mx-auto">
            <div className="s-workflows__header-container sm-center md-center lg-center">
              <Header className="mb0 xs-h4" as="h3">
                More productive with existing workflows
              </Header>
              <p className="h6 color-gray600 my2">
                Integrate with your favorite tools
              </p>
            </div>
            <div className="s-workflows__illustration mx-auto pt2 xs-col-12 sm-col-10 md-col-9 lg-col-8">
              <Image
                width="100%"
                src="https://alphamon-asset.s3.ap-northeast-2.amazonaws.com/img/landing/integrations.png"
              />
              <Link to="/slack-integration">
                <strong>
                  Learn more about Slack Integration
                  <GTSVG />
                </strong>
              </Link>
            </div>
          </div>
        </Section>

        <Section className="s-potential px3 xs-px2 pt4 xs-pt4">
          <div className="mw100 mx-auto">
            <div className="s-potential__header-container sm-center md-center lg-center">
              <Header className="mb4 xs-h4 xs-my3" as="h3">
                Unleash your potential from now
              </Header>
              <Form
                className="s-potential__form flex xs-col-12"
                onSubmit={this.handleSubmitEmailBottom}
                noValidate
              >
                <Input
                  className="s-potential__input-email mr2 mb1 xs-mr1 xs-col-8"
                  type="email"
                  placeholder="Enter your email"
                  ref={this.inputEmailBottom}
                  onChange={this.handleChangeInputEmailBottom}
                />
                <Button className="s-potential__button-signup xs-col-4">
                  {sendingGetStartedEmail ? (
                    <ButtonLoadingSVG />
                  ) : (
                    <span>
                      Sign up
                      <span className="xs-hide"> for free</span>
                    </span>
                  )}
                </Button>
              </Form>
              {this.state.isInvalidEmailBottom && (
                <p className="hero__header__email-invalid hero__header__email-invalid--bottom">
                  The email address is in an invalid format.
                </p>
              )}
              <p className="fs-small mt2">
                <b>Free</b> to use for individuals and teams of any size for now
              </p>
            </div>
          </div>
        </Section>

        <div className="image-preloader">
          <div className="mk" />
          <div className="pr" />
          <div className="pm" />
          <div className="fr" />
          <div className="kb" />
          <div className="hr" />
        </div>
      </Page>
    );
  }
}

MainPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default MainPage;
