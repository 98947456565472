import './styles/index.scss';
import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router as BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import GA from 'analytics/ga';

import Root from './views/Root';

const history = createBrowserHistory();
history.listen(GA.pageView);

ReactDOM.render(
  <BrowserRouter history={history}>
    <Root />
  </BrowserRouter>,
  document.getElementById('additor-root'),
);
