import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import { animated, useSpring } from 'react-spring';

import { ReactComponent as CursorAlex } from '../assets/alex-cursor.svg';
import { ReactComponent as CursorEric } from '../assets/eric-cursor.svg';
import { ReactComponent as CursorFloyd } from '../assets/floyd-cursor.svg';
import { ReactComponent as CursorOlivia } from '../assets/olivia-cursor.svg';

const cursorPosition = {
  olivia: {
    from: { top: 350, left: '18%', opacity: 1 },
    to: { top: 260, left: '0%', opacity: 0 },
  },
  alex: {
    from: { right: '18%', top: 450, opacity: 1 },
    to: { right: '0%', top: 370, opacity: 0 },
  },
  eric: {
    from: { right: 110, bottom: 60, opacity: 1 },
    to: { right: -80, bottom: 0, opacity: 0 },
  },
  floyd: {
    from: { left: 90, bottom: 100, opacity: 1 },
    to: { left: -50, bottom: 26, opacity: 0 },
  },
};

const AnimatedDiv = styled(animated.div)`
  position: fixed;
  z-index: 3;
  transform: scale(0.9);
  pointer-events: none;
`;

const CursorWrapper = styled.div`
  display: ${({ isCursorVisible }) => (isCursorVisible ? 'block' : 'none')};
`;

function AnimatedCursors() {
  const [isCursorVisible, setIsCursorVisible] = useState(
    window.innerWidth >= 1200,
  );

  const [olivia, setOlivia] = useSpring(() => ({
    ...cursorPosition.olivia.from,
  }));

  const [alex, setAlex] = useSpring(() => ({
    ...cursorPosition.alex.from,
  }));

  const [eric, setEric] = useSpring(() => ({
    ...cursorPosition.eric.from,
  }));

  const [floyd, setFloyd] = useSpring(() => ({
    ...cursorPosition.floyd.from,
  }));

  const handleScroll = _.throttle(() => {
    if (document.body.scrollTop > 100) {
      setOlivia(cursorPosition.olivia.to);
      setAlex(cursorPosition.alex.to);
      setEric(cursorPosition.eric.to);
      setFloyd(cursorPosition.floyd.to);
    } else if (document.body.scrollTop < 30) {
      setOlivia(cursorPosition.olivia.from);
      setAlex(cursorPosition.alex.from);
      setEric(cursorPosition.eric.from);
      setFloyd(cursorPosition.floyd.from);
    }
  }, 100);

  useEffect(() => {
    document.body.addEventListener('scroll', handleScroll);
    return () => {
      document.body.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!ResizeObserver) return () => {};

    const resizeObserver = new ResizeObserver(([{ contentRect }]) => {
      if (contentRect.width >= 1200) {
        setIsCursorVisible(true);
      } else {
        setIsCursorVisible(false);
      }
    });
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);
    };
  }, []);

  return (
    <>
      <AnimatedDiv style={olivia}>
        <CursorWrapper isCursorVisible={isCursorVisible}>
          <CursorOlivia />
        </CursorWrapper>
      </AnimatedDiv>
      <AnimatedDiv style={alex}>
        <CursorWrapper isCursorVisible={isCursorVisible}>
          <CursorAlex />
        </CursorWrapper>
      </AnimatedDiv>
      <AnimatedDiv style={eric}>
        <CursorWrapper isCursorVisible={isCursorVisible}>
          <CursorEric />
        </CursorWrapper>
      </AnimatedDiv>
      <AnimatedDiv style={floyd}>
        <CursorWrapper isCursorVisible={isCursorVisible}>
          <CursorFloyd />
        </CursorWrapper>
      </AnimatedDiv>
    </>
  );
}

export default AnimatedCursors;
