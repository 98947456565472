export function setCookie(cName, cValue, cDay, cMin = 0) {
  const expire = new Date();
  expire.setDate(expire.getDate() + cDay);
  expire.setTime(expire.getTime() + 1000 * 60 * cMin);
  // 한글 깨짐을 막기위해 escape(cValue)를 한다.
  let cookies = `${cName}=${escape(
    cValue,
  )}; path=/;domain=${window.location.host.replace('app.', '')}`;
  if (window.location.host.includes('localhost')) {
    cookies = `${cName}=${escape(cValue)}`; // 한글 깨짐을 막기위해 escape(cValue)를 한다.
  }
  if (typeof cDay !== 'undefined')
    cookies += `;expires=${expire.toGMTString()};`;
  document.cookie = cookies;
}

export function getCookie(cName) {
  if (typeof document === 'undefined') return null;
  cName += '=';
  const cookieData = document.cookie;
  let start = cookieData.indexOf(cName);
  let cValue = '';
  if (start !== -1) {
    start += cName.length;
    let end = cookieData.indexOf(';', start);
    if (end === -1) end = cookieData.length;
    cValue = cookieData.substring(start, end);
  }
  return unescape(cValue);
}

export const COOKIE_NAMES = {
  EXCHANGE_TOKEN: 'exchange',
  ACCESS_TOKEN: 'access',
  GUEST_TOKEN: 'gt',
  WORKSPACE_ID: 'wid',
  WORKSPACE_EIDS: 'additor_weids',
  LATEST_WORKSPACE: 'latest_ws',
  SIGN_UP_BY_EXTENSION: 'sign_up_by_extension',
  CRX_ID: 'crx-id',
};

export const COOKIE_EXPIRED = {
  EXCHANGE_TOKEN: {
    cDay: 30,
  },
  WORKSPACE_EIDS: {
    cDay: 30,
  },
  LATEST_WORKSPACE: {
    cDay: 30,
  },
};
