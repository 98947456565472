let BrowserDetector = {};

if (typeof window !== 'undefined') {
  BrowserDetector = {
    isChrome: navigator.userAgent.indexOf('Chrome') > -1,
    isExplorer:
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      navigator.appVersion.indexOf('Trident/') > 0,
    isFirefox: navigator.userAgent.indexOf('Firefox') > -1,
    isSafari: navigator.userAgent.indexOf('Safari') > -1,
    isOpera: navigator.userAgent.toLowerCase().indexOf('op') > -1,
  };

  if (BrowserDetector.isChrome && BrowserDetector.isSafari) {
    BrowserDetector.isSafari = false;
  }

  if (BrowserDetector.isChrome && BrowserDetector.isOpera) {
    BrowserDetector.isChrome = false;
  }
}

export default BrowserDetector;
