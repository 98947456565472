import React from 'react';

import { appServerURL } from 'config/paths';
import Form from 'components/Form';
import Input from 'components/Input';
import Image from 'components/Image';
import Link from 'components/Link';
import Button from 'components/Button';
import Header from 'components/Header';
import Hero from 'components/Hero';
import Page from 'components/Page';
import Section from 'components/Section';

import tempDevicesImage from 'resources/img/temp_devices.png';

export default class ProductPage extends React.Component {
  handleSubmitEmail = e => {
    e.preventDefault();
    const emailInput = e.target.querySelector('input[type=email]');
    if (emailInput && emailInput.value) {
      window.location.href = `${appServerURL}/sign_up?email=${emailInput.value}`;
    }
  };

  render() {
    return (
      <Page id="product">
        <Hero className="hero--product px3 xs-px2">
          <div className="mw100 mx-auto text-center">
            <Header as="h2" className="hero--product__h2 xs-h25 xs-mt4 mb0 mt5">
              Docs minus the bad stuff
            </Header>
            <p className="h5 hero--product__p mb4 xs-large">
              Collaborate beyond formats and tools
            </p>
            <Form
              className="hero--product__form flex"
              onSubmit={this.handleSubmitEmail}
            >
              <Input
                className="hero--product__input-email mr2 mb1 xs-mr1 xs-col-8"
                type="email"
                placeholder="Enter your email"
              />
              <Button className="hero--product__button-signup">
                Get Started
              </Button>
            </Form>
          </div>
        </Hero>
        <Section className="s-devices px3 xs-px2 xs-pt4">
          <div className="col-12 md-col-7 lg-col-6 mx-auto">
            <Image width="100%" src={tempDevicesImage} />
          </div>
        </Section>
        <Section className="s-function px3 xs-px2 xs-pt4">
          <div className="s-function__container mw100 mx-auto">
            <div className="container s-function__container__header">
              <Header as="h3" className="s-function__container__header__h3 mb3">
                Collect
              </Header>
              <Button className="s-function__container__header__button" minimal>
                Highlight
              </Button>
              <Button className="s-function__container__header__button" minimal>
                Edit card
              </Button>
              <Link href={`${appServerURL}/sign_up`}>
                <Button className="s-function__container__header__button" round>
                  Start your project
                </Button>
              </Link>
            </div>
            <div className="s-function__container__object px2">
              <div className="s-function__container__object__content">
                <i>Some contents...</i>
              </div>
            </div>
          </div>
        </Section>
        <Section className="s-function px3 xs-px2 xs-pt4">
          <div className="s-function__container mw100 mx-auto">
            <div className="container s-function__container__header">
              <Header as="h3" className="s-function__container__header__h3">
                Organize
              </Header>
              <Button className="s-function__container__header__button" minimal>
                Drag & Drop
              </Button>
              <Button className="s-function__container__header__button" minimal>
                Import
              </Button>
              <Button className="s-function__container__header__button" minimal>
                Embed
              </Button>
              <Link href={`${appServerURL}/sign_up`}>
                <Button className="s-function__container__header__button" round>
                  Start your project
                </Button>
              </Link>
            </div>
            <div className="s-function__container__object px2">
              <div className="s-function__container__object__content">
                <i>Some contents...</i>
              </div>
            </div>
          </div>
        </Section>
        <Section className="s-function px3 xs-px2 xs-pt4">
          <div className="s-function__container mw100 mx-auto">
            <div className="container s-function__container__header">
              <Header as="h3" className="s-function__container__header__h3">
                Share
              </Header>
              <Button className="s-function__container__header__button" minimal>
                comment
              </Button>
              <Button className="s-function__container__header__button" minimal>
                Export
              </Button>
              <Link href={`${appServerURL}/sign_up`}>
                <Button className="s-function__container__header__button" round>
                  Start your project
                </Button>
              </Link>
            </div>
            <div className="s-function__container__object px2">
              <div className="s-function__container__object__content">
                <i>Some contents...</i>
              </div>
            </div>
          </div>
        </Section>
        <Section className="s-function px3 xs-px2 xs-pt4">
          <div className="s-function__container mw100 mx-auto">
            <div className="container s-function__container__header">
              <Header as="h3" className="s-function__container__header__h3">
                Integrate
              </Header>
              <Button className="s-function__container__header__button" minimal>
                Drag & Drop
              </Button>
              <Link href={`${appServerURL}/sign_up`}>
                <Button className="s-function__container__header__button" round>
                  Start your project
                </Button>
              </Link>
            </div>
            <div className="s-function__container__object px2">
              <div className="s-function__container__object__content">
                <i>Some contents...</i>
              </div>
            </div>
          </div>
        </Section>
      </Page>
    );
  }
}
