import React from 'react';
import styled from 'styled-components/macro';
import { mediaMax } from 'themes/media';
import SectionTitle from 'views/Common/SectionTitle';
import SectionDescription from 'views/Common/SectionDescription';

import { ReactComponent as SvgCompanies } from './assets/companies.svg';
import { ReactComponent as SvgCompaniesMobile } from './assets/companies-mobile.svg';

const Wrapper = styled.div`
  padding: 50px 70px 100px;
  overflow: hidden;

  ${mediaMax.xs`
    padding: 50px 20px 100px;
  `};
`;

const Title = styled(SectionTitle)`
  height: 42px;
  margin: 0 auto 16px;
  font-weight: 900;
  text-align: center;
`;

const Message = styled(SectionDescription)`
  line-height: 1.53;
  margin-bottom: 50px;
  text-align: center;
`;

const CompaniesLogo = styled(SvgCompanies)`
  display: block;
  max-width: 940px;
  width: 100%;
  margin: 0 auto;
  ${mediaMax.sm`
    display: none;
  `};
`;

const CompaniesLogoMobile = styled(SvgCompaniesMobile)`
  display: none;
  margin: 0 auto;
  ${mediaMax.sm`
    display: block;
    transform: scale(1.5);
  `};
  ${mediaMax.xs`
    transform: scale(1);
  `};
`;

function CompaniesSection() {
  return (
    <Wrapper>
      <Title>Trusted by</Title>
      <Message>Trusted and loved by productive individuals and teams</Message>
      <CompaniesLogo />
      <CompaniesLogoMobile />
    </Wrapper>
  );
}

export default CompaniesSection;
