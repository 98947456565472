import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import cn from 'classnames';

const Link = ({
  theme = 'default',
  to,
  href = '#',
  newTab = false,
  onClick = () => {},
  className = '',
  children = null,
}) => {
  const classname = cn('link', className, {
    'link--gray': theme === 'gray',
  });

  if (to) {
    return (
      <RouterLink to={to} className={classname} onClick={onClick}>
        {children}
      </RouterLink>
    );
  }

  return (
    <a
      className={className}
      href={href}
      target={newTab ? '_blank' : '_self'}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  theme: PropTypes.oneOf(['gray', 'default']),
  href: PropTypes.string,
  to: PropTypes.any.isRequired,
  newTab: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Link;
