import _ from 'lodash';
import { useEffect, useState } from 'react';

function useVideoSlideShow({ tabs, tabMenuRef, videoRef }) {
  const [selectedVideoId, setSelectedVideoId] = useState('');

  const onClickTab = id => {
    setSelectedVideoId(id);
    _.map(videoRef.current, (element, key) => {
      if (key === id) {
        element.play();
      } else {
        element.pause();
        element.currentTime = 0;
      }
    });
  };

  useEffect(() => {
    function handleEnded(event) {
      const targetVideoId = event.target.dataset.id;
      const nextIndex =
        (_.findIndex(tabs, { id: targetVideoId }) + 1) % tabs.length;
      onClickTab(tabs[nextIndex].id);
    }
    _.forEach(videoRef.current, element => {
      element.addEventListener('ended', handleEnded);
    });
    return () => {
      _.forEach(videoRef.current, element => {
        element.removeEventListener('ended', handleEnded);
      });
    };
  }, [videoRef.current]);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const { isIntersecting } = entries[0];
      if (isIntersecting) {
        observer.disconnect();
        onClickTab(tabs[0].id);
      }
    });
    observer.observe(tabMenuRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return {
    selectedVideoId,
    onClickTab,
  };
}

export default useVideoSlideShow;
