import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import _ from 'lodash';

import { mediaMax } from 'themes/media';
import colors from 'themes/colors';

import { SlackLogoFlat, SlackIconSVG } from 'resources';
import { redirectToSlackOAuth } from 'utils/slackAuth';

const Wrapper = styled.div`
  display: flex;
  height: 28px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 3px;
  padding: 2px 6px;
  transition: all 0.15s ease;

  svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: ${({ flat }) => (flat ? colors.white15 : colors.black5)};
  }

  ${mediaMax.xs`
    font-size: 12px;

    svg {
      margin-right: 6px;
    }
  `}

  span {
    margin-top: 1px;
  }
`;

function SlackSigninButton({ onClick = _.noop, flat }) {
  const handleOnClickSlackAuth = useCallback(() => {
    redirectToSlackOAuth();
    onClick();
  }, []);

  return (
    <Wrapper
      className="slack-signin-button"
      onClick={handleOnClickSlackAuth}
      flat={flat}
    >
      {flat ? (
        <SlackLogoFlat width={20} color={colors.white} />
      ) : (
        <SlackIconSVG width={20} />
      )}
      <span>Slack</span>
    </Wrapper>
  );
}

SlackSigninButton.propTypes = {
  onClick: PropTypes.func,
  flat: PropTypes.bool.isRequired,
};

export default SlackSigninButton;
