import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';

import { mediaMax, mediaMin } from 'themes/media';
import colors from 'themes/colors';
import SectionTitle from 'views/Common/SectionTitle';
import SectionDescription from 'views/Common/SectionDescription';
import chapters from '../chapters';

const Wrapper = styled.div`
  padding: 0 60px;

  ${mediaMax.sm`
    padding: 0 40px;
  `}

  ${mediaMax.xs`
    padding: 0 18px;
  `}
`;

const Section = styled.section`
  margin: 0 0 140px;

  ${mediaMax.xs`
    margin: 0 0 120px;
  `}
`;

const Video = styled.video`
  display: block;
  margin: 0 auto;
  border-radius: 12px;

  ${mediaMin.sm`
    max-width: 700px; 
  `};

  ${mediaMin.sm`
    max-width: 840px; 
  `};
`;

const Title = styled(SectionTitle)`
  margin: 0 0 12px;
  text-align: center;
`;

const Description = styled(SectionDescription)`
  margin: 0 0 40px;
  text-align: center;

  strong {
    color: ${colors.gray900};
  }
`;

function Flat() {
  return (
    <Wrapper>
      {_.map(chapters, ({ id, title, desc, video }) => (
        <Section key={id}>
          <Title>{title}</Title>
          <Description>{desc}</Description>
          <Video width="100%" muted autoPlay playsInline loop src={video} />
        </Section>
      ))}
    </Wrapper>
  );
}

export default Flat;
