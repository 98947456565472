import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components/macro';

import colors from 'themes/colors';

const Modal = styled.div`
  min-width: 400px;
  max-width: 450px;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 4px 34px -4px rgba(0, 0, 0, 0.1);
  background-color: ${colors.white};

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    margin: 0 0 16px 0;
    padding: 0;
    font-size: 24px;
    font-weight: bold;
    color: ${colors.gray900};
    letter-spacing: -0.4px;
  }

  video {
    margin-bottom: 16px;
    border-radius: 3px;
    border: 1px solid ${colors.black15};
  }

  button {
    display: block;
    width: 72px;
    height: 30px;
    margin-left: auto;
    background-color: ${colors.brand500};
    color: ${colors.white};
    border: none;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      background-color: ${colors.brand700};
    }
    &:active {
      background-color: ${colors.brand800};
    }
  }
`;

function Alert({ title = '', contents, onClickButton = _.noop }) {
  return (
    <Modal>
      {title && <h1>{title}</h1>}
      {contents && contents}
      <button autoFocus onClick={onClickButton}>
        OK
      </button>
    </Modal>
  );
}

Alert.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  contents: PropTypes.node,
  onClickButton: PropTypes.func,
};

export default Alert;
