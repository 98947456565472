import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="fs-modal modal-dialog fs-privacy fs-tab">
      <div className="main-wrapper">
        <div className="main-title-msg">
          <span>Privacy Policy</span>
        </div>
        <div className="main-body mw100 mx-auto">
          <div className="center-container">
            <div className="content">
              <p>
                We take the privacy of our users very seriously. We try to keep
                all your data safe and secure and we never sell your data,
                neither your email address, nor any information on how you use
                Additor. The Additor Chrome extension saves whatever you save
                with Additor to your own account.
              </p>
              <p>
                Nevertheless, we do collect certain information – as described
                below – and when you start using Additor, you agree to our
                collection, transfer, processing, storage, disclosure and other
                uses of your information as described in this Privacy Policy.
              </p>
              <br />
              <h3>1. Information we collect</h3>
              <p>
                When signing up for the service on the Additor website, we ask
                you to provide your email address. We may collect information
                about how and when you use Additor. This information include
                your IP address, time, date, browser used, and the actions taken
                by you within the application. Like most websites, we use
                cookies to enhance your experience, gather general visitor
                information, and track visits to our website.
              </p>
              <br />
              <h3>2. Use of information collected</h3>
              <p>
                Any of the information we collect from you may be used in one of
                the following ways:
                <br />
                - To improve our website (we continually strive to improve our
                website offerings based on the information and feedback we
                receive from you)
                <br />- To improve customer service (your information helps us
                to more effectively respond to your customer service requests
                and support needs)
              </p>
              <br />
              <h3>3. GDPR</h3>
              <p>
                The EU General Data Protection Regulations (GDPR) take effect
                from May 25, and we're happy to comply with this. If data
                collection is not integral to the way Additor works, then we
                won’t collect it.
              </p>
              <p>
                Additor uses administrative and electronic measures designed to
                appropriately protect your Personal Information against
                accidental or unlawful destruction, accidental loss,
                unauthorized alteration, unauthorized disclosure or access,
                misuse, and any other unlawful form of processing of the
                Personal Information in our possession. Please be aware that no
                security measures are perfect or impenetrable. We cannot
                guarantee that information about you will not be accessed,
                viewed, disclosed, altered, or destroyed by breach of any of our
                administrative, physical, and electronic safeguards, subject to
                requirements under applicable law to ensure or warrant
                information security.
              </p>
              <p>
                We will make any legally-required disclosures of any breach of
                the security, confidentiality, or integrity of your unencrypted
                electronically stored Personal Information to you via email or
                conspicuous posting on our Site in the most expedient time
                possible.
              </p>
              <p>
                At any time, you may request your information to be exported and
                sent to you for review, and we promptly honor any requests by
                you to have your information deleted and forgotten.
              </p>
              <br />
              <h3>4. Security</h3>
              <p>
                We implement a variety of security measures to maintain the
                safety of your personal information when you submit a request.
              </p>
              <p>
                Nevertheless, nobody is 100% safe from hackers. In the case of
                any security breach in our databases we will notify you as soon
                as possible and will also inform you about the actions taken by
                us in response.
              </p>
              <br />
              <h3>5. Changes in our privacy policy</h3>
              <p>
                If we decide to change our privacy policy, we will post those
                changes on this page. This policy was last modified on May 25th,
                2018.
              </p>
              <br />
              <h3>6. Feedback</h3>
              <p>
                We welcome and appreciate all feedback, comments and suggestions
                in relation to the service. Please share your feedback with us
                at <a href="mailto:info@additor.io">info@additor.io</a>. If you
                provide us any feedback, you agree that we may use, copy,
                modify, create derivative works of and otherwise exploit the
                feedback for any purpose.
              </p>
              <br />
              <h3>7. Contacting us</h3>
              <p>
                If you have any questions about this Privacy Policy, please
                contact us at{' '}
                <a href="mailto:info@additor.io">info@additor.io</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
