import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { layout } from 'styled-system';

import SectionTitle from 'views/Common/SectionTitle';
import SectionDescription from 'views/Common/SectionDescription';
import { mediaMin } from 'themes/media';
import { LogEventFns } from 'analytics/ga';
import useVideoSlideShow from 'views/Contents/customHooks/useVideoSlideShow';

import { SectionWrapper } from '../styled';
import tabs from './tabs';
import Tab from './Tab';

const Title = styled(SectionTitle)`
  text-align: center;
  margin-bottom: 12px;
`;

const Description = styled(SectionDescription)`
  text-align: center;
  margin-bottom: 30px;

  ${mediaMin.xs`
    margin-bottom: 30px;
  `};
`;

const Br = styled.br`
  display: none;

  ${mediaMin.xs`
    display: inline;
  `};
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

const TabMenu = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 100%;

  margin: 0 auto 10px;

  ${mediaMin.xs`
    margin: 0 auto 20px;
  `};

  ${mediaMin.sm`
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    margin: 0 auto 72px;
  `};
`;

const TabVideoWrapper = styled.div`
  width: 100%;
`;

const TabVideo = styled.video`
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  ${layout};
`;

function NoteFeaturesSection() {
  const tabMenuRef = useRef();
  const videoRef = useRef({});
  const { selectedVideoId, onClickTab } = useVideoSlideShow({
    tabMenuRef,
    tabs,
    videoRef,
  });

  const handleClickTab = id => {
    switch (id) {
      case 'list':
        LogEventFns.product.clickNoteFeatureListButton();
        break;
      case 'simultaneous-editing':
        LogEventFns.product.clickNoteFeatureSimultaneousEditingButton();
        break;
      case 'image-grid':
        LogEventFns.product.clickNoteFeatureImageGridButton();
        break;
      case 'embed':
        LogEventFns.product.clickNoteFeatureEmbedButton();
        break;
      case 'comment':
        LogEventFns.product.clickNoteFeatureCommentButton();
        break;
      case 'page-reference':
        LogEventFns.product.clickNoteFeaturePageReferenceButton();
        break;
    }
    onClickTab(id);
  };

  return (
    <SectionWrapper id="note-editor">
      <Title>Minimal but powerful note features</Title>
      <Description>
        You don't need hundreds of unnecessary functions.&nbsp;
        <Br />
        Our editor focuses on fundamentals.
      </Description>
      <ContentWrapper>
        <TabMenu ref={tabMenuRef}>
          {tabs.map(({ id, name, icon }) => (
            <Tab
              id={id}
              key={id}
              name={name}
              icon={icon}
              isSelected={id === selectedVideoId}
              onClick={handleClickTab}
            />
          ))}
        </TabMenu>
        <TabVideoWrapper>
          {tabs.map(({ id, src }) => (
            <TabVideo
              key={id}
              muted
              playsInline
              data-id={id}
              src={src}
              display={id === selectedVideoId ? 'block' : 'none'}
              ref={element => (videoRef.current[id] = element)}
            />
          ))}
        </TabVideoWrapper>
      </ContentWrapper>
    </SectionWrapper>
  );
}

export default NoteFeaturesSection;
