import { cdnVideoBaseURL } from 'config/paths';

const tabs = [
  {
    id: 'by-channels',
    name: {
      short: 'Channels',
      long: 'By channels',
    },
    src: `${cdnVideoBaseURL}/landing/organize-by-channel.mp4`,
  },
  {
    id: 'by-tags',
    name: {
      short: 'Tags',
      long: 'By tags',
    },
    src: `${cdnVideoBaseURL}/landing/organize-by-tags.mp4`,
  },
  {
    id: 'with-dnd',
    name: {
      short: 'Drag & drop',
      long: 'With drag & drop',
    },
    src: `${cdnVideoBaseURL}/landing/organize-with-dnd.mp4`,
  },
];

export default tabs;
