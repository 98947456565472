import React from 'react';

import { cdnVideoBaseURL } from 'config/paths';

import { ReactComponent as ListIcon } from './assets/list.svg';
import { ReactComponent as SimultaneousEditingIcon } from './assets/simultaneous-editing.svg';
import { ReactComponent as ImageGridIcon } from './assets/image-grid.svg';
import { ReactComponent as EmbedIcon } from './assets/embed.svg';
import { ReactComponent as CommentIcon } from './assets/comment.svg';
import { ReactComponent as PageReferenceIcon } from './assets/page-reference.svg';

const tabs = [
  {
    id: 'list',
    icon: <ListIcon />,
    name: 'List',
    src: `${cdnVideoBaseURL}/landing/note-features-list.mp4`,
  },
  {
    id: 'simultaneous-editing',
    icon: <SimultaneousEditingIcon />,
    name: 'Simultaneous editing',
    src: `${cdnVideoBaseURL}/landing/note-features-simultaneous-editing.mp4`,
  },
  {
    id: 'image-grid',
    icon: <ImageGridIcon />,
    name: 'Image Grid',
    src: `${cdnVideoBaseURL}/landing/note-features-image-grid.mp4`,
  },
  {
    id: 'embed',
    icon: <EmbedIcon />,
    name: 'Embed',
    src: `${cdnVideoBaseURL}/landing/note-features-embed.mp4`,
  },
  {
    id: 'comment',
    icon: <CommentIcon />,
    name: 'Comment',
    src: `${cdnVideoBaseURL}/landing/note-features-comment.mp4`,
  },
  {
    id: 'page-reference',
    icon: <PageReferenceIcon />,
    name: 'Page Reference',
    src: `${cdnVideoBaseURL}/landing/note-features-page-ref.mp4`,
  },
];

export default tabs;
