import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import _ from 'lodash';

import colors from 'themes/colors';
import { mediaMax } from 'themes/media';

import { putGetStarted } from 'api/getStarted';
import { getGoogleUser } from 'utils/googleAuth';
import { setCookie } from 'utils/cookie';
import getAppHostName from 'utils/getAppHostName';
import { GoogleLogo, GoogleLogoWhite } from 'resources';

const Wrapper = styled.div`
  display: flex;
  height: 28px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 3px;
  padding: 2px 6px;
  transition: all 0.15s ease;

  svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: ${({ flat }) => (flat ? colors.white15 : colors.black5)};
  }

  ${mediaMax.xs`
    font-size: 12px;

    svg {
      margin-right: 6px;
    }
  `}

  span {
    margin-top: 1px;
  }
`;

function GoogleSigninButton({ flat = false, onClick = _.noop }) {
  const handleOnGoogleSignin = async googleUser => {
    const { ok, verificationToken, verifiedUserName } = await putGetStarted(
      googleUser.getAuthResponse().id_token,
      'google',
    );
    if (ok) {
      setCookie('verification', verificationToken, 0, 60);
      setCookie('verified_user_name', verifiedUserName, 0, 60);
      window.location.href = `${getAppHostName()}/check-in`;
    }
  };

  const handleOnClickGoogleAuth = useCallback(() => {
    getGoogleUser().then(googleUser => {
      handleOnGoogleSignin(googleUser);
    });
    onClick();
  }, []);

  return (
    <Wrapper
      className="google-signin-button"
      onClick={handleOnClickGoogleAuth}
      flat={flat}
    >
      {flat ? <GoogleLogoWhite width={20} /> : <GoogleLogo width={20} />}
      <span>Google</span>
    </Wrapper>
  );
}

GoogleSigninButton.propTypes = {
  flat: PropTypes.bool,
  onClick: PropTypes.func,
};

export default GoogleSigninButton;
