import styled from 'styled-components/macro';
import { color } from 'styled-system';
import colors from 'themes/colors';

export const THEME = {
  LIGHT: {
    name: 'LIGHT',
    color: colors.white,
    borderColor: colors.white15,
    hoverBackgroundColor: colors.white10,
  },
  BRAND: {
    name: 'BRAND',
    color: colors.white,
    borderColor: colors.transparent,
    hoverBackgroundColor: colors.white10,
  },
};

export const Link = styled.a`
  display: inline-block;
  position: relative;
  height: 62px;
  transition: color 0.15s ease;
  border-radius: 8px;
  border: 1px solid ${({ borderColor }) => borderColor};
  &:hover {
    &:after {
      content: ' ';
      display: block;
      position: absolute;
      border-radius: 8px;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
    }
  }
  ${color};
`;
