import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import _ from 'lodash';

import colors from 'themes/colors';
import { mediaMin } from 'themes/media';

import AppleSigninButton from './AppleSigninButton';
import GoogleSigninButton from './GoogleSigninButton';
import SlackSigninButton from './SlackSigninButton';

const Wrapper = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  color: ${({ flat }) => (flat ? colors.white : colors.gray800)};
  font-family: ObjectSans, sans-serif;

  p {
    white-space: nowrap;
    font-size: 13px;
    font-weight: 500;
    margin: 1px 15px 0 0;
  }

  .google-signin-button {
    margin-left: -6px;
  }

  .google-signin-button,
  .slack-signin-button {
    margin-right: 0;
  }

  ${mediaMin.xs`
    p {
      margin: 1px 18px 0 0;
      font-size: 14px;
    }
  
    .google-signin-button,
    .slack-signin-button {
      margin-right: 12px;
    }
  `}
`;

function SigninWithOAuth({
  onClickGoogleButton = _.noop,
  onClickSlackButton = _.noop,
  onClickAppleButton = _.noop,
  flat = false,
}) {
  return (
    <Wrapper className="sign-in-with-oauth__wrapper" flat={flat}>
      <p className="sign-in-with-oauth__or-start-with">Or start with</p>
      <GoogleSigninButton onClick={onClickGoogleButton} flat={flat} />
      <SlackSigninButton onClick={onClickSlackButton} flat={flat} />
      <AppleSigninButton onClick={onClickAppleButton} flat={flat} />
    </Wrapper>
  );
}

SigninWithOAuth.propTypes = {
  onClickGoogleButton: PropTypes.func,
  onClickSlackButton: PropTypes.func,
  onClickAppleButton: PropTypes.func,
  flat: PropTypes.bool,
};

export default SigninWithOAuth;
