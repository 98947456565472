import React from 'react';

import step1 from './lottie/step1.json';
import step2_1 from './lottie/step2-1.json';
import step2_2 from './lottie/step2-2.json';
import step3_1 from './lottie/step3-1.json';
import step3_2 from './lottie/step3-2.json';
import step4_1 from './lottie/step4-1.json';
import step4_2 from './lottie/step4-2.json';
import step5_1 from './lottie/step5-1.json';
import step5_2 from './lottie/step5-2.json';
import step6 from './lottie/step6.json';
import step7 from './lottie/step7.json';
import step8 from './lottie/step8.json';
import step9 from './lottie/step9.json';
import step10 from './lottie/step10.json';
import step11 from './lottie/step11.json';

/*
 * lotties 배열에서는 나중에 보여야 할 lottie 가 배열의 앞에 있어야 한다.
 */
const chapters = [
  {
    id: 'chapter-1',
    title: 'Collaborate asynchronously',
    desc: (
      <>
        Even though everyone is in different places and timezones, do your{' '}
        <strong>work flexibly</strong>.
      </>
    ),
    video: 'https://assets.additor.io/videos/landing/main-chapter-1.mp4',
    lotties: [
      {
        shouldPlayAt: 0.0,
        data: step1,
        loop: true,
      },
    ],
  },
  {
    id: 'chapter-2',
    title: 'Organize various information flexibly',
    desc: (
      <>
        You can make use of whatever you want <strong>beyond the format</strong>{' '}
        and streamline all.
      </>
    ),
    video: 'https://assets.additor.io/videos/landing/main-chapter-2-1.mp4',
    lotties: [
      {
        shouldPlayAt: 8.1,
        data: step3_2,
        loop: true,
      },
      {
        shouldPlayAt: 8.0,
        data: step3_1,
      },
      {
        shouldPlayAt: 0.5,
        data: step2_2,
        loop: true,
      },
      {
        shouldPlayAt: 0.0,
        data: step2_1,
      },
    ],
  },
  {
    id: 'chapter-3',
    title: 'Give feedback without ambiguity',
    desc: (
      <>
        For team collaboration, it's most important to{' '}
        <strong>remove miscommunication</strong>.
      </>
    ),
    video: 'https://assets.additor.io/videos/landing/main-chapter-3.mp4',
    lotties: [
      {
        shouldPlayAt: 0.5,
        data: step4_2,
        loop: true,
      },
      {
        shouldPlayAt: 0.0,
        data: step4_1,
      },
    ],
  },
  {
    id: 'chapter-4',
    title: 'No more double-check',
    desc: (
      <>
        Don't worry about <strong>catching up on the context</strong>. Additor
        just visualizes the flow.
      </>
    ),
    video: 'https://assets.additor.io/videos/landing/main-chapter-4.mp4',
    lotties: [
      {
        shouldPlayAt: 0.5,
        data: step5_2,
        loop: true,
      },
      {
        shouldPlayAt: 0.0,
        data: step5_1,
      },
    ],
  },
  {
    id: 'chapter-5',
    title: 'Dots connected',
    desc: (
      <>
        By clearly understanding the context, you can{' '}
        <strong>move forward and progress</strong>.
      </>
    ),
    video: 'https://assets.additor.io/videos/landing/main-chapter-5.mp4',
    lotties: [
      {
        shouldPlayAt: 9.5,
        data: step11,
      },
      {
        shouldPlayAt: 9,
        data: step10,
      },
      {
        shouldPlayAt: 8,
        data: step9,
      },
      {
        shouldPlayAt: 7,
        data: step8,
      },
      {
        shouldPlayAt: 6.5,
        data: step7,
      },
      {
        shouldPlayAt: 0.0,
        data: step6,
      },
    ],
  },
];

export default chapters;
