export default function getAppHostName() {
  const { host, protocol } = window.location;
  if (
    host.startsWith('localhost') ||
    host.match(/^\d+\.\d+\.\d+\.\d+(?::\d+)?$/)
  ) {
    return 'http://localhost:2339';
  }
  return `${protocol}//app.${host.replace(/^\w+\.(?=\w+\.\w+$)/, '')}`;
}
