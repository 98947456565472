import React from 'react';
import styled from 'styled-components/macro';
import { mediaMin } from 'themes/media';
import colors from 'themes/colors';
import { FilledArrowRight } from 'resources';
import { LogEventFns } from 'analytics/ga';
import { cdnImageBaseURL } from 'config/paths';
import SectionTitle from 'views/Common/SectionTitle';
import SectionDescription from 'views/Common/SectionDescription';

import { StyledLink } from './styled';

const SectionWrapper = styled.div`
  width: 100%;
  margin: 60px 0;
  position: relative;

  ${mediaMin.sm`
    margin: 80px 0 60px 0;
  `};
`;

const ContentsWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  ${mediaMin.xs`
    padding: 0 40px;
  `};

  ${mediaMin.sm`
    padding: 0 60px;
  `};

  ${mediaMin.md`
    display: grid;
    grid-template-columns: minmax(300px, 350px) auto;
    grid-template-rows: auto 115px auto;
  `};

  ${mediaMin.xlg`
    padding: 0;
  `}
`;

const Title = styled(SectionTitle)`
  margin: 0 0 18px 12px;
  text-align: center;
  color: ${colors.gray900};
  z-index: 1;

  ${mediaMin.md`
    text-align: start;
    grid-row: 1;
    grid-column: 1;
    margin: auto 0 0 10px;
  `};
`;

const Description = styled(SectionDescription)`
  margin: 30px 18px;
  text-align: center;
  color: ${colors.gray900};
  z-index: 1;

  ${mediaMin.xs`
    margin: 30px 18px;
  `};

  ${mediaMin.md`
    text-align: start;
    grid-row: 2;
    grid-column: 1;
    margin-left: 10px;
  `};
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${mediaMin.md`
    justify-content: flex-start;
    align-items: flex-start;
    grid-row: 3;
    grid-column: 1;
    margin-top: 10px;
    margin-left: 10px;
  `};
`;
const LearnMoreLink = styled(StyledLink)`
  color: ${colors.brand500};
`;

const StyledImage = styled.img`
  position: relative;
  width: 100%;
  z-index: 1;

  ${mediaMin.md`
    width: 650px;
    margin-left: 0;
  `};

  ${mediaMin.lg`
    width: 720px;
  `};
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-top: 20px;

  ${mediaMin.md`
    margin-top: 0;
    margin-left: auto;
    justify-content: flex-start;
    grid-row: 1/4;
    grid-column: 2;
  `};
`;

const DesktopGraphic = styled.div`
  display: none;
  position: absolute;
  background-color: ${colors.gray80};

  ${mediaMin.md`
    display: block;
  `};
`;

const DesktopRectShape = styled(DesktopGraphic)`
  height: 100%;
  right: 60px;

  ${mediaMin.md`
    width: 450px;
  `};

  ${mediaMin.lg`
    width: 550px;
  `};

  ${mediaMin.xlg`
    right: 0;
  `};
  border-radius: 15px;
`;

function FeaturesSection() {
  return (
    <SectionWrapper>
      <ContentsWrapper>
        <Title>Quality collaboration, done.</Title>
        <Description>
          Additor follows the natural workflow and structure of your team.
        </Description>
        <LinkWrapper>
          <LearnMoreLink
            to="/product"
            onClick={LogEventFns.main.clickFeatureLearnMoreLink}
          >
            How it works
            <FilledArrowRight />
          </LearnMoreLink>
        </LinkWrapper>
        <ImageWrapper>
          <StyledImage
            src={`${cdnImageBaseURL}/landing/main/feature-section-image.png`}
          />
        </ImageWrapper>
        <DesktopRectShape />
      </ContentsWrapper>
    </SectionWrapper>
  );
}

export default FeaturesSection;
