import * as React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { FormContext } from '..';

class Input extends React.PureComponent {
  state = {
    value: null,
    prevValueFromProps: '',
  };

  timer = null;

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      prevValueFromProps: props.value || '',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.prevValueFromProps) {
      return {
        value: nextProps.value,
        prevValueFromProps: nextProps.value,
      };
    }
    return null;
  }

  // TODO
  // shouldComponentUpdate (nextProps, nextState) {
  // }

  componentDidMount() {
    const { initFormValue } = this.context;
    const { name, validator } = this.props;
    const { value } = this.state;
    initFormValue(name, value, validator);
  }

  componentDidUpdate(_, prevState) {
    const { value } = this.state;
    const { validateTimeout } = this.props;
    if (value !== prevState.value) {
      this.updateFormData();
      // TODO 부모 변경 시 유효성 검사 한다면 여기서 타이머 돌리기
      if (validateTimeout > 0) {
        if (this.timer == null) {
          this.timer = window.setTimeout(
            this.updateFormData,
            validateTimeout,
            true,
          );
        } else {
          window.clearTimeout(this.timer);
          this.timer = window.setTimeout(
            this.updateFormData,
            validateTimeout,
            true,
          );
        }
      }
    }
  }

  componentWillUnmount() {
    const { freeFormData } = this.context;
    const { name } = this.props;
    freeFormData(name);
  }

  @autobind
  handleOnFocus(event) {
    const { onFocus } = this.props;
    return onFocus(event);
  }

  @autobind
  handleOnKeyUp(event) {
    const { onKeyUp } = this.props;
    return onKeyUp(event);
  }

  @autobind
  handleOnKeyDown(event) {
    const { onKeyDown } = this.props;
    return onKeyDown(event);
  }

  @autobind
  handleOnChange(event) {
    const { onChange, validateTimeout } = this.props;
    onChange(event);
    this.setState({
      value: event.target.value,
    });
    // TODO 부모 변경 시 유효성 검사 하지 않는다면 여기서 타이머 돌리기
    if (validateTimeout > 0) {
      if (this.timer == null) {
        this.timer = window.setTimeout(
          this.updateFormData,
          validateTimeout,
          true,
        );
      } else {
        window.clearTimeout(this.timer);
        this.timer = window.setTimeout(
          this.updateFormData,
          validateTimeout,
          true,
        );
      }
    }
  }

  @autobind
  handleOnBlur(event) {
    const { onBlur, validateOnBlur } = this.props;
    onBlur(event);
    this.setState({
      value: event.target.value,
    });
    if (validateOnBlur) {
      this.updateFormData(true);
      if (this.timer != null) {
        window.clearTimeout(this.timer);
        this.timer = null;
      }
    }
  }

  @autobind
  updateFormData(shouldValidate = false) {
    const { value } = this.state;
    const { updateFormData } = this.context;
    const { name } = this.props;
    updateFormData(name, value, shouldValidate);
  }

  render() {
    const {
      className,
      type,
      name,
      autoFocus,
      autoComplete,
      required,
      innerRef,
      placeholder,
    } = this.props;
    const { value } = this.state;
    const { formData, formName } = this.context;
    const errorInFormData = !!formData[name] && !!formData[name].error;
    return (
      <input
        id={`${formName}__${name}`}
        name={name}
        className={classNames(
          'form__control',
          { 'form__control--error': errorInFormData },
          className,
        )}
        type={type}
        value={value}
        onFocus={this.handleOnFocus}
        onChange={this.handleOnChange}
        onBlur={this.handleOnBlur}
        onKeyUp={this.handleOnKeyUp}
        onKeyDown={this.handleOnKeyDown}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        required={required}
        ref={innerRef}
        placeholder={placeholder}
      />
    );
  }
}

Input.contextType = FormContext;

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'text',
    'password',
    'tel',
    'email',
    'number',
    'hidden',
  ]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  validator: PropTypes.func,
  validateTimeout: PropTypes.number,
  validateOnBlur: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  placeholder: PropTypes.string,
};

Input.defaultProps = {
  value: '',
  className: '',
  onFocus: () => undefined,
  onChange: () => undefined,
  onBlur: () => undefined,
  onKeyUp: () => undefined,
  onKeyDown: () => undefined,
  validator: () => undefined,
  validateTimeout: 0,
  validateOnBlur: false,
  required: false,
  autoFocus: false,
  autoComplete: 'on',
  innerRef: () => undefined,
  placeholder: '',
};

export default Input;
