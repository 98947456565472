import React from 'react';

import HeroSection from './HeroSection';
import VideoFeedbackSection from './VideoFeedbackSection';
import TrackChangesSection from './TrackChangesSection';
import InformationSection from './InformationSection';
import NoteFeaturesSection from './NoteFeaturesSection';
import KnowledgeNetworkedSection from './KnowledgeNetworkedSection';
import OrganizeContentSection from './OrganizeContentSection';
import InternalCommunicationSection from './CommunicationSection/InternalCommunicationSection';
import ExternalCommunicationSection from './CommunicationSection/ExternalCommunicationSection';
import WebClipperSection from './WebClipperSection';
import BottomCTASection from './BottomCTASection';
import { Content } from './styled';

function Product() {
  return (
    <Content>
      <HeroSection />
      <VideoFeedbackSection />
      <TrackChangesSection />
      <InformationSection />
      <NoteFeaturesSection />
      <KnowledgeNetworkedSection />
      <OrganizeContentSection />
      <InternalCommunicationSection />
      <ExternalCommunicationSection />
      <WebClipperSection />
      <BottomCTASection />
    </Content>
  );
}

export default Product;
