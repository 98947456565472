const colors = {
  transparent: '#00000000',

  white: '#ffffff',
  white0: 'rgba(255, 255, 255, 0)', // safari에서는 #FFFFFF00 과 #00000000 를 구분하므로, white의 0을 따로 만들어둠.
  white5: 'rgba(255, 255, 255, 0.05)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white15: 'rgba(255, 255, 255, 0.15)',
  white25: 'rgba(255, 255, 255, 0.25)',
  white30: 'rgba(255, 255, 255, 0.3)',
  white45: 'rgba(255, 255, 255, 0.45)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white75: 'rgba(255, 255, 255, 0.75)',
  white85: 'rgba(255, 255, 255, 0.85)',
  white90: 'rgba(255, 255, 255, 0.9)',
  white95: 'rgba(255, 255, 255, 0.95)',

  black5: 'rgba(0, 0, 0, 0.05)',
  black10: 'rgba(0, 0, 0, 0.1)',
  black15: 'rgba(0, 0, 0, 0.15)',
  black20: 'rgba(0, 0, 0, 0.2)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black45: 'rgba(0, 0, 0, 0.45)',
  black60: 'rgba(0, 0, 0, 0.6)',
  black75: 'rgba(0, 0, 0, 0.75)',
  black85: 'rgba(0, 0, 0, 0.85)',

  gray50: '#F9F9F9',
  gray80: '#F5F5F5',
  gray100: '#EFEFF0',
  gray200: '#E7E7E8',
  gray300: '#D6D6D8',
  gray400: '#BFC0C2',
  gray500: '#A2A3A7',
  gray600: '#828488',
  gray700: '#606367',
  gray800: '#404346',
  gray900: '#222426',

  brand50: '#f7f5ff',
  brand70: '#eae5ff',
  brand80: '#dad1ff',
  brand100: '#beadff',
  brand200: '#a48dff',
  brand300: '#9378ff',
  brand400: '#8263ff',
  brand500: '#7552f6',
  brand600: '#6c49fa',
  brand700: '#623ff0',
  brand800: '#5634e0',
  brand900: '#4929cc',

  subbrand100: '#5A439A',
  subbrand200: '#523C8B',
  subbrand300: '#493185',
  subbrand400: '#3A2669',
  darkbrand90: 'rgba(17, 10, 34, 0.9)',

  airbrand: '#523b90',
  darkairbrand: '#34106a',
  darkairbrand90: 'rgba(52, 16, 106, 0.9)',
  darkairbrand60: 'rgba(52, 16, 106, 0.6)',

  red40: '#fff8f9',
  red50: '#ffe6e6',
  red100: '#ffdedf',
  red200: '#ffcecf',
  red300: '#feb2b5',
  red400: '#fd8c91',
  red450: '#fc747b',
  red500: '#fa626a',
  red550: '#fa5760',
  red600: '#f23c47',
  red650: '#f23a45',
  red700: '#e3202d',
  red800: '#c50f1d',
  red900: '#990814',

  blue30: '#fbfaff',
  blue50: '#def9ff',
  blue60: '#d5f9ff',
  blue80: '#98ebfe',
  blue100: '#6ce0fc',
  blue200: '#43d2f9',
  blue300: '#21c4f4',
  blue400: '#04b5ee',
  blue500: '#01a5e5',
  blue600: '#0296d9',
  blue700: '#0586cb',
  blue800: '#0977bb',
  blue900: '#0e69ab',

  yellow100: '#ffed79',
  yellow200: '#ffd044',
  yellow300: '#ffc021',
  yellow400: '#ffb003',

  green200: '#defcbd',
  green400: '#71E0BC',
};

export default colors;
