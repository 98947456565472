import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Card = props => {
  const className = cn('card', props.className, {
    'card--shadow-on-hover': props.shadowEffect === 'hover',
    'card--shadow': props.shadow,
  });
  return <div className={className}>{props.children}</div>;
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  shadowEffect: PropTypes.oneOf(['hover', 'click']),
  shadow: PropTypes.bool,
};

Card.defaultProps = {
  className: 'card',
  shadow: false,
};

export default Card;
