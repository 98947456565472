import { useEffect } from 'react';
import _ from 'lodash';

function useIntersectionObserver({
  targetElementRef,
  onIntersectingTrue = _.noop,
  onIntersectingFalse = _.noop,
  once = false,
  onBeforeCleanUp = _.noop,
}) {
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      _.forEach(entries, entry => {
        if (entry.isIntersecting) {
          onIntersectingTrue();
          if (once) {
            intersectionObserver.disconnect();
          }
        } else {
          onIntersectingFalse();
        }
      });
    });
    intersectionObserver.observe(targetElementRef.current);
    return () => {
      onBeforeCleanUp();
      intersectionObserver.disconnect();
    };
  }, []);
}

export default useIntersectionObserver;
