import getRequestBaseURL from './getRequestBaseURL';

export async function deleteCredentialsPassword({ workspaceEid, email }) {
  return await fetch(
    `${getRequestBaseURL()}/credentials/password?workspaceEid=${encodeURIComponent(
      workspaceEid,
    )}&email=${encodeURIComponent(email)}`,
    {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    },
  );
}
