import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

import colors from 'themes/colors';
import { mediaMin } from 'themes/media';
import { eventLogger } from 'analytics/amplitude';
import CompaniesSection from './CompaniesSection';
import CommentSection from './CommentSection';
import FeaturesSection from './FeaturesSection';
import HeroSection from './HeroSection';
import WorkflowSection from './WorkflowSection';
import TemplateSection from './TemplateSection';
import IntegrationSection from './IntegrationSection';
import CheckInSection from './CheckInSection';

const Content = styled.div`
  padding-top: 10px;
  background-color: ${colors.white};

  ${mediaMin.xs`
    padding-top: 0;
    margin-top: 0;
  `};
`;

function Main() {
  useEffect(() => {
    eventLogger.onboarding.landedOnLandingPage();
  }, []);

  return (
    <Content>
      <HeroSection />
      <WorkflowSection />
      <CompaniesSection />
      <TemplateSection />
      <CommentSection />
      <FeaturesSection />
      <IntegrationSection />
      <CheckInSection />
    </Content>
  );
}

export default Main;
