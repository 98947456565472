import React, { useRef, useEffect, useState } from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components/macro';

import { mediaMin } from 'themes/media';
import { slideToLeft } from 'themes/keyframes';
import { cdnImageBaseURL } from 'config/paths';
import SectionTitle from 'views/Common/SectionTitle';
import SectionDescription from 'views/Common/SectionDescription';

import TemplateItem from './TemplateItem';

const templates = [
  {
    thumb: `${cdnImageBaseURL}/landing/main/template/okr.jpg`,
    title: 'OKR',
    description: "Let's set and manage OKR to the growth of our team",
  },
  {
    thumb: `${cdnImageBaseURL}/landing/main/template/north-star-matric.jpg`,
    title: 'North Star Metric ⭐️',
    description:
      'The one metric that matters most to the growth of your product',
  },
  {
    thumb: `${cdnImageBaseURL}/landing/main/template/pitch-deck.jpg`,
    title: 'Pitch Deck',
    description: 'Organize content and set the storyline for your pitch deck',
  },
  {
    thumb: `${cdnImageBaseURL}/landing/main/template/elevator-pitch.jpg`,
    title: 'Elevator Pitch',
    description: 'How to pitch your future investors in 30 seconds',
  },
  {
    thumb: `${cdnImageBaseURL}/landing/main/template/daily-scrum-meeting.jpg`,
    title: 'Daily Scrum Meeting',
    description: 'Sharing us caring. Get all team members aligned.',
  },
  {
    thumb: `${cdnImageBaseURL}/landing/main/template/sprint-retrospective.jpg`,
    title: 'Sprint Retrospective',
    description: "Let's learn from what we've done and make it better",
  },
  {
    thumb: `${cdnImageBaseURL}/landing/main/template/meeting-note.jpg`,
    title: 'Meeting Note',
    description: 'Make our meeting productive and beneficial',
  },
  {
    thumb: `${cdnImageBaseURL}/landing/main/template/weekly-agenda.jpg`,
    title: 'Weekly Agenda',
    description: 'For the week-by-week growth of our team',
  },
  {
    thumb: `${cdnImageBaseURL}/landing/main/template/additor-quick-guide.jpg`,
    title: 'Additor Quick Guide',
    description: "Let's get started with this simple guide",
  },
  {
    thumb: `${cdnImageBaseURL}/landing/main/template/release-note.jpg`,
    title: 'Release Note 📝',
    description: 'Manage your release notes by versions',
  },
  {
    thumb: `${cdnImageBaseURL}/landing/main/template/product-planning.jpg`,
    title: 'Product Planning',
    description: "It's time to plan our next product or feature!",
  },
];

const Wrapper = styled.div`
  overflow: hidden;

  ${mediaMin.xs`
    margin-top: 150px;
  `};
`;

const TitleWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 110px auto 0;
  padding: 0 24px;

  ${mediaMin.xs`
    max-width: 1280px;
    padding: 0 80px;
  `};
`;

const Title = styled(SectionTitle)`
  margin-bottom: 16px;
  text-align: center;

  ${mediaMin.xs`
    margin-bottom: 20px; 
    br {
      display: none;
    } 
  `};
`;

const Description = styled(SectionDescription)`
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
`;

const TemplateGroupScrollWrapper = styled.div`
  position: relative;
  margin: 0 0 120px;
  width: 100%;
  overflow-x: auto;

  ${mediaMin.xs`
    margin: 0 auto 110px;
    overflow-x: hidden;  
  `};
`;

const TemplateGroup = styled.div`
  display: grid;
  grid-template-columns: ${({ templateLength }) =>
    `repeat(${templateLength}, minmax(230px, 1fr))`};
  grid-column-gap: 30px;
  height: 300px;
  padding: 0;
  margin: 0 auto 0 30px;
  ${mediaMin.lg`
    margin: 0 auto
  `};

  ${({ flowAnimationWidth }) => {
    if (flowAnimationWidth === 0) {
      return '';
    }
    return css`
      animation: ${slideToLeft(flowAnimationWidth)};
      animation-duration: ${(flowAnimationWidth / 260) * 8}s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-play-state: paused;
    `;
  }};
  animation-play-state: ${({ runAnimation }) =>
    runAnimation ? 'running' : 'pause'};
`;

function TemplateSection() {
  const sectionWrapper = useRef(null);
  const templateScrollWrapperRef = useRef(null);
  const templateGroupRef = useRef(null);
  const [flowAnimationWidth, setFlowAnimationWidth] = useState(0);
  const [runAnimation, setRunAnimation] = useState(false);
  const [orderedTemplates, setOrderedTemplates] = useState([]);

  const enableAnimation = () => {
    setRunAnimation(true);
    setOrderedTemplates(_.concat(templates, templates, templates));
    setFlowAnimationWidth(
      templateScrollWrapperRef.current.scrollWidth -
        templateScrollWrapperRef.current.clientWidth,
    );
  };

  const disableAnimation = () => {
    setRunAnimation(false);
    setOrderedTemplates(templates);
    setFlowAnimationWidth(0);
  };

  useEffect(() => {
    const setWidth = () => {
      if (!templateScrollWrapperRef.current) {
        disableAnimation();
        return;
      }

      if (document.body.clientWidth < 500) {
        disableAnimation();
      } else {
        enableAnimation();
      }
    };

    setWidth();
    window.addEventListener('resize', setWidth);
    return () => {
      window.addEventListener('resize', setWidth);
    };
  }, []);

  useEffect(() => {
    // 배경이미지 세팅
    sectionWrapper.current.style.backgroundImage = `url('${cdnImageBaseURL}/landing/main/template-background-pattern.svg')`;

    // 템플릿 섹션에 들어오는지 감지함.
    const intersectionObserver = new IntersectionObserver(entries => {
      const { isIntersecting } = entries[0];
      if (isIntersecting) {
        enableAnimation();
      } else {
        disableAnimation();
      }
    });
    intersectionObserver.observe(sectionWrapper.current);

    return () => {
      intersectionObserver.disconnect();
    };
  }, []);

  return (
    <Wrapper ref={sectionWrapper}>
      <TitleWrapper>
        <Title>
          Do your work. <br />
          We do work for work.
        </Title>
        <Description>
          Get started fast with the templates.
          <br />
          No effort for coding and styling is needed to organize your ideas.
        </Description>
      </TitleWrapper>
      <TemplateGroupScrollWrapper ref={templateScrollWrapperRef}>
        <TemplateGroup
          ref={templateGroupRef}
          templateLength={orderedTemplates.length}
          flowAnimationWidth={flowAnimationWidth}
          runAnimation={runAnimation}
        >
          {orderedTemplates.map(({ thumb, title, description }, index) => (
            <TemplateItem
              key={index}
              thumb={thumb}
              title={title}
              description={description}
            />
          ))}
        </TemplateGroup>
      </TemplateGroupScrollWrapper>
    </Wrapper>
  );
}

export default TemplateSection;
