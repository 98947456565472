import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Route } from 'react-router';
import { COOKIE_NAMES, getCookie } from 'utils/cookie';
import {
  FacebookLogo,
  LinkedInLogo,
  ProductHuntLogo,
  TwitterLogo,
} from 'resources';
import AboutPage from 'containers/AboutPage';
import PrivacyPolicy from 'containers/PrivacyPolicy';
import SignIn from 'containers/SignIn';
import SignUp from 'containers/SignUp';
import StartVerification from 'containers/StartVerification';

import Header from './Header';
import Footer from './Footer';
import { ThemeProvider } from './LayoutContext';

const menuItem = (name, linkTo, component, key, children = []) => ({
  name,
  linkTo,
  component,
  key: key || name,
  children,
});

class Root extends React.Component {
  state = {
    theme: 'white',
    hideHeader: false,
    hideFooter: false,
    readyToRender: false,
  };

  componentDidMount() {
    this.redirectToLoggedInWorkspace();
  }

  componentDidUpdate(prevProps) {
    const { location: prevLocation } = prevProps;
    const { location } = this.props;
    if (prevLocation.pathname !== '/' && location.pathname === '/') {
      this.redirectToLoggedInWorkspace();
    }
  }

  redirectToLoggedInWorkspace() {
    this.setState({ readyToRender: false });
    const workspaceEids = JSON.parse(
      getCookie(COOKIE_NAMES.WORKSPACE_EIDS) || '[]',
    );
    const latestWorkspaceEid = getCookie(COOKIE_NAMES.LATEST_WORKSPACE);
    const { location } = window;
    if (
      workspaceEids.length > 0 &&
      location.hostname !== 'localhost' &&
      location.pathname === '/'
    ) {
      if (latestWorkspaceEid) {
        location.href = `https://${latestWorkspaceEid}.${location.hostname}`;
      } else {
        location.href = `https://${workspaceEids[0]}.${location.hostname}`;
      }
    } else {
      this.setState({ readyToRender: true });
    }
  }

  updateLayout = theme => {
    this.setState({
      ...this.state,
      ...theme,
    });
  };

  render() {
    const { socialItems, location } = this.props;
    const { readyToRender, theme } = this.state;
    if (!readyToRender) return null;

    return (
      <ThemeProvider
        value={{
          layout: this.state,
          updateLayout: this.updateLayout,
        }}
      >
        <div
          id="AdditorLandingRoot"
          className={classNames(`theme--${theme}`, {
            'slack-page': location.pathname === '/slack-integration',
          })}
        >
          <Header />
          <Route path="/mail-sent">
            <StartVerification />
          </Route>
          <Route path="/sign-in">
            <SignIn />
          </Route>
          <Route path="/sign-up">
            <SignUp />
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Footer socialItems={socialItems} />
        </div>
      </ThemeProvider>
    );
  }
}

Root.propTypes = {
  socialItems: PropTypes.array,
  footerNavItems: PropTypes.array,
  location: PropTypes.object.isRequired,
};

Root.defaultProps = {
  socialItems: [
    menuItem('facebook', 'https://www.facebook.com/additor.io', FacebookLogo),
    menuItem('twitter', 'https://twitter.com/additor_io', TwitterLogo),
    menuItem(
      'linkedin',
      'https://www.linkedin.com/company/additor/',
      LinkedInLogo,
    ),
    menuItem(
      'producthunt',
      'https://www.producthunt.com/posts/additor-beta',
      ProductHuntLogo,
    ),
  ],
  footerNavItems: [],
};

export default Root;
