import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ReactModal from 'react-modal';

import helpers from 'themes/helpers';
import colors from 'themes/colors';

import { ReactComponent as SVGClose } from './assets/x.svg';

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5000;
  background-color: ${colors.black75};
`;

const StyledContent = styled.div`
  width: 100%;
  max-width: 1200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled.div``;

const CloseButton = styled.div`
  position: fixed;
  top: 32px;
  right: 32px;
  ${helpers.centerFlex};
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${colors.white5};
  cursor: pointer;

  &:hover {
    background-color: ${colors.white15};
  }
`;

const Video = styled.video`
  width: 100%;
  outline: none;
`;

function VideoModal({ src, isOpen, onRequestClose }) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      appElement={document.body}
      overlayElement={(props, contentElement) => (
        <StyledOverlay {...props} style={{}}>
          <CloseButton>
            <SVGClose />
          </CloseButton>
          {contentElement}
        </StyledOverlay>
      )}
      contentElement={(props, children) => (
        <StyledContent onClick={e => e.stopPropagation()} autoFocus>
          {children}
        </StyledContent>
      )}
    >
      <Wrapper>
        <Video src={src} controls muted playsInline autoPlay />
      </Wrapper>
    </ReactModal>
  );
}

VideoModal.propTypes = {
  src: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default VideoModal;
