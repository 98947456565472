import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { opacity } from 'styled-system';
import styled from 'styled-components/macro';
import { mediaMin } from 'themes/media';
import SectionTitle from 'views/Common/SectionTitle';
import SectionDescription from 'views/Common/SectionDescription';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  height: 100vh;
  position: relative;
  padding: 28vh 0;
  transform: translateX(${({ isLeft }) => (isLeft ? 0 : 100)}%);
  scroll-snap-align: start;
`;

const ContentsWrapper = styled.div`
  width: min(30vw, 500px);
  margin: ${({ isLeft }) =>
    isLeft ? '0 0 0 min(7vw, 114px)' : '0 min(7vw, 114px) 0 auto'};
  transform: translateY(calc(-50% + 24px));
  transition: opacity 0.1s;
  ${opacity};

  ${mediaMin.lg`
    transform: translateY(calc(-50% + 4px));
  `}

  ${mediaMin.xlg`
    transform: translateY(calc(-50% - 40px));
  `}
  
  ${SectionDescription}, ${SectionTitle} {
    text-align: inherit;
  }
  ${SectionDescription} {
    margin-bottom: 0;
    ${mediaMin.xs`
      margin-bottom: 0;
    `}
  }
`;

export const DISAPPEARED_REASONS = {
  SCROLL_UP: 'scrollUp',
  SCROLL_DOWN: 'scrollDown',
};

function Chapter({
  chapter,
  isLeft = false,
  onAppeared = _.noop,
  onDisappeared = _.noop,
  style = {},
}) {
  const wrapperRef = useRef();
  const contentsWrapperRef = useRef();
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const callback = entries => {
      _.forEach(entries, entry => {
        setIsIntersecting(entry.isIntersecting);
        if (entry.isIntersecting) {
          onAppeared(chapter);
        } else {
          const reason =
            entry.boundingClientRect.y > 0
              ? DISAPPEARED_REASONS.SCROLL_UP
              : DISAPPEARED_REASONS.SCROLL_DOWN;
          onDisappeared(chapter, reason);
        }
      });
    };

    // 0.5 만큼 보였을 때, isIntersecting 이 true 가 되고 opacity 가 0에서 1이 된다.
    const observer = new IntersectionObserver(callback, {
      rootMargin: '0px 0px -40% 0px',
      threshold: 0.5,
    });
    observer.observe(wrapperRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Wrapper id={chapter.id} ref={wrapperRef} isLeft={isLeft} style={style}>
      <ContentsWrapper
        ref={contentsWrapperRef}
        opacity={isIntersecting ? 1 : 0}
        isLeft={isLeft}
      >
        <SectionTitle>{chapter.title}</SectionTitle>
        <SectionDescription>{chapter.desc}</SectionDescription>
      </ContentsWrapper>
    </Wrapper>
  );
}

Chapter.propTypes = {
  chapter: PropTypes.object.isRequired,
  style: PropTypes.object,
  isLeft: PropTypes.bool,
  onAppeared: PropTypes.func,
  onDisappeared: PropTypes.func,
};

export default Chapter;
