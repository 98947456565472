import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as SVG from '../../resources';

const Icon = props => {
  const { alt, name, width, height } = props;
  const className = cn('icon', props.className);

  return (
    <img
      alt={alt}
      className={className}
      src={SVG[name]}
      width={width}
      height={height}
      {...props}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
};

Icon.defaultProps = {
  name: '',
  width: 20,
  height: 20,
  alt: 'icon',
};

export default Icon;
