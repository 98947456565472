import React from 'react';
import styled from 'styled-components/macro';

import colors from 'themes/colors';
import { mediaMin } from 'themes/media';
import { cdnImageBaseURL } from 'config/paths';
import SectionTitle from 'views/Common/SectionTitle';
import SectionDescription from 'views/Common/SectionDescription';
import { THEME } from 'views/Common/StoreButton';
import ChromeWebStoreButton from 'views/Common/StoreButton/ChromeWebStoreButton';
import { LogEventFns } from 'analytics/ga';

import { SectionWrapper } from './styled';

const SectionBackground = styled.div`
  background-color: ${colors.gray80};
`;

const StyledSectionDescription = styled(SectionDescription)`
  margin-bottom: 10px;
  ${mediaMin.xs`
    margin-bottom: 20px;
  `};
`;

const StyledImage = styled.img`
  width: 104%;
  margin-left: -2%;

  ${mediaMin.sm`
    width: 102%;
    margin-left: -1%;
  `};
`;

const ContentsWrapper = styled.div`
  width: 100%;
  min-height: 320px;
  position: relative;
  display: flex;
  flex-direction: column;

  a {
    margin: 12px auto 60px;

    ${mediaMin.lg`
      margin-top: 16px;
    `};
  }
`;

function WebClipperSection() {
  return (
    <SectionBackground>
      <SectionWrapper>
        <SectionTitle>Web clipper for Additor</SectionTitle>
        <StyledSectionDescription>
          Boost your web research and streamline all processes. Do more with
          less.
        </StyledSectionDescription>
        <ContentsWrapper>
          <ChromeWebStoreButton
            onClick={LogEventFns.product.clickCRXStoreButton}
            theme={THEME.BRAND.name}
          />
          <StyledImage
            src={`${cdnImageBaseURL}/landing/product/web-clipper.png`}
            alt="web-clipper"
          />
        </ContentsWrapper>
      </SectionWrapper>
    </SectionBackground>
  );
}

export default WebClipperSection;
