import React from 'react';

import Header from 'components/Header';
import Hero from 'components/Hero';
import Page from 'components/Page';

export default class AboutPage extends React.Component {
  render() {
    return (
      <Page id="about">
        <Hero className="hero--about px3 xs-px2">
          <div className="mw100 mx-auto text-center">
            <Header as="h2" className="hero--about__h2 xs-h25 xs-mt4 mb0 mt5">
              Build the future of work
            </Header>
            <p className="h5 hero--about__p mb4 xs-large">
              Additor helps people turn potential into reality. Tools are born
              to help you.
            </p>
          </div>
        </Hero>
      </Page>
    );
  }
}
