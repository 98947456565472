import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { opacity, space } from 'styled-system';

import colors from 'themes/colors';

const Wrapper = styled.div`
  position: sticky;
  top: 50%;
  right: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.3s;
  transform: translate(
      ${({ translateX }) => translateX}%,
      ${({ translateY }) => translateY}%
    )
    scale(${({ scale }) => scale});

  border-radius: 20px;
  ${opacity};
  ${space};

  &:before {
    display: block;
    padding-bottom: 56.25%;
    content: '';
    border-radius: 20px;
    box-shadow: 0 -10px 90px ${colors.black15};
  }
`;

function TopVideoWrapper({ children, isVisible }) {
  const [marginTop, setMarginTop] = useState(0);
  useEffect(() => {
    function setBeautifulMarginTop() {
      if (window.innerHeight < 720) {
        setMarginTop(0);
      } else {
        setMarginTop('calc(-160vh + 188px)');
      }
    }
    setBeautifulMarginTop();
    window.addEventListener('resize', setBeautifulMarginTop);
    return () => {
      window.removeEventListener('resize', setBeautifulMarginTop);
    };
  }, []);

  return (
    <Wrapper id="top-video-wrapper" opacity={isVisible ? 1 : 0} mt={marginTop}>
      {children}
    </Wrapper>
  );
}

TopVideoWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default TopVideoWrapper;
