import PropTypes from 'prop-types';
import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import { postGetStartedSignUp, putGetStarted } from 'api/getStarted';
import getStartedWithApple from 'api/getStartedWithApple';
import { getGoogleUser } from 'utils/googleAuth';
import { redirectToSlackOAuth } from 'utils/slackAuth';
import { setCookie } from 'utils/cookie';
import getAppHostName from 'utils/getAppHostName';
import { Form, FormGroup, Input, FormConsumer } from 'components/ContextForm';
import { LogEventFns } from 'analytics/ga';

import IconFavGoogle from 'resources/img/fav-google.png';
import IconFavSlack from 'resources/img/fav-slack.png';
import titleDecoration from 'resources/img/signin-decoration.png';
import { ReactComponent as inputCheck } from 'resources/svg/input-check.svg';
import { ReactComponent as inputCheckValid } from 'resources/svg/input-check-valid.svg';
import { ReactComponent as inputCheckInvalid } from 'resources/svg/input-check-invalid.svg';
import { ReactComponent as AppleIconSVG } from 'resources/svg/apple-logo.svg';

class PanelSignUp extends React.Component {
  state = {
    invalidPassword: false,
    invalidGoogleAccount: false,
  };

  handleOnClickGoogleSignUp = async () => {
    LogEventFns.signUp.clickGoogleSignUpButton();
    const googleUser = await getGoogleUser();
    this.handleOnGoogleSignUp(googleUser);
  };

  handleOnGoogleSignUp = async googleUser => {
    const {
      ok,
      verificationToken,
      verifiedUserName,
      ...res
    } = await putGetStarted(googleUser.getAuthResponse().id_token, 'google');
    if (ok) {
      setCookie('verification', verificationToken, 0, 60);
      setCookie('verified_user_name', verifiedUserName, 0, 60);
      window.location.href = `${getAppHostName()}/check-in`;
    } else if (res.status === 409 || res.status === 410) {
      this.setState({ invalidSignInInfo: true });
    }
  };

  validEmail = email => {
    if (!email) {
      return 'Email is required.';
    }
    const isEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9]{1,63})$/i;
    if (isEmail.test(email)) {
      return null;
    } else {
      return 'The email address is in an invalid format.';
    }
  };

  validPassword = password => {
    if (password.length > 0 && password.length < 6) {
      return 'This password is too short. It should be at least 6 character.';
    }
    return null;
  };

  handleOnSubmit = async (e, isValid, formData) => {
    e.preventDefault();
    LogEventFns.signUp.clickSignUpButton();
    if (isValid) {
      const res = await postGetStartedSignUp(formData.email, formData.password);
      if (res.ok) {
        this.props.history.push(
          `/mail-sent?email=${encodeURIComponent(formData.email)}`,
        );
      }
    }
  };

  handleOnClickGoogleAuth = async () => {
    const googleUser = await getGoogleUser();
    this.handleOnGoogleSignin(googleUser);
  };

  handleOnGoogleSignin = async googleUser => {
    const {
      ok,
      verificationToken,
      verifiedUserName,
      ...res
    } = await putGetStarted(googleUser.getAuthResponse().id_token, 'google');
    if (ok) {
      setCookie('verification', verificationToken, 0, 60);
      setCookie('verified_user_name', verifiedUserName, 0, 60);
      window.location.href = `${getAppHostName()}/check-in`;
    } else if (res.status === 409 || res.status === 410) {
      this.setState({ invalidSignInInfo: true });
    }
  };

  handleOnClickSlackAuth = () => {
    LogEventFns.signUp.clickSlackSignUpButton();
    redirectToSlackOAuth();
  };

  handleClickAppleAuth = async () => {
    LogEventFns.signUp.clickAppleSignUpButton();
    try {
      const data = await window.AppleID.auth.signIn();
      if (data) {
        getStartedWithApple(data.authorization.id_token, data.user);
      }
    } catch (e) {
      // ignore error
    }
  };

  renderInputValid(name) {
    return (
      <FormConsumer>
        {({ formData }) => {
          if (formData[name] && !!formData[name].error) {
            return <img className="valid-icon" src={inputCheckInvalid} />;
          }
          if (formData[name] && !!formData[name].isValid) {
            return <img className="valid-icon" src={inputCheckValid} />;
          }
          return <img className="valid-icon" src={inputCheck} />;
        }}
      </FormConsumer>
    );
  }

  render() {
    const { invalidGoogleAccount } = this.state;
    return (
      <div className="sign-up-page__panel--signup">
        <h1 className="sign-up-page__title">
          <span>
            Sign up to Additor
            <img className="sign-up-page__title__deco" src={titleDecoration} />
          </span>
        </h1>
        <p className="sign-up-page__desc">
          It’s a new day for organizing all your knowledge
        </p>
        <div className="sign-up-page__panel--signup__form">
          <Form name="findWorkspace" onSubmit={this.handleOnSubmit}>
            <FormGroup
              name="email"
              className="sign-up-page__panel__form-group sign-up-page__panel--signup__form-group"
            >
              <p className="sign-up-page__panel__label">
                Enter your <b>email</b>
              </p>
              <div
                className="sign-up-page__panel__form-control-wrap"
                onClick={LogEventFns.signUp.clickEmailInput}
              >
                <Input
                  name="email"
                  type="text"
                  className="sign-up-page__panel__form-control"
                  validator={this.validEmail}
                  validateTimeout={500}
                  validateOnBlur
                />
                {this.renderInputValid('email')}
              </div>
            </FormGroup>
            <FormGroup
              name="password"
              className="sign-up-page__panel__form-group sign-up-page__panel--signup__form-group"
            >
              <p className="sign-up-page__panel__label">
                <b>Password (Optional)</b>
              </p>
              <div
                className="sign-up-page__panel__form-control-wrap"
                onClick={LogEventFns.signUp.clickPasswordInput}
              >
                <Input
                  name="password"
                  type="password"
                  className="sign-up-page__panel__form-control"
                  validator={this.validPassword}
                  validateTimeout={500}
                  validateOnBlur
                />
              </div>
            </FormGroup>
            <button
              type="submit"
              className="sign-up-page__panel__btn-submit sign-up-page__panel--signup__btn-submit"
            >
              Next
            </button>
          </Form>
        </div>
        <div className="sign-up-page__panel--signup__or">or</div>
        <button
          type="button"
          className="sign-up-page__panel--signup__btn-google"
          onClick={this.handleOnClickGoogleSignUp}
        >
          <span className="icon" aria-hidden={true}>
            <img src={IconFavGoogle} />
          </span>
          Sign up with Google
        </button>
        <button
          type="button"
          className="sign-up-page__panel--signup__btn-slack"
          onClick={this.handleOnClickSlackAuth}
        >
          <span className="icon" aria-hidden={true}>
            <img src={IconFavSlack} />
          </span>
          Sign up with Slack
        </button>
        <button
          type="button"
          className="sign-up-page__panel--signup__btn-apple"
          onClick={this.handleClickAppleAuth}
        >
          <span className="icon" aria-hidden={true}>
            <AppleIconSVG width={20} height={20} />
          </span>
          Sign up with Apple
        </button>
        {invalidGoogleAccount && (
          <p className="sign-up-page__panel--signup__error-google">
            There’s no matched account with this google account in this
            workspace.
          </p>
        )}
        <div className="sign-up-page__sign-in-wrapper">
          <span>Already using Additor?</span>
          <Link
            theme="default"
            className="sign-up-page__sign-in-link"
            to="/sign-in"
            onClick={LogEventFns.signUp.clickSignInLink}
          >
            Sign in
          </Link>
          <button
            className="sign-up-page__google-sign-in-btn"
            type="button"
            onClick={this.handleOnClickGoogleAuth}
          >
            <img
              src={IconFavGoogle}
              className="icon"
              alt="Sign in with Google account"
            />
          </button>
          <button
            className="sign-up-page__google-sign-in-btn"
            type="button"
            onClick={this.handleOnClickSlackAuth}
          >
            <img
              src={IconFavSlack}
              className="icon"
              alt="Sign in with Slack account"
            />
          </button>
          <button
            className="sign-up-page__google-sign-in-btn"
            type="button"
            onClick={this.handleClickAppleAuth}
          >
            <AppleIconSVG width={14} />
          </button>
        </div>
      </div>
    );
  }
}

PanelSignUp.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(PanelSignUp);
