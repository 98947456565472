import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import colors from 'themes/colors';
import { mediaMin } from 'themes/media';
import ProgressBar, { DIRECTION } from 'views/Common/ProgressBar';

const VideoSelectorItemWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }

  ${mediaMin.md`
    flex-direction: column;
    margin-bottom: 0;
    margin-right: 20px;
    &:last-of-type {
      margin-bottom: 0;
      margin-right: 0;
    }
  `}
`;

const VideoSelectorName = styled.div`
  font-size: 17px;
  font-weight: 900;
  cursor: pointer;

  ${mediaMin.xs`
    font-size: 20px;
  `};

  ${mediaMin.md`
    display: flex;
    align-items: center;
    font-size: 16px;
    width: 100px;
    height: auto;
    margin-right: 14px;
  `};
`;

const VideoSelectorDescription = styled.div`
  font-size: 16px;
  margin-top: 10px;
  line-height: 1.9;
  cursor: pointer;

  ${mediaMin.md`
    flex: 1;
    font-size: 15px;
    margin-top: 0;
  `};
`;

const VideoSelectorItem = styled.div`
  width: calc(100% - 5px);
  border-radius: 5px;
  padding: 8px 12px;
  margin: 0 0 0 11px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: ${colors.white};

  ${VideoSelectorName} {
    color: ${({ selected }) => (selected ? colors.brand500 : colors.gray900)};
  }

  &:hover {
    background-color: ${colors.gray80};
  }

  ${mediaMin.md`
    width: 100%;
    padding: 12px 8px;
    margin: 11px 0 0;
    flex-direction: row;
  `};
`;

function Tab({ id, title, description, selected, onClick }) {
  const [duration, setDuration] = useState(0);
  const [progressDirection, setProgressDirection] = useState(
    DIRECTION.HORIZONTAL,
  );
  const handleClick = () => {
    if (selected) return;
    onClick(id);
  };

  useEffect(() => {
    function handleLoadedMetadata(e) {
      setDuration(e.target.duration * 1000);
    }
    const videoElement = document.querySelector(`[data-id="${id}"]`);
    videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    return () => {
      videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setProgressDirection(
        document.body.clientWidth > 992
          ? DIRECTION.HORIZONTAL
          : DIRECTION.VERTICAL,
      );
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <VideoSelectorItemWrapper>
      <ProgressBar
        duration={duration}
        isInProgress={selected}
        direction={progressDirection}
      />
      <VideoSelectorItem selected={selected ? 1 : 0} onClick={handleClick}>
        <VideoSelectorName>{title}</VideoSelectorName>
        <VideoSelectorDescription>{description}</VideoSelectorDescription>
      </VideoSelectorItem>
    </VideoSelectorItemWrapper>
  );
}

Tab.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Tab;
