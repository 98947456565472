import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'querystring';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { validate as isEmail } from 'isemail';

import { postGetStarted } from 'api/getStarted';
import Page from 'components/Page';
import LayoutContext from 'containers/Root/LayoutContext';

import textureSquare from 'resources/img/texture-square.png';
import textureCircle from 'resources/img/texture-circle.png';
import textureTriangle from 'resources/img/texture-triangle.png';

class StartVerification extends React.Component {
  state = {
    sendAgain: false,
  };

  constructor(props) {
    super(props);
    this.handleOnClickResend = this.handleOnClickResend.bind(this);
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search.slice(1));
    if (!query.email || !isEmail(query.email)) {
      this.props.history.push('/');
    }
    this.context.updateLayout({
      theme: 'purple',
      hideFooter: true,
    });
  }

  componentWillUnmount() {
    this.context.updateLayout({
      theme: 'white',
      hideFooter: false,
    });
  }

  async handleOnClickResend(e) {
    e.preventDefault();
    const email = queryString.parse(this.props.location.search.slice(1)).email;
    const res = await postGetStarted(email);
    if (res.ok) {
      this.setState({ sendAgain: true });
    }
  }

  render() {
    const email = queryString.parse(this.props.location.search.slice(1)).email;
    return (
      <Page className="start-verification" id="start-verification">
        <div className="start-verification__panel-wrap">
          <section className="start-verification__panel">
            <h1>Check your e-mail</h1>
            <p>
              We've sent an email to&nbsp;
              <strong>{email}</strong>.<br />
              It will expire shortly, so please verify it right now.
            </p>
            <button type="button" onClick={this.handleOnClickResend}>
              Resend
            </button>
            {this.state.sendAgain && (
              <p className="start-verification__panel__send-again">
                <span>
                  The confirmation email has been sent again.&nbsp;
                  <b>Please check your inbox.</b>
                </span>
              </p>
            )}
            <div className="start-verification__panel__banner">
              <video
                src="https://alphamon-asset.s3.ap-northeast-2.amazonaws.com/img/sign_in/email-verify.mp4"
                muted
                autoPlay
              />
            </div>
          </section>
          <div
            className="bg-texture bg-texture--triangle"
            style={{ backgroundImage: `url(${textureTriangle})` }}
          />
          <div
            className="bg-texture bg-texture--circle"
            style={{ backgroundImage: `url(${textureCircle})` }}
          />
          <div
            className="bg-texture bg-texture--square"
            style={{ backgroundImage: `url(${textureSquare})` }}
          />

          <p className="start-verification__create-ws">
            Wanna start your own project?&nbsp;
            <Link to="/sign-up">Create your workspace here.</Link>
          </p>
        </div>
      </Page>
    );
  }
}

StartVerification.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

StartVerification.contextType = LayoutContext;

export default withRouter(StartVerification);
