import styled from 'styled-components/macro';

import { mediaMin } from 'themes/media';

const HeroTitle = styled.h1`
  font-family: ObjectSans, sans-serif;
  font-weight: 800;
  text-align: center;
  margin: 0 0 10px;
  padding: 0 16px;
  font-size: 45px;
  line-height: 1.2;

  ${mediaMin.xs`
    padding: 0 24px;
    font-size: 65px;
  `};

  ${mediaMin.sm`
    font-size: 70px;
  `};

  ${mediaMin.md`
    font-size: 78px;
  `};

  ${mediaMin.lg`
    font-size: 85px;
  `};
`;

export default HeroTitle;
