import AES from 'crypto-js/aes';
import SHA256 from 'crypto-js/sha256';

import { eventLogger } from 'analytics/amplitude';

import getRequestBaseURL from './getRequestBaseURL';

export async function postGetStarted(email) {
  const res = await fetch(`${getRequestBaseURL()}/get-started`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  });
  if (res.ok) {
    eventLogger.onboarding.submittedEmailForLogin();
    return { ok: true, ...(await res.json()) };
  }
  return { ok: false, status: res.status };
}

export async function postGetStartedFind(email) {
  const res = await fetch(`${getRequestBaseURL()}/get-started/find`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  });
  if (res.ok) {
    eventLogger.onboarding.submittedEmailForLogin();
    return { ok: true, ...(await res.json()) };
  }
  return { ok: false, status: res.status };
}

export async function postGetStartedSignUp(email, password = '') {
  const reqBody = {
    email,
  };
  if (password) {
    const at = Date.now().toString();
    const shaHash = SHA256(password + email).toString();
    const secret = AES.encrypt(shaHash, at).toString();
    reqBody.at = at;
    reqBody.secret = secret;
  }
  const res = await fetch(`${getRequestBaseURL()}/get-started/signUp`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(reqBody),
  });
  if (res.ok) {
    eventLogger.onboarding.submittedEmailForLogin();
    return { ok: true, ...(await res.json()) };
  }
  return { ok: false, status: res.status };
}

export async function postGetStartedSignIn(email, workspaceEid) {
  const res = await fetch(`${getRequestBaseURL()}/get-started/signIn`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, workspaceEid }),
  });
  if (res.ok) {
    eventLogger.onboarding.submittedEmailForLogin();
    return { ok: true, ...(await res.json()) };
  }
  return { ok: false, status: res.status };
}

export async function putGetStarted(code, serviceType, extraCredential = {}) {
  const res = await fetch(`${getRequestBaseURL()}/get-started`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      code,
      credential: {
        serviceType: serviceType,
        ...extraCredential,
      },
    }),
  });
  if (res.ok) {
    return { ok: true, ...(await res.json()) };
  }
  return { ok: false, status: res.status };
}

export async function getGetStarted(workspaceId, email) {
  const res = await fetch(
    `${getRequestBaseURL()}/get-started?workspaceId=${workspaceId}&email=${encodeURIComponent(
      email,
    )}`,
    { method: 'GET' },
  );
  if (res.ok) {
    eventLogger.onboarding.submittedEmailForLogin();
    return { ok: true, ...(await res.json()) };
  }
  return { ok: false, status: res.status };
}
