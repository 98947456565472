import React, { useRef } from 'react';
import styled from 'styled-components/macro';

import { mediaMin } from 'themes/media';
import SectionDescription from 'views/Common/SectionDescription';
import SectionTitle from 'views/Common/SectionTitle';
import useSlideShow from 'views/Contents/customHooks/useSlideShow';
import { LogEventFns } from 'analytics/ga';

import { internalCommunicationTabs } from './tabs';
import TabMenuItem from './TabMenuItem';
import {
  StyledSectionWrapper,
  LeftTopFigure,
  ContentWrapper,
  ImageWrapper,
  StyledImage,
  TabMenu,
} from './styled';

const InternalCommunicationSectionWrapper = styled(StyledSectionWrapper)`
  ${mediaMin.md`
    flex-direction: row-reverse;
  `};
`;

const StyledContentWrapper = styled(ContentWrapper)`
  ${mediaMin.md`
    margin-left: 80px;
  `};
`;

const StyledLeftTopFigure = styled(LeftTopFigure)`
  left: -46px;
  top: -65px;
  border-radius: 0 0 100%;
`;

function InternalCommunicationSection() {
  const tabMenuRef = useRef();

  const { isStartedSlideShow, currentTabIndex, onClickTab } = useSlideShow({
    tabMenuRef,
    tabLength: internalCommunicationTabs.length,
  });

  const handleClickTab = tabIndex => {
    switch (tabIndex) {
      case 0:
        LogEventFns.product.clickJoinOrLeaveChannelButton();
        break;
      case 1:
        LogEventFns.product.clickKeepSecurityWithPrivateChannelsButton();
        break;
    }
    onClickTab(tabIndex);
  };

  return (
    <InternalCommunicationSectionWrapper id="setting-permission">
      <StyledContentWrapper>
        <SectionTitle>Optimize your internal collaboration</SectionTitle>
        <SectionDescription>
          Additor supports the basic structure of your organization or projects.
        </SectionDescription>
        <TabMenu ref={tabMenuRef}>
          {internalCommunicationTabs.map(
            ({ id, name, description }, tabIndex) => {
              return (
                <TabMenuItem
                  key={id}
                  name={name}
                  description={description}
                  isSelected={
                    isStartedSlideShow && tabIndex === currentTabIndex
                  }
                  onClick={() => handleClickTab(tabIndex)}
                />
              );
            },
          )}
        </TabMenu>
      </StyledContentWrapper>
      <ImageWrapper>
        <StyledLeftTopFigure />
        {internalCommunicationTabs.map(({ id, imgSrc }, tabIndex) => (
          <StyledImage
            key={id}
            src={imgSrc}
            alt="internal-communication"
            display={tabIndex === currentTabIndex ? 'block' : 'none'}
          />
        ))}
      </ImageWrapper>
    </InternalCommunicationSectionWrapper>
  );
}

export default InternalCommunicationSection;
