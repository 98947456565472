import styled from 'styled-components/macro';
import colors from 'themes/colors';
import { mediaMin } from 'themes/media';

const SectionTitle = styled.h2`
  font-family: ObjectSans, sans-serif;
  text-align: center;
  font-weight: 900;
  color: ${colors.gray900};
  margin: 0 0 12px;
  line-height: 1.5;
  font-size: 24px;

  ${mediaMin.xs`
    font-size: 30px;
  `};

  ${mediaMin.lg`
    font-size: 32px;
  `};

  ${mediaMin.xlg`
    font-size: 34px;
  `};
`;

export default SectionTitle;
