import getRequestBaseURL from './getRequestBaseURL';
import AES from 'crypto-js/aes';
import SHA256 from 'crypto-js/sha256';

export async function postEntranceWithPassword(workspaceId, email, password) {
  const at = Date.now().toString();
  const shaHash = SHA256(password + email).toString();
  const secret = AES.encrypt(shaHash, at).toString();
  const res = await fetch(`${getRequestBaseURL()}/entrance/p`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      credential: {
        workspaceId,
        email,
        secret,
        at,
      },
    }),
  });
  if (res.ok) {
    return { ok: true, ...(await res.json()) };
  }
  return { status: res.status };
}

export async function postEntranceWithGoogle(workspaceId, idToken) {
  const res = await fetch(`${getRequestBaseURL()}/entrance/g`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      credential: {
        workspaceId,
        token: idToken,
      },
    }),
  });
  if (res.ok) {
    return { ok: true, ...(await res.json()) };
  }
  return { status: res.status };
}

export async function postEntranceWithApple(workspaceId, idToken, user) {
  let extraCredential = {};
  if (user) {
    extraCredential.info = {
      name: user.name,
    };
  }
  const res = await fetch(`${getRequestBaseURL()}/entrance/a`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      credential: {
        workspaceId,
        token: idToken,
        ...extraCredential,
      },
    }),
  });
  if (res.ok) {
    return { ok: true, ...(await res.json()) };
  }
  return { status: res.status };
}
