import React, { useRef, useEffect } from 'react';
import styled from 'styled-components/macro';

import { mediaMin } from 'themes/media';
import colors from 'themes/colors';
import { LogEventFns } from 'analytics/ga';
import { cdnImageBaseURL } from 'config/paths';

import SectionTitle from 'views/Common/SectionTitle';
import SectionDescription from 'views/Common/SectionDescription';
import SignInToAdditor from 'views/Common/SignInToAdditor';
import { THEME } from 'views/Common/StoreButton';
import AppStoreButton from 'views/Common/StoreButton/AppStoreButton';
import ChromeWebStoreButton from 'views/Common/StoreButton/ChromeWebStoreButton';

const SectionWrapper = styled.div`
  width: 100%;
  height: 600px;
  ${mediaMin.xs`
    height: 500px;
  `};
  margin-bottom: 40px;
  overflow: hidden;
  background: ${colors.darkairbrand};
  background-size: cover;

  ${mediaMin.xs`
    background-size: auto;
    background-repeat: repeat-x;
  `}
`;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
  position: relative;
  margin: 0 auto;
  border-radius: 10px;
`;

const TitleWrapper = styled.div``;

const Title = styled(SectionTitle)`
  color: ${colors.white};
  white-space: pre-line;
  text-align: center;
  margin: 90px 0 16px;
  font-size: 34px;

  ${mediaMin.xs`
    white-space: nowrap;
  `};
`;

const Description = styled(SectionDescription)`
  text-align: center;
  height: 26px;
  color: ${colors.white};
  white-space: nowrap;
  margin-bottom: 40px;
`;

const AppButtons = styled.div`
  margin-top: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  a {
    margin: 0 0 20px 0;
    &:last-of-type {
      margin: 0;
    }
  }

  ${mediaMin.xs`
    flex-direction: row;
    a { 
      margin: 0 20px 0 0;
    }
  `};
`;

function CheckInSection() {
  const sectionRef = useRef(null);
  useEffect(() => {
    sectionRef.current.style.backgroundImage = `url('${cdnImageBaseURL}/landing/main/check-in-background-pattern.svg')`;
  }, []);

  return (
    <SectionWrapper ref={sectionRef}>
      <ContentsWrapper>
        <TitleWrapper>
          <Title>{`Check-in\n the future today.`}</Title>
          <Description>Don't waste your potential any more.</Description>
        </TitleWrapper>
        <SignInToAdditor
          flatOAuthButton
          onClickEmailInput={LogEventFns.main.clickBottomEmailInput}
          onSubmit={LogEventFns.main.clickBottomGetStartedButton}
          onClickSlackButton={LogEventFns.main.clickSlackSignInButton}
          onClickAppleButton={LogEventFns.main.clickAppleSignInButton}
          onClickGoogleButton={LogEventFns.main.clickGoogleSignInButton}
        />
        <AppButtons>
          <AppStoreButton
            theme={THEME.LIGHT.name}
            onClick={LogEventFns.main.clickChromeExtensionButton}
          />
          <ChromeWebStoreButton
            theme={THEME.LIGHT.name}
            onClick={LogEventFns.main.clickAppleAppStoreButton}
          />
        </AppButtons>
      </ContentsWrapper>
    </SectionWrapper>
  );
}

export default CheckInSection;
