export default async function sendEmailToSlack(body) {
  const slackWebhook =
    'https://hooks.slack.com/services/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';
  const res = await fetch(`${slackWebhook}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(body),
  });
  if (res.ok) {
    return { ok: true };
  }
  return { ok: false, status: res.status };
}
