import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { fetchGraphqlWorkspace } from 'api/fetchGraphqlWorkspace';
import { Form, FormGroup, Input } from 'components/ContextForm';
import { ReactComponent as ArrowLeftSVG } from 'resources/svg/arrow-left.svg';
import titleDecoration from 'resources/img/signin-decoration.png';
import SignInContext from './SignInContext';

class PanelSignInToWorkspace extends React.Component {
  searchWorkspace = async eid => {
    const response = await fetchGraphqlWorkspace(eid);
    if (response.ok) {
      const workspace = await response.data.fetch;
      if (workspace != null) {
        const { selectWorkspace } = this.context;
        selectWorkspace(workspace);
        return null;
      } else {
        return "This workspace URL doesn't exist or it's not open.";
      }
    } else {
      // 서버 or 네트워크 장애?
    }
    return null;
  };

  handleOnClickBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleOnSubmitForm = (e, isValid) => {
    e.persist();
    // TODO Set workspace via ajax request
    if (isValid) {
      const { history } = this.props;
      const { workspace } = this.context;
      history.push(`/sign-in/sign-in-to-workspace?workspace=${workspace.eid}`);
    }
  };

  render() {
    return (
      <div className="sign-in-page__panel sign-in-page__panel--workspace">
        <div
          onClick={this.handleOnClickBack}
          className="sign-in-page__panel--find__bnt-back"
        >
          <ArrowLeftSVG />
        </div>
        <h1 className="sign-in-page__title">
          <span>
            <img className="sign-in-page__title__deco" src={titleDecoration} />
            Directly sign in to it
          </span>
        </h1>
        <div className="sign-in-page__panel--workspace__form">
          <Form name="searchWorkspace" onSubmit={this.handleOnSubmitForm}>
            <FormGroup name="eid" className="sign-in-page__panel__form-group">
              <p className="sign-in-page__panel__label">
                Enter your <b>workspace’s URL</b>
              </p>
              <div className="sign-in-page__panel__form-control-wrap">
                <Input
                  name="eid"
                  type="text"
                  className="sign-in-page__panel__form-control sign-in-page__panel--workspace__form-control"
                  validator={this.searchWorkspace}
                  placeholder="workspace’s URL"
                  autoFocus
                />
                <span>.additor.io</span>
              </div>
            </FormGroup>
            <button
              type="submit"
              className="sign-in-page__panel__btn-submit sign-in-page__panel--workspace__btn-submit"
            >
              Continue
            </button>
          </Form>
        </div>
      </div>
    );
  }
}

PanelSignInToWorkspace.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(PanelSignInToWorkspace);
PanelSignInToWorkspace.contextType = SignInContext;
