import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Section = ({ className = '', children = null }) => {
  const classname = cn('section', className);
  return <section className={classname}>{children}</section>;
};

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Section;
