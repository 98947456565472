import { COOKIE_NAMES, getCookie } from './cookie';

export const EXTERNAL_MESSAGE_TYPES = {
  OPEN_TAB: 'additorChromeExtension/externalMessage/OPEN_TAB',
};

export function sendMessageToChromeExtension(message) {
  if (window.chrome) {
    window.chrome.runtime.sendMessage(getCookie(COOKIE_NAMES.CRX_ID), message);
  }
}
