import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { mediaMin } from 'themes/media';

export const StyledLink = styled(Link)`
  font-family: ObjectSans, sans-serif;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 17px;
  svg {
    margin-left: 5px;
  }

  ${mediaMin.xs`
    font-size: 20px;
    justify-content: center;

    .more {
      display: none;
    }
  `};
`;
