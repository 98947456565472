import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.body.scrollTop = 0;
    // 애니메이션 적용으로 인해 전체 스크롤을 window.scrollTo 로 조작하지 못하게 됨.
  }, [pathname]);

  return null;
}
