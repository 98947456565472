import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { layout } from 'styled-system';

import { mediaMin } from 'themes/media';
import { LogEventFns } from 'analytics/ga';
import SectionTitle from 'views/Common/SectionTitle';
import SectionDescription from 'views/Common/SectionDescription';
import useVideoSlideShow from 'views/Contents/customHooks/useVideoSlideShow';

import { SectionWrapper } from '../styled';
import tabs from './tabs';
import Tab from './Tab';

const Title = styled(SectionTitle)`
  text-align: center;
  margin-bottom: 12px;
`;

const Description = styled(SectionDescription)`
  text-align: center;
  margin-bottom: 30px;

  ${mediaMin.xs`
    margin-bottom: 30px;
  `};
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

const TabMenu = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  width: 100%;

  margin: 0 auto 30px;

  ${mediaMin.xs`
    max-width: 520px;
    margin: 0 auto 60px;
    grid-gap: 20px;
  `};
`;

const TabVideoWrapper = styled.div`
  width: 100%;
`;

const TabVideo = styled.video`
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  ${layout};
`;

function OrganizeContentSection() {
  const tabMenuRef = useRef();
  const videoRef = useRef({});
  const { selectedVideoId, onClickTab } = useVideoSlideShow({
    tabMenuRef,
    tabs,
    videoRef,
  });

  const handleClickTab = id => {
    switch (id) {
      case 'by-channels':
        LogEventFns.product.clickOrganizeByChannelButton();
        break;
      case 'by-tags':
        LogEventFns.product.clickOrganizeByTagButton();
        break;
      case 'with-dnd':
        LogEventFns.product.clickOrganizeWithDNDButton();
        break;
    }
    onClickTab(id);
  };

  return (
    <SectionWrapper id="content-management">
      <Title>Organize content with hierarchy and flexibility</Title>
      <Description>
        You can deal with various contexts according to projects or teams.
      </Description>
      <ContentWrapper>
        <TabMenu ref={tabMenuRef}>
          {tabs.map(({ id, name }) => (
            <Tab
              key={id}
              id={id}
              name={name}
              isSelected={id === selectedVideoId}
              onClick={handleClickTab}
            />
          ))}
        </TabMenu>
        <TabVideoWrapper>
          {tabs.map(({ id, src }) => (
            <TabVideo
              key={id}
              muted
              playsInline
              data-id={id}
              src={src}
              display={id === selectedVideoId ? 'block' : 'none'}
              ref={element => (videoRef.current[id] = element)}
            />
          ))}
        </TabVideoWrapper>
      </ContentWrapper>
    </SectionWrapper>
  );
}

export default OrganizeContentSection;
