import styled from 'styled-components/macro';
import colors from 'themes/colors';
import { mediaMin } from 'themes/media';

const SectionDescription = styled.p`
  font-family: ObjectSans, sans-serif;
  text-align: center;
  font-weight: normal;
  color: ${colors.gray900};
  line-height: 1.7;
  font-size: 17px;
  margin: 12px 0 40px;

  ${mediaMin.xs`
    margin: 12px 0 60px;
  `};

  ${mediaMin.xlg`
    font-size: 18px;
  `};

  strong {
    color: ${colors.gray900};
    font-weight: 500;
  }
`;

export default SectionDescription;
