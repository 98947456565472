import * as React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import classNames from 'classnames';

export const FormContext = React.createContext();
const { Provider: FormProvider } = FormContext;
export const FormConsumer = FormContext.Consumer;

class Form extends React.PureComponent {
  state = {};

  @autobind
  initFormValue(name, value, validator = () => undefined) {
    const { [name]: formData } = this.state;
    if (formData) {
      throw new Error(`${name} is already initialized `);
    }

    this.setState({
      [name]: {
        value,
        validator,
        fetching: false,
        isValid: false,
      },
    });
  }

  @autobind
  async validateFormData(name) {
    const { [name]: formData } = this.state;
    const error = await formData.validator(formData.value);
    this.setState({
      [name]: {
        ...formData,
        error,
        isValid: !error,
        fetching: false,
      },
    });
  }

  @autobind
  async updateFormData(name, value, validate = false) {
    const { [name]: formData } = this.state;
    if (value === formData.value && !validate) {
      return;
    }
    if (validate) {
      this.setState({ [name]: { ...formData, value, fetching: true } }, () =>
        this.validateFormData(name),
      );
    } else if (value !== formData.value) {
      this.setState({
        [name]: {
          ...formData,
          value,
          error: null,
          isValid: false,
        },
      });
    }
  }

  @autobind
  freeFormData(name) {
    this.setState({ [name]: undefined });
  }

  @autobind
  async _validateForm(res, name) {
    const { [name]: formData } = this.state;
    const error = await formData.validator(formData.value);
    this.setState({
      [name]: {
        ...formData,
        error,
        isValid: error == null,
        fetching: false,
      },
    });
    return !error && res;
  }

  @autobind
  async validateForm() {
    return Object.keys(this.state).reduce(this._validateForm, true);
  }

  @autobind
  async handleOnSubmit(e) {
    const { onSubmit } = this.props;
    if (onSubmit == null) {
      return;
    }
    e.preventDefault();
    const isValid = await this.validateForm();
    const formValue = {};
    for (let key in this.state) {
      if (!this.state.hasOwnProperty(key)) {
        continue;
      }
      formValue[key] = this.state[key].value;
    }
    onSubmit(e, isValid, formValue);
  }

  render() {
    const { children, className, name, method, action, innerRef } = this.props;
    const isValid = !Object.keys(this.state).find(
      // eslint-disable-next-line react/destructuring-assignment
      _name => this.state[_name] != null && !this.state[_name].isValid,
    );
    const hasError = !!Object.keys(this.state).find(
      // eslint-disable-next-line react/destructuring-assignment
      _name => this.state[_name] != null && !!this.state[_name].error,
    );
    return (
      <FormProvider
        value={{
          formName: name,
          formData: this.state,
          initFormValue: this.initFormValue,
          updateFormData: this.updateFormData,
          freeFormData: this.freeFormData,
          isValid,
          hasError,
        }}
      >
        <form
          className={classNames('form', className)}
          name={name}
          method={method}
          action={action}
          noValidate
          onSubmit={this.handleOnSubmit}
          ref={innerRef}
        >
          {children}
        </form>
      </FormProvider>
    );
  }
}

Form.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  action: PropTypes.string,
  method: PropTypes.string,
  onSubmit: PropTypes.func,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

Form.defaultProps = {
  className: '',
  action: '',
  method: '',
  onSubmit: null,
  innerRef: () => undefined,
};

export default Form;
