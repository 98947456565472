import React from 'react';
import { color, grid } from 'styled-system';
import _ from 'lodash';
import styled from 'styled-components/macro';

import { ReactComponent as SvgRakutenLogo } from './assets/rakuten-logo.svg';
import { ReactComponent as SvgLyftLogo } from './assets/lyft-logo.svg';
import { ReactComponent as SvgTechstarsLogo } from './assets/techstars-logo.svg';
import { ReactComponent as SvgQuotes } from './assets/quotes.svg';
import colors from 'themes/colors';
import { mediaMin } from 'themes/media';
import { cdnImageBaseURL } from 'config/paths';
import SectionTitle from 'views/Common/SectionTitle';
import SectionDescription from 'views/Common/SectionDescription';

const SectionWrapper = styled.div`
  width: 100%;
  margin-top: 80px;
  padding: 40px 0;

  ${mediaMin.sm`
    padding: 80px 0 60px;
  `};
`;

const ContentsWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding: 0 18px;

  ${mediaMin.xs`
    padding: 0 40px;
  `};

  ${mediaMin.sm`
    padding: 0 60px;
  `};

  ${mediaMin.xlg`
    padding: 0;
  `};
`;

const Title = styled(SectionTitle)`
  margin-bottom: 16px;
  text-align: center;

  ${mediaMin.xs`
    font-size: 32px;
    margin-bottom: 30px;
  `};
`;

const Description = styled(SectionDescription)`
  margin: 0 0 40px;
  text-align: center;
  white-space: pre-line;
`;

const LogoWrapper = styled.div`
  height: 29px;
  display: none;

  svg {
    height: 100%;
  }

  ${mediaMin.md`
    flex-basis: 100%;
    display: block;
  `};
`;

const StyledQuotes = styled(SvgQuotes)`
  display: none;
  margin-right: 15px;
  margin-top: 5px;
  width: 20px;
  position: absolute;

  ${mediaMin.md`
    display: block;
  `};
`;

const CommentWrapper = styled.div`
  padding: 20px;
  margin-bottom: 24px;
  border-radius: 15px;
  font-family: 'system-ui', Open Sans, Apple SD Gothic Neo, Noto Sans KR,
    sans-serif;
  ${color};
  ${grid};

  ${mediaMin.md`
    margin-bottom: 0;
    padding: 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `};
`;

const Comments = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaMin.md`
    max-width: 1200px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 24px;
    grid-template-areas:
      "dennis jed"
      "kyo kyo";
  `};
`;

const TextWrapper = styled.p`
  font-size: 17px;
  font-family: 'system-ui', Open Sans, Apple SD Gothic Neo, Noto Sans KR,
    sans-serif;
  line-height: 1.88;
  position: relative;
  margin: 0 0 50px;

  ${mediaMin.md`
    font-size: 20px;
    margin-left: 40px;
  `};
`;

const WriterName = styled.span`
  white-space: nowrap;
  margin-bottom: 6px;
  font-weight: 900;

  ${mediaMin.xs`
    flex-basis: 100%;
  `}

  ${mediaMin.md`
    flex-basis: auto;
  `}
`;

const WriterDivider = styled.span`
  display: none;
  margin-right: 12px;
  margin-left: 12px;

  ${mediaMin.md`
    display: block;
  `};
`;

const WriterJob = styled.span`
  display: none;
  white-space: nowrap;
  font-weight: 500;
  ${mediaMin.xs`
    display: inline;
  `};
`;

const WriterCompany = styled.span`
  white-space: nowrap;
  font-weight: 500;
  ${mediaMin.md`
    display: none;
  `};

  ${mediaMin.lg`
    display: inline;
  `};
`;

const WriterComma = styled.span`
  display: none;
  white-space: pre-wrap;
  
  ${mediaMin.xs`
    display: inline;
  `}
  
  ${mediaMin.md`
    display: none;
  `}

  ${mediaMin.lg`
    display: inline;
  `}
`;

const CommentImg = styled.img`
  width: 56px;
  height: 56px;
  clip-path: circle(50%);
  margin-right: 20px;
`;

const WriterWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;

    ${mediaMin.xs`
      flex-direction: row;
      flex-wrap: wrap;
    `};

    ${mediaMin.md`
      flex-direction: row;
  `};
  }
`;

const Comment1ImgSrc = `${cdnImageBaseURL}/landing/main/dennis-list.png`;
const Comment2ImgSrc = `${cdnImageBaseURL}/landing/main/kyo-kim.png`;
const Comment3ImgSrc = `${cdnImageBaseURL}/landing/main/jed-ng.png`;

const comments = [
  {
    gridArea: 'dennis',
    writer: {
      name: 'Dennis List',
      thumb: Comment1ImgSrc,
      job: 'Program Manager',
      company: 'Techstars',
    },
    backgroundColor: colors.gray80,
    logo: SvgTechstarsLogo,
    message: (
      <>
        As a program manager I research and collect large amounts of data and
        share this with my team and across the organization. Additor makes this
        a breeze because of its simplicity and ease of use.
      </>
    ),
  },
  {
    gridArea: 'jed',
    writer: {
      name: 'Jed Ng',
      thumb: Comment3ImgSrc,
      job: 'Investor',
      company: 'Head of Rakuten Rapid API',
    },
    backgroundColor: '#ffdfc7',
    logo: SvgRakutenLogo,
    message: (
      <>
        Additor brings together structure and visual elements to collaboration
        in a perfectly synchronous way. It's exactly what's needed to bring
        productivity back to endless messy Slack channel chats. Beautiful UI and
        integrations are improving all the time!
      </>
    ),
  },
  {
    gridArea: 'kyo',
    writer: {
      name: 'Kyo Kim',
      thumb: Comment2ImgSrc,
      job: 'Product Designer',
      company: 'Lyft',
    },
    backgroundColor: '#ffe8e9',
    logo: SvgLyftLogo,
    message: (
      <>
        As a designer, I found the Additor super useful for sharing and
        collaboration with someone else. Moreover it has been completely changed
        my workflow management, life style, note-taking and way of planning trip
        or event with someone else. It’s definitely a game changer!
      </>
    ),
  },
];

function CommentSection() {
  return (
    <SectionWrapper>
      <ContentsWrapper>
        <Title>Who’s working in the future?</Title>
        <Description>
          Our time and effort worth the most.{'\n'}Teams of high-performance
          have been using Additor for better productivity.
        </Description>
        <Comments>
          {_.map(
            comments,
            (
              {
                message,
                gridArea,
                writer: { thumb, name, job, company },
                logo: Logo,
                backgroundColor,
              },
              index,
            ) => (
              <CommentWrapper
                backgroundColor={backgroundColor}
                gridArea={gridArea}
                key={index}
              >
                <StyledQuotes />
                <TextWrapper>{message}</TextWrapper>
                <WriterWrapper>
                  <CommentImg src={thumb} />
                  <div>
                    <WriterName>{name}</WriterName>
                    <WriterDivider>|</WriterDivider>
                    <WriterJob>{job}</WriterJob>
                    <WriterComma>, </WriterComma>
                    <WriterCompany>{company}</WriterCompany>
                    <LogoWrapper>
                      <Logo />
                    </LogoWrapper>
                  </div>
                </WriterWrapper>
              </CommentWrapper>
            ),
          )}
        </Comments>
      </ContentsWrapper>
    </SectionWrapper>
  );
}

export default CommentSection;
