import { AMPLITUDE_KEY } from '../config/paths';

const amplitudeFns = {
  logEvent: (type, property) => {
    window.amplitude.getInstance().logEvent(type, property);
  },
};

const noop = () => {};
const analytics = AMPLITUDE_KEY
  ? amplitudeFns
  : Object.keys(amplitudeFns).reduce(
      (obj, key) => ({
        ...obj,
        [key]: noop,
      }),
      {},
    );

export const eventLogger = {
  onboarding: {
    landedOnLandingPage: () => {
      analytics.logEvent('landed on landing page');
    },
    submittedEmailForLogin: () => {
      analytics.logEvent('submitted email for login');
    },
    clickedOnGoogleLogin: () => {
      analytics.logEvent('clicked on google login');
    },
    clickedOnSlackLogin: () => {
      analytics.logEvent('clicked on slack login');
    },
  },
};

export default analytics;
