import React from 'react';


export const LayoutContext = React.createContext();

const { Provider: ThemeProvider, Consumer: ThemeConsumer } = LayoutContext;

export {
  ThemeProvider,
  ThemeConsumer,
};

export default LayoutContext;
