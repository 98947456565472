import React from 'react';
import PropTypes from 'prop-types';

const Menu = ({ className = 'list', children = null }) => {
  return <ul className={className}>{children}</ul>;
};

Menu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Menu;
