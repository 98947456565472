import React from 'react';
import styled from 'styled-components/macro';

import colors from 'themes/colors';
import { mediaMax, mediaMin } from 'themes/media';
import { LogEventFns } from 'analytics/ga';

import SectionTitle from 'views/Common/SectionTitle';
import YessioImg from './assets/yess.io-dashboard.webp';

const Content = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  padding: calc(10vh + 70px) 20px 100px;

  ${mediaMin.xs`
    padding-top: calc(10vh + 60px);
    padding-left: 40px;
    padding-right: 40px;
  `};

  ${mediaMin.md`
    padding-left: 80px;
    padding-right: 80px;
  `};

  ${SectionTitle} {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    font-size: inherit;
    font-weight: 400;
    line-height: 1.5;
    max-width: 800px;
  }

  h2 {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
  }

  a {
    font-size: inherit;
    font-weight: 900;
    color: ${colors.brand500};
  }
`;

const StyledHeroTitle = styled(SectionTitle)`
  margin-bottom: 70px;
  ${mediaMax.md`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}

  ${mediaMax.xs`
    padding: 0;
  `}
`;

const HeroImg = styled.img`
  width: 100%;
  max-width: 700px;
  margin-bottom: 70px;
  border-radius: 5px;
`;

// Add a new styled component for lists
const FeatureList = styled.ul`
  text-align: left;
  max-width: 800px;
  font-weight: 500;
  padding-left: 20px;

  li {
    margin-bottom: 10px;
    line-height: 1.5;
  }
`;

function Yessio() {
  return (
    <Content>
      <StyledHeroTitle>
        Yess.io - client management system and AI sales copilot for
        solopreneurs, agencies, and freelancers
      </StyledHeroTitle>
      <HeroImg src={YessioImg} />
      <p>
        We are introducing our sister product,{' '}
        <a href="https://yess.io" onClick={LogEventFns.yessio.clickYessioLink}>
          Yess.io
        </a>
        , a client management system and AI sales copilot for solopreneurs,
        agencies, and freelancers. What makes Yess different is that it can be
        your whole business OS with it's extensive set of features:
      </p>
      <FeatureList>
        <li>AI sales copilot for meeting preparation and follow-ups</li>
        <li>AI proposal generation</li>
        <li>Professional scheduling links that match your brand</li>
        <li>Integrated e-signature feature</li>
        <li>Invoice generation and management</li>
        <li>Comprehensive client management system</li>
        <li>Solopreneur-friendly interface that scales with your growth</li>
      </FeatureList>
      <p>
        Offload your admin work to Yess and focus on what you do best. Scale
        your business without increasing headcount.
      </p>
      <a href="https://yess.io" onClick={LogEventFns.yessio.clickYessioLink}>
        Get started with Yess
      </a>
    </Content>
  );
}

Yessio.propTypes = {};

export default Yessio;
