import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components/macro';

import colors from 'themes/colors';

export const DIRECTION = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
};

const ProgressBarBackground = styled.div`
  background-color: ${colors.brand70};
`;

function ProgressBar({
  className = '',
  direction = DIRECTION.HORIZONTAL,
  duration = 0,
  isInProgress = false,
  size = '4px',
}) {
  const [{ percent }, set] = useSpring(() => ({
    percent: 0,
  }));

  useEffect(() => {
    if (duration) {
      set({
        config: { duration },
      });
    }
  }, [duration]);

  useEffect(() => {
    set({
      percent: isInProgress ? 100 : 0,
      immediate: !isInProgress,
    });
  }, [isInProgress]);

  return (
    <ProgressBarBackground className={className}>
      <animated.div
        style={{
          width:
            direction === DIRECTION.HORIZONTAL
              ? percent.interpolate(p => `${p}%`)
              : size,
          height:
            direction === DIRECTION.HORIZONTAL
              ? size
              : percent.interpolate(p => `${p}%`),
          backgroundColor: colors.brand500,
        }}
      />
    </ProgressBarBackground>
  );
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  isInProgress: PropTypes.bool,
  direction: PropTypes.oneOf([DIRECTION.HORIZONTAL, DIRECTION.VERTICAL]),
  duration: PropTypes.number, // ms
  size: PropTypes.string,
};

export default ProgressBar;
