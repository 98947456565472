import React from 'react';

const Divider = () => (
  <div className="divider-wrapper mw100">
    <div className="divider" />
    <div className="divider-dot" />
  </div>
);

export default Divider;
