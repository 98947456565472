import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Hero = ({ className = '', children = null }) => {
  const classname = cn('hero', className);

  return <section className={classname}>{children}</section>;
};

Hero.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Hero;
