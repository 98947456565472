import React from 'react';


export const SignInContext = React.createContext();

const { Provider: SignInContextProvider } = SignInContext;

export {
  SignInContextProvider,
};

export default SignInContext;
