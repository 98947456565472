/* eslint-disable no-undef */
const GA = {
  pageView: location => {
    ga('send', 'pageview', location.pathname);
  },
  event: ({ category, action, label, value }) => {
    ga('send', 'event', category, action, label, value);
  },
};

export default GA;

export const LogEventFns = {
  header: {
    clickAdditorButton: () => {
      GA.event({
        category: 'Header',
        action: 'click additor button',
      });
    },
    clickProductButton: () => {
      GA.event({
        category: 'Header',
        action: 'click product button',
      });
    },
    clickIntegrationButton: () => {
      GA.event({
        category: 'Header',
        action: 'click integration button',
      });
    },
    clickPricingButton: () => {
      GA.event({
        category: 'Header',
        action: 'click pricing button',
      });
    },
    clickAboutUsButton: () => {
      GA.event({
        category: 'Header',
        action: 'click about-us button',
      });
    },
    clickYessioButton: () => {
      GA.event({
        category: 'Header',
        action: 'click yess.io button',
      });
    },
    clickSignInButton: () => {
      GA.event({
        category: 'Header',
        action: 'click sign-in button',
      });
    },
    clickSignUpButton: () => {
      GA.event({
        category: 'Header',
        action: 'click sign-up button',
      });
    },
  },
  main: {
    clickTopEmailInput: () => {
      GA.event({
        category: 'Main',
        action: 'click top email input',
      });
    },
    clickTopGetStartedButton: () => {
      GA.event({
        category: 'Main',
        action: 'click top get-started button',
      });
    },
    clickGoogleSignInButton: () => {
      GA.event({
        category: 'Main',
        action: 'click google sign-in button',
      });
    },
    clickSlackSignInButton: () => {
      GA.event({
        category: 'Main',
        action: 'click slack sign-in button',
      });
    },
    clickAppleSignInButton: () => {
      GA.event({
        category: 'Main',
        action: 'click apple sign-in button',
      });
    },
    clickHeroCollectButton: () => {
      GA.event({
        category: 'Main',
        action: 'click hero-collect button',
      });
    },
    clickHeroOrganizeButton: () => {
      GA.event({
        category: 'Main',
        action: 'click hero-organize button',
      });
    },
    clickHeroShareButton: () => {
      GA.event({
        category: 'Main',
        action: 'click hero-share button',
      });
    },
    clickFeatureLearnMoreLink: () => {
      GA.event({
        category: 'Main',
        action: 'click feature-learn-more link',
      });
    },
    clickAirGetStartedButton: () => {
      GA.event({
        category: 'Main',
        action: 'click air get-started button',
      });
    },
    clickProductHuntButton: () => {
      GA.event({
        category: 'Main',
        action: 'click product-hunt button',
      });
    },
    clickSynchronouslyButton: () => {
      GA.event({
        category: 'Main',
        action: 'click synchronously button',
      });
    },
    clickAsynchronouslyButton: () => {
      GA.event({
        category: 'Main',
        action: 'click asynchronously button',
      });
    },
    clickSlackIntegrationButton: () => {
      GA.event({
        category: 'Main',
        action: 'click slack-integration button',
      });
    },
    clickBottomEmailInput: () => {
      GA.event({
        category: 'Main',
        action: 'click bottom email input',
      });
    },
    clickBottomGetStartedButton: () => {
      GA.event({
        category: 'Main',
        action: 'click bottom get-started button',
      });
    },
    clickAppleAppStoreButton: () => {
      GA.event({
        category: 'Main',
        action: 'click apple-app-store button',
      });
    },
    clickChromeExtensionButton: () => {
      GA.event({
        category: 'Main',
        action: 'click chrome-extension button',
      });
    },
  },
  product: {
    clickHeroPing: () => {
      GA.event({
        category: 'Product',
        action: 'click hero ping',
      });
    },
    clickTopEmailInput: () => {
      GA.event({
        category: 'Product',
        action: 'click top email input',
      });
    },
    clickTopGetStartedButton: () => {
      GA.event({
        category: 'Product',
        action: 'click top get-started button',
      });
    },
    clickGoogleSignInButton: () => {
      GA.event({
        category: 'Product',
        action: 'click google sign-in button',
      });
    },
    clickSlackSignInButton: () => {
      GA.event({
        category: 'Product',
        action: 'click slack sign-in button',
      });
    },
    clickAppleSignInButton: () => {
      GA.event({
        category: 'Product',
        action: 'click apple sign-in button',
      });
    },
    clickWatchVideoButton: () => {
      GA.event({
        category: 'Product',
        action: 'click watch video button',
      });
    },
    clickKnowledgeLibraryButton: () => {
      GA.event({
        category: 'Product',
        action: 'click knowledge library button',
      });
    },
    clickInAppHighlighterButton: () => {
      GA.event({
        category: 'Product',
        action: 'click in app highlighter button',
      });
    },
    clickNoteFeatureListButton: () => {
      GA.event({
        category: 'Product',
        action: 'click note feature list button',
      });
    },
    clickNoteFeatureSimultaneousEditingButton: () => {
      GA.event({
        category: 'Product',
        action: 'click note feature simultaneous editing button',
      });
    },
    clickNoteFeatureImageGridButton: () => {
      GA.event({
        category: 'Product',
        action: 'click note feature image grid button',
      });
    },
    clickNoteFeatureEmbedButton: () => {
      GA.event({
        category: 'Product',
        action: 'click note feature embed button',
      });
    },
    clickNoteFeatureCommentButton: () => {
      GA.event({
        category: 'Product',
        action: 'click note feature comment button',
      });
    },
    clickNoteFeaturePageReferenceButton: () => {
      GA.event({
        category: 'Product',
        action: 'click note feature page reference button',
      });
    },
    clickOrganizeByChannelButton: () => {
      GA.event({
        category: 'Product',
        action: 'click organize by channel button',
      });
    },
    clickOrganizeByTagButton: () => {
      GA.event({
        category: 'Product',
        action: 'click organize by tag button',
      });
    },
    clickOrganizeWithDNDButton: () => {
      GA.event({
        category: 'Product',
        action: 'click organize with drag and drop button',
      });
    },
    clickJoinOrLeaveChannelButton: () => {
      GA.event({
        category: 'Product',
        action: 'click join or leave channel button',
      });
    },
    clickKeepSecurityWithPrivateChannelsButton: () => {
      GA.event({
        category: 'Product',
        action: 'click keep security with private channels button',
      });
    },
    clickInviteExternalCollaboratorsButton: () => {
      GA.event({
        category: 'Product',
        action: 'click invite external collaborators button',
      });
    },
    clickSharePagesPubliclyButton: () => {
      GA.event({
        category: 'Product',
        action: 'click share pages publicly button',
      });
    },
    clickCRXStoreButton: () => {
      GA.event({
        category: 'Product',
        action: 'click chrome web store button',
      });
    },
    clickBottomEmailInput: () => {
      GA.event({
        category: 'Product',
        action: 'click bottom email input',
      });
    },
    clickBottomGetStartedButton: () => {
      GA.event({
        category: 'Product',
        action: 'click bottom get-started button',
      });
    },
  },
  slackIntegration: {
    clickAddToSlackButton: () => {
      GA.event({
        category: 'SlackIntegration',
        action: 'click add-to-slack button',
      });
    },
  },
  pricing: {
    clickAirGetStartedButton: () => {
      GA.event({
        category: 'Pricing',
        action: 'click air get-started button',
      });
    },
    clickStandardGetStartedButton: () => {
      GA.event({
        category: 'Pricing',
        action: 'click standard get-started button',
      });
    },
    clickProStayTunedButton: () => {
      GA.event({
        category: 'Pricing',
        action: 'click pro stay-tuned button',
      });
    },
    clickEnterpriseContactUsButton: () => {
      GA.event({
        category: 'Pricing',
        action: 'click enterprise contact-us button',
      });
    },
  },
  aboutUs: {
    clickEmailLink: () => {
      GA.event({
        category: 'AboutUs',
        action: 'click email link',
      });
    },
  },
  yessio: {
    clickYessioLink: () => {
      GA.event({
        category: 'Yessio',
        action: 'click yess.io link',
      });
    },
  },
  signIn: {
    clickEmailInput: () => {
      GA.event({
        category: 'SignIn',
        action: 'click email input',
      });
    },
    clickSignInButton: () => {
      GA.event({
        category: 'SignIn',
        action: 'click sign-in button',
      });
    },
    clickGoogleSignInButton: () => {
      GA.event({
        category: 'SignIn',
        action: 'click google sign-in button',
      });
    },
    clickSlackSignInButton: () => {
      GA.event({
        category: 'SignIn',
        action: 'click slack sign-in button',
      });
    },
    clickAppleSignInButton: () => {
      GA.event({
        category: 'SignIn',
        action: 'click apple sign-in button',
      });
    },
    clickDirectlySignInButton: () => {
      GA.event({
        category: 'SignIn',
        action: 'click directly sign-in button',
      });
    },
    clickCreateANewWorkspaceButton: () => {
      GA.event({
        category: 'SignIn',
        action: 'click create-a-new-workspace button',
      });
    },
  },
  signUp: {
    clickEmailInput: () => {
      GA.event({
        category: 'SignUp',
        action: 'click email input',
      });
    },
    clickPasswordInput: () => {
      GA.event({
        category: 'SignUp',
        action: 'click password input',
      });
    },
    clickSignUpButton: () => {
      GA.event({
        category: 'SignUp',
        action: 'click sign-up button',
      });
    },
    clickGoogleSignUpButton: () => {
      GA.event({
        category: 'SignUp',
        action: 'click google sign-up button',
      });
    },
    clickSlackSignUpButton: () => {
      GA.event({
        category: 'SignUp',
        action: 'click slack sign-up button',
      });
    },
    clickAppleSignUpButton: () => {
      GA.event({
        category: 'SignUp',
        action: 'click apple sign-up button',
      });
    },
    clickSignInLink: () => {
      GA.event({
        category: 'SignUp',
        action: 'click sign-in link',
      });
    },
  },
  footer: {
    clickFacebookButton: () => {
      GA.event({
        category: 'Footer',
        action: 'click facebook button',
      });
    },
    clickTwitterButton: () => {
      GA.event({
        category: 'Footer',
        action: 'click twitter button',
      });
    },
    clickLinkedInButton: () => {
      GA.event({
        category: 'Footer',
        action: 'click linked-in button',
      });
    },
    clickProductHuntButton: () => {
      GA.event({
        category: 'Footer',
        action: 'click product-hunt button',
      });
    },
    clickTermsLink: () => {
      GA.event({
        category: 'Footer',
        action: 'click terms link',
      });
    },
    clickPrivacyLink: () => {
      GA.event({
        category: 'Footer',
        action: 'click privacy link',
      });
    },
  },
};
