import { keyframes } from 'styled-components/macro';

export const ping = keyframes`
  0% {
    transform: scale(0.5);
    background-color: rgba(117, 82, 246, .7);
  }
  80% {
    transform: scale(3.8);
    background-color: rgba(117, 82, 246, 0);
  }
  100% {
    transform: scale(8.2);
    background-color: rgba(117, 82, 246, 0);
  }
`;

export const slideInTop = keyframes`
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
`;

export const wiggle = range => {
  const min = (range / 2) * -1;
  const max = range / 2;

  const randomGenerator = () =>
    `${Math.min(
      max,
      Math.max(
        min,
        Math.random() *
          Math.floor(range / 2) *
          Math.pow(-1, Math.floor(Math.random() * 10) % 2),
      ),
    )}px`;

  return keyframes`
    0% {
      transform: translate(0px, 0px);
    }
    20% {
      transform: translate(${randomGenerator()}, ${randomGenerator()});
    }
    40% {
      transform: translate(${randomGenerator()}, ${randomGenerator()});
    }
    60% {
      transform: translate(${randomGenerator()}, ${randomGenerator()});
    }
    80% {
      transform: translate(${randomGenerator()}, ${randomGenerator()});
    }
    100% {
      transform: translate(0px, 0px);
    }
  `;
};

export const slideToLeft = width => {
  return keyframes`
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translate(${-1 * width}px);
    }
  `;
};
