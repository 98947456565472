import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components/macro';
import { color } from 'styled-system';

import ProgressBar from 'views/Common/ProgressBar';
import colors from 'themes/colors';
import { mediaMin } from 'themes/media';
import helpers from 'themes/helpers';

const Name = styled.div`
  height: 48px;
  text-align: center;
  font-weight: 500;
  border-radius: 5px;
  margin: 0 0 11px;
  ${color};

  &:hover {
    background-color: ${colors.gray80};
  }
  
  &:before {
    ${helpers.centerFlex};
    height: 48px;
    content: '${({ name }) => name.short}';
    font-size: 15px;
    font-family: ObjectSans, sans-serif;
  }
  
  ${mediaMin.xs`
    &:before {
      content: '${({ name }) => name.long}';
      font-size: 16px;
    }
  `}
`;

const Wrapper = styled.div`
  cursor: pointer;
`;

function Tab({ id, name, isSelected = false, onClick = _.noop }) {
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    function handleLoadedMetadata(e) {
      setDuration(e.target.duration * 1000);
    }
    const videoElement = document.querySelector(`[data-id="${id}"]`);
    videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    return () => {
      videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  }, []);

  const handleClick = () => {
    if (isSelected) return;
    onClick(id);
  };

  return (
    <Wrapper onClick={handleClick}>
      <Name color={isSelected ? colors.brand500 : colors.gray900} name={name} />
      <ProgressBar duration={duration} isInProgress={isSelected} />
    </Wrapper>
  );
}

Tab.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Tab;
