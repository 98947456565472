import React, { useState } from 'react';
import styled from 'styled-components/macro';

import colors from 'themes/colors';
import { mediaMin } from 'themes/media';
import { cdnImageBaseURL, cdnVideoBaseURL } from 'config/paths';
import SectionTitle from 'views/Common/SectionTitle';
import SectionDescription from 'views/Common/SectionDescription';
import { LogEventFns } from 'analytics/ga';

import { SectionWrapper } from '../styled';
import VideoModal from './VideoModal';
import { ReactComponent as Figure1 } from './assets/figure-1.svg';
import { ReactComponent as Figure2 } from './assets/figure-2.svg';
import { ReactComponent as PlayIcon } from './assets/play-icon.svg';

const StyledSectionWrapper = styled(SectionWrapper)`
  position: relative;
  z-index: 5;
`;

const SectionContent = styled.div`
  position: relative;
`;

const VideoWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 7px; // 내부 video 와 크기를 맞추기 위함
    left: 0;
    z-index: 11;
    border-radius: 5px;
    background-color: ${colors.black15};
  }

  ${mediaMin.lg`
    width: 80%;
  `};
`;

const ProfileImage = styled.img`
  width: 124px;
  position: absolute;
  z-index: 13;
  top: 24px;
  right: 8px;
  transform: translate(100%, -100%);
  display: none;

  ${mediaMin.lg`
    display: block;
  `};
`;

const StyledFigure1 = styled(Figure1)`
  position: absolute;
  top: -100px;
  left: -75px;
  display: none;

  ${mediaMin.lg`
    display: block;
  `};
`;

const StyledFigure2 = styled(Figure2)`
  position: absolute;
  top: 36px;
  left: -148px;
  display: none;

  ${mediaMin.lg`
    display: block;
  `};
`;

const Video = styled.video`
  position: relative;
  z-index: 5;
  width: 100%;
  border-radius: 5px;
`;

const VideoStartBtn = styled.button`
  display: flex;
  align-items: center;
  height: 45px;
  position: absolute;
  z-index: 13;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 24px;

  background-color: ${colors.brand500};
  color: ${colors.white};
  box-shadow: 0 0 0 15px rgba(117, 82, 246, 0.25), 0 0 5px 0 rgba(0, 0, 0, 0.3);
  border: none;
  outline: none;
  border-radius: 45px;
  cursor: pointer;
  font-weight: 500;

  svg {
    margin-left: 4px;
  }

  &:hover {
    background-color: ${colors.brand700};
  }
`;

const ArrowImage = styled.img`
  position: absolute;
  width: 632px;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 8px);
  display: none;

  ${mediaMin.xs`
    display: block;
  `};

  ${mediaMin.sm`
    width: 702px;
  `};
`;

function VideoFeedbackSection() {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const handleClickWatchBtn = () => {
    LogEventFns.product.clickWatchVideoButton();
    setIsVideoModalOpen(true);
  };

  const handleRequestClose = () => {
    setIsVideoModalOpen(false);
  };

  return (
    <StyledSectionWrapper
      data-section-id="video-feedback-section"
      id="video-feedback"
    >
      <SectionTitle>Clearly communicate with video feedback</SectionTitle>
      <SectionDescription>
        Check out what has been done or changed by collaborators.
        <br />
        Work must go on, never go back.
      </SectionDescription>
      <SectionContent>
        <VideoWrapper>
          <Video
            src={`${cdnVideoBaseURL}/landing/product-section-1.mp4`}
            poster={`${cdnImageBaseURL}/landing/product/product-section-1.png`}
          />
          <VideoStartBtn onClick={handleClickWatchBtn}>
            Watch 30-sec video <PlayIcon />
          </VideoStartBtn>
          <ProfileImage
            src={`${cdnImageBaseURL}/landing/product/commit-user-profile.png`}
          />
          <StyledFigure1 />
          <StyledFigure2 />
        </VideoWrapper>
        <VideoModal
          src={`${cdnVideoBaseURL}/landing/product-section-1.mp4`}
          isOpen={isVideoModalOpen}
          onRequestClose={handleRequestClose}
        />
        <ArrowImage src={`${cdnImageBaseURL}/landing/product/arrow-2.png`} />
      </SectionContent>
    </StyledSectionWrapper>
  );
}

export default VideoFeedbackSection;
