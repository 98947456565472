import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import _ from 'lodash';

import colors from 'themes/colors';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${colors.black45};
`;

function Dim({ onClick = _.noop }) {
  return <Wrapper onClick={onClick} />;
}

Dim.propTypes = {
  onClick: PropTypes.func,
};

export default Dim;
