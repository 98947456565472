import React from 'react';
import PropTypes from 'prop-types';
import colors from 'themes/colors';
import { AdditorNamelogo } from 'resources';

function getColor(type) {
  switch (type) {
    case 'basic':
      return colors.brand500;
    case 'white':
      return colors.white;
    default:
      return colors.brand500;
  }
}

const NameLogo = ({
  className = '',
  type = 'basic',
  width = '120px',
  height = '38px',
  onClick = () => {},
}) => {
  const color = getColor(type);
  return (
    <AdditorNamelogo
      color={color}
      className={className}
      width={width}
      height={height}
      onClick={onClick}
    />
  );
};

NameLogo.propTypes = {
  type: PropTypes.oneOf(['white', 'basic']),
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default NameLogo;
