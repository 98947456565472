import React from 'react';
import PropTypes from 'prop-types';

const Form = props => {
  return (
    <form
      className={props.className}
      onSubmit={props.onSubmit}
      noValidate={props.noValidate}
    >
      {props.children}
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  noValidate: PropTypes.bool,
};

Form.defaultProps = {
  className: '',
  onSubmit: () => {},
  noValidate: false,
};

export default Form;
