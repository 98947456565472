export default function getApiHostName() {
  const { host, protocol } = window.location;
  if (
    host.startsWith('localhost') ||
    host.match(/^\d+\.\d+\.\d+\.\d+(?::\d+)?$/)
  ) {
    return 'https://api.alphamon.xyz';
  }
  return `${protocol}//api.${host.replace(/^\w+\.(?=\w+\.\w+$)/, '')}`;
}
