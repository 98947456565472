import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LottieWeb from 'lottie-web';

import { cdnImageBaseURL } from 'config/paths';

function Lottie({
  animationData,
  assetsPath = `${cdnImageBaseURL}/landing/lottie_images/`,
  speed = 1,
  className = '',
  clickToPlay = false,
  hoverToPlay = false,
  autoplay = false,
  loop = false,
  onClick = _.noop,
}) {
  const lottie = useRef();
  const lottieContainerRef = useRef();

  useEffect(() => {
    lottie.current = LottieWeb.loadAnimation({
      container: lottieContainerRef.current,
      assetsPath,
      renderer: 'svg',
      loop,
      autoplay,
      animationData,
    });
    if (speed) {
      lottie.current.setSpeed(speed);
    }
    return () => {
      lottie.current.destroy();
    };
  }, [animationData, speed, autoplay, loop]);

  const handleMouseEnter = () => {
    if (hoverToPlay) {
      lottie.current.play();
    }
  };

  const handleMouseLeave = () => {
    if (hoverToPlay) {
      lottie.current.stop();
    }
  };

  const handleClick = () => {
    if (clickToPlay) {
      lottie.current.play();
    }
    onClick();
  };

  return (
    <div
      className={className}
      ref={lottieContainerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    />
  );
}

Lottie.propTypes = {
  animationData: PropTypes.object.isRequired,
  assetsPath: PropTypes.string,
  speed: PropTypes.number,
  className: PropTypes.string,
  clickToPlay: PropTypes.bool,
  hoverToPlay: PropTypes.bool,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Lottie;
