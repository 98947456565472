import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import _ from 'lodash';

import { mediaMax } from 'themes/media';
import colors from 'themes/colors';

import { AppleIconSVG } from 'resources';
import getStartedWithApple from 'api/getStartedWithApple';

const Wrapper = styled.div`
  display: flex;
  height: 28px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 3px;
  padding: 2px 6px 2px 3px;
  transition: all 0.15s ease;

  svg {
    color: ${({ flat }) => (flat ? colors.white : colors.black)};
    margin-right: 8px;
  }

  &:hover {
    background-color: ${({ flat }) => (flat ? colors.white15 : colors.black5)};
  }

  ${mediaMax.xs`
    font-size: 12px;

    svg {
      margin-right: 6px;
    }
  `}

  span {
    margin-top: 1px;
  }
`;

function AppleSigninButton({ flat, onClick = _.noop }) {
  const handleClickAppleAuth = useCallback(() => {
    window.AppleID.auth.signIn().then(data => {
      if (data) {
        getStartedWithApple(data.authorization.id_token, data.user);
      }
    });
    onClick();
  }, []);

  return (
    <Wrapper
      className="apple-signin-button"
      onClick={handleClickAppleAuth}
      flat={flat}
    >
      <AppleIconSVG />
      <span>Apple</span>
    </Wrapper>
  );
}

AppleSigninButton.propTypes = {
  flat: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default AppleSigninButton;
