import React from 'react';

import { eventLogger } from 'analytics/amplitude';

import { GAPI_API_KEY, GAPI_CLIENT_ID, cdnVideoBaseURL } from 'config/paths';
import BrowserDetector from './BrowserDetector';

import { showAlertModal } from 'views/Common/Modal';

export async function getGoogleAuthInstance() {
  if (window.gapi != null) {
    const created = window.gapi.auth2.getAuthInstance();
    if (created == null) {
      await window.gapi.client.init({
        apiKey: GAPI_API_KEY,
        clientId: GAPI_CLIENT_ID,
        scope: 'profile email',
        discoveryDocs: [
          'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
        ],
        fetchBasicProfile: true,
      });
      return await window.gapi.auth2.getAuthInstance();
    }
    return created;
  }
  return null;
}

async function getGoogleUserByGoogleAuthInstance(
  googleAuthInstance,
  signInOption,
) {
  return await googleAuthInstance.signIn({
    prompt: 'select_account',
    ...signInOption,
  });
}

export async function getGoogleUser(signInOption = {}) {
  eventLogger.onboarding.clickedOnGoogleLogin();
  try {
    const googleAuthInstance = await getGoogleAuthInstance();
    return await getGoogleUserByGoogleAuthInstance(
      googleAuthInstance,
      signInOption,
    );
  } catch (e) {
    if (e.details === 'Cookies are not enabled in current environment.') {
      let videoSrc;
      if (BrowserDetector.isChrome) {
        videoSrc = `${cdnVideoBaseURL}/landing/allow-3rd-cookies-on-chrome.mp4`;
      } else if (BrowserDetector.isSafari) {
        videoSrc = `${cdnVideoBaseURL}/landing/allow-3rd-cookies-on-safari.mp4`;
      }

      showAlertModal({
        alertProps: {
          title: 'Cookies are not enabled in the current environment',
          contents: (
            <>
              <p>To sign in, please allow third-party cookies.</p>
              {videoSrc && <video src={videoSrc} autoPlay loop width="100%" />}
            </>
          ),
        },
      });
    }
    throw e;
  }
}
