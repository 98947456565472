import styled from 'styled-components/macro';
import { mediaMin } from 'themes/media';

const HeroDescription = styled.p`
  font-family: ObjectSans, sans-serif;
  text-align: center;
  margin: 0 0 42px;
  padding: 0 16px;
  font-size: 18px;
  line-height: 1.5;

  ${mediaMin.xs`
    padding: 0 24px;
  `};

  ${mediaMin.md`
    font-size: 19px;
  `};

  ${mediaMin.lg`
    font-size: 21px;
  `};
`;

export default HeroDescription;
