import React from 'react';
import styled from 'styled-components/macro';
import { mediaMin } from 'themes/media';

import { cdnImageBaseURL } from 'config/paths';
import SectionTitle from 'views/Common/SectionTitle';
import SectionDescription from 'views/Common/SectionDescription';
import { SectionWrapper } from '../styled';

import { ReactComponent as Figure1 } from './assets/figure-1.svg';
import { ReactComponent as Figure2 } from './assets/figure-2.svg';
import { ReactComponent as Figure3 } from './assets/figure-3.svg';

const StyledSectionWrapper = styled(SectionWrapper)`
  z-index: 5;
`;

const SectionContent = styled.div`
  position: relative;
`;

const SectionImg = styled.img`
  display: block;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  ${mediaMin.lg`
    max-width: calc(1000px + 80px);
    margin: 0 auto;
  `};
`;

const StyledFigure1 = styled(Figure1)`
  position: absolute;
  top: -70px;
  left: calc(50% + 134px);
  display: none;
  ${mediaMin.lg`
    display: block;  
  `};
`;

const StyledFigure2 = styled(Figure2)`
  position: absolute;
  top: calc(50% - 92px);
  left: calc(50% + 504px);
  display: none;
  ${mediaMin.lg`
    display: block;  
  `};
`;

const StyledFigure3 = styled(Figure3)`
  position: absolute;
  top: calc(100% + 54px);
  left: calc(50% - 640px);
  display: none;
  ${mediaMin.lg`
    display: block;  
  `};
`;

function TrackChangesSection() {
  return (
    <StyledSectionWrapper id="track-changes">
      <SectionTitle>Track important changes visually</SectionTitle>
      <SectionDescription>
        For distributed teams, processes are much more important than results.
        <br />
        It's easier to follow up on changes.
      </SectionDescription>
      <SectionContent>
        <SectionImg
          src={`${cdnImageBaseURL}/landing/product/track-changes.png`}
        />
        <StyledFigure1 />
        <StyledFigure2 />
        <StyledFigure3 />
      </SectionContent>
    </StyledSectionWrapper>
  );
}

export default TrackChangesSection;
