import React from 'react';

import Header from 'components/Header';
import Hero from 'components/Hero';
import Page from 'components/Page';

export default class UseCasePage extends React.Component {
  render() {
    return (
      <Page id="use-case">
        <Hero className="hero--use-cases px3 xs-px2">
          <div className="mw100 mx-auto text-center">
            <Header
              as="h2"
              className="hero--use-cases__h2 xs-h25 xs-mt4 mb0 mt5"
            >
              Knowledge matters
            </Header>
            <p className="h5 hero--use-cases__p mb4 xs-large">
              Whatever your team or project is
            </p>
          </div>
        </Hero>
      </Page>
    );
  }
}
