import { eventLogger } from 'analytics/amplitude';

import {
  sendMessageToChromeExtension,
  EXTERNAL_MESSAGE_TYPES,
} from './sendMessageToChromeExtension';
import getApiHostName from './getApiHostName';

export function redirectToSlackOAuth(config = {}) {
  eventLogger.onboarding.clickedOnGoogleLogin();
  const { isExtension = false, workspaceId = '' } = config;

  const params = new URLSearchParams();
  if (workspaceId) {
    params.append('w', workspaceId);
  }
  if (isExtension) {
    params.append('from', 'extension');
  }
  const queryString = params.toString();

  if (isExtension) {
    sendMessageToChromeExtension({
      type: EXTERNAL_MESSAGE_TYPES.OPEN_TAB,
      url: `${getApiHostName()}/inte/slack/authenticate${
        queryString !== '' ? `?${queryString}` : ''
      }`,
    });
    window.close();
  } else {
    window.location.href = `${getApiHostName()}/inte/slack/authenticate${
      queryString !== '' ? `?${queryString}` : ''
    }`;
  }
}
