import PropTypes from 'prop-types';
import * as React from 'react';
import classNames from 'classnames';
import { FormContext } from '..';

class FormGroup extends React.PureComponent {
  render() {
    const { formData, formName } = this.context;
    const { children, name, className, error, hideErrorMessage } = this.props;

    const errorFromContext = !!formData[name] && formData[name].error;
    return (
      <div
        className={classNames(
          'form__group',
          { 'form__group--error': error || errorFromContext },
          className,
        )}
      >
        <div
          className={classNames('form__group__wrap', {
            'form__group--error__wrap':
              !hideErrorMessage && (error || errorFromContext),
          })}
        >
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label className="form__label" htmlFor={`${formName}__${name}`}>
            {children}
            {/* {!!formData[name] && formData[name].fetching.toString()} */}
          </label>
          {!hideErrorMessage && (error || errorFromContext) && (
            <p className="form__error">{error || errorFromContext}</p>
          )}
        </div>
      </div>
    );
  }
}

FormGroup.contextType = FormContext;

FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  error: PropTypes.string,
  hideErrorMessage: PropTypes.bool,
};

FormGroup.defaultProps = {
  className: '',
  error: '',
  hideErrorMessage: false,
};

export default FormGroup;
