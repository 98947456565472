import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'themes/colors';

const TemplateWrapper = styled.div`
  border-radius: 8px;
  box-shadow: 0 0 32px 0 ${colors.black5};
  background-color: ${colors.white};
  overflow: hidden;
`;

const TemplateThumb = styled.img`
  width: 100%;
  height: 140px;
  object-fit: cover;
`;

const TemplateColor = styled.div`
  width: 100%;
  height: 140px;
  background-color: ${colors.gray800};
`;

const TemplateContents = styled.div`
  padding: 34px 20px;
`;

const TemplateTitle = styled.div`
  font-size: 18px;
  font-weight: 900;
  line-height: 1.11;
  margin-bottom: 15px;
  font-family: ObjectSans, sans-serif;
`;

const TemplateDescription = styled.div`
  width: 100%;
  margin-bottom: 12px;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 500;
  font-family: 'system-ui', Open Sans, Apple SD Gothic Neo, Noto Sans KR,
    sans-serif;
`;

function TemplateItem({ thumb, description, title }) {
  const templateItemRef = useRef(null);

  return (
    <TemplateWrapper ref={templateItemRef}>
      {thumb ? <TemplateThumb src={thumb} /> : <TemplateColor />}
      <TemplateContents>
        <TemplateTitle>{title}</TemplateTitle>
        <TemplateDescription>{description}</TemplateDescription>
      </TemplateContents>
    </TemplateWrapper>
  );
}

TemplateItem.propTypes = {
  thumb: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  parentRef: PropTypes.node,
};

export default TemplateItem;
