import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { validate as isEmail } from 'isemail';

import { postGetStartedFind } from 'api/getStarted';
import { Form, FormGroup, Input } from 'components/ContextForm';
import findMotion from 'resources/img/find_workspace.gif';
import findSteal from 'resources/img/find_workspace.jpg';
import titleDecoration from 'resources/img/signin-decoration.png';
import { ReactComponent as ArrowLeftSVG } from 'resources/svg/arrow-left.svg';

function validateEmail(email) {
  if (!isEmail(email)) {
    return 'The email address is in an invalid format.';
  }
  return '';
}

class PanelFindWorkspace extends React.Component {
  timer = null;
  state = {
    motionPlay: true,
  };

  componentDidMount() {
    this.timer = window.setTimeout(
      () => this.setState({ motionPlay: false }),
      3250,
    );
  }

  componentWillUnmount() {
    if (this.timer != null) {
      window.clearTimeout(this.timer);
    }
  }

  handleOnClickBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleOnSubmitForm = async (e, isValid, { email }) => {
    e.preventDefault();
    if (!isValid) {
      return;
    }
    const res = await postGetStartedFind(email);
    if (res.ok) {
      this.props.history.push(`/mail-sent?email=${encodeURIComponent(email)}`);
    }
  };

  render() {
    return (
      <div className="sign-in-page__panel sign-in-page__panel--find">
        <div
          onClick={this.handleOnClickBack}
          className="sign-in-page__panel--find__bnt-back"
        >
          <ArrowLeftSVG />
        </div>
        <h1 className="sign-in-page__title sign-in-page__panel--find__title">
          <span>
            <img className="sign-in-page__title__deco" src={titleDecoration} />
            Find your Additor
            <br />
            workspace
          </span>
        </h1>
        <Form name="findWorkspace" onSubmit={this.handleOnSubmitForm}>
          <FormGroup name="email" className="sign-in-page__panel__form-group">
            <p className="sign-in-page__panel--find__form-label">
              Enter your <b>email</b>
            </p>
            <div className="sign-in-page__panel__form-control-wrap">
              <Input
                name="email"
                type="email"
                placeholder="you@example.com"
                className="sign-in-page__panel__form-control"
                validator={validateEmail}
                validateOnBlur
                autoFocus
              />
            </div>
          </FormGroup>
          <button type="submit" className="sign-in-page__panel__btn-submit">
            Continue
          </button>
        </Form>
        <div className="sign-in-page__panel--find__banner">
          {this.state.motionPlay ? (
            <img src={findMotion} />
          ) : (
            <img src={findSteal} />
          )}
        </div>
      </div>
    );
  }
}

PanelFindWorkspace.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(PanelFindWorkspace);
