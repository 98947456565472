import React from 'react';
import styled from 'styled-components/macro';

import SectionTitle from 'views/Common/SectionTitle';
import SectionDescription from 'views/Common/SectionDescription';
import colors from 'themes/colors';
import { cdnImageBaseURL } from 'config/paths';
import { mediaMin } from 'themes/media';

import { SectionWrapper } from './styled';

const Wrapper = styled(SectionWrapper)`
  max-width: unset;
  background-color: ${colors.gray50};
  padding-left: 0;
  padding-right: 0;

  ${mediaMin.xs`
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  `};

  ${mediaMin.sm`
    padding-bottom: 0;
  `};

  ${mediaMin.md`
    padding-bottom: 0;
  `};

  ${mediaMin.lg`
    max-width: unset;
    padding-bottom: 0;
  `};

  ${mediaMin.xlg`
    padding-bottom: 0;
  `}
`;

const Title = styled(SectionTitle)`
  text-align: center;
  margin-bottom: 12px;
  padding: 0 16px;
`;

const Description = styled(SectionDescription)`
  text-align: center;
  margin-bottom: 32%;
  padding: 0 16px;

  ${mediaMin.xs`
    margin-bottom: 24%;
  `};

  ${mediaMin.sm`
    margin-bottom: 20%;
  `};

  ${mediaMin.lg`
    margin-bottom: 18%;
  `};
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  transform: scale(1.5);
  transform-origin: bottom center;

  ${mediaMin.xs`
    transform: scale(1.4);
  `}

  ${mediaMin.md`
    transform: scale(1.4);
  `}

  ${mediaMin.lg`
    transform: scale(1.6);
  `}

  ${mediaMin.xlg`
    transform: scale(1.68);
  `}
`;

const ContentImg = styled.img`
  width: 100%;
`;

function KnowledgeNetworkedSection() {
  return (
    <Wrapper id="bi-directional-link">
      <Title>All the knowledge networked</Title>
      <Description>
        Build the second brain of your team. You can reference or
        <br />
        bi-directionally link other pages.
      </Description>
      <ContentWrapper>
        <ContentImg
          src={`${cdnImageBaseURL}/landing/product/networked-knowledge.png`}
        />
      </ContentWrapper>
    </Wrapper>
  );
}

export default KnowledgeNetworkedSection;
