import _ from 'lodash';
import React, { useRef } from 'react';
import styled from 'styled-components/macro';

import { mediaMin } from 'themes/media';
import { LogEventFns } from 'analytics/ga';
import SectionTitle from 'views/Common/SectionTitle';
import SectionDescription from 'views/Common/SectionDescription';
import useVideoSlideShow from 'views/Contents/customHooks/useVideoSlideShow';

import { SectionWrapper } from '../styled';
import Tab from './Tab';
import tabs from './tabs';

const BrOnlyDesktop = styled.br`
  display: none;
  ${mediaMin.md`
    display: block;
  `};
`;

const TabMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;

  ${mediaMin.md`
    margin-bottom: 60px;
    flex-direction: row;
  `};

  ${mediaMin.lg`
    max-width: 1000px;
    margin: 0 auto 60px;
  `};
`;

const StyledVideoWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;

  ${mediaMin.md`
    max-width: 1000px;
    margin: 0 auto;
  `}
`;

const StyledVideo = styled.video`
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  position: ${({ selected }) => (selected ? 'inherit' : 'absolute')};
  opacity: ${({ selected }) => (selected ? 1 : 0)};
`;

function InformationSection() {
  const videoRef = useRef({});
  const tabMenuRef = useRef(null);

  const { onClickTab, selectedVideoId } = useVideoSlideShow({
    tabs,
    tabMenuRef,
    videoRef,
  });

  const handleClickTab = id => {
    switch (id) {
      case 'knowledge-library-video':
        LogEventFns.product.clickKnowledgeLibraryButton();
        break;
      case 'in-app-highlighter-video':
        LogEventFns.product.clickInAppHighlighterButton();
        break;
    }
    onClickTab(id);
  };

  return (
    <SectionWrapper id="knowledge-library">
      <SectionTitle>Make full use of various information</SectionTitle>
      <SectionDescription>
        Additor supports various formats of content and follows natural
        knowledge workflow. <BrOnlyDesktop />
        Take advantage of the integration and compatibility.
      </SectionDescription>
      <TabMenu ref={tabMenuRef}>
        {_.map(tabs, ({ title, description, src, id }) => (
          <Tab
            key={id}
            id={id}
            title={title}
            description={description}
            src={src}
            onClick={handleClickTab}
            selected={selectedVideoId === id}
          />
        ))}
      </TabMenu>
      <StyledVideoWrapper>
        {_.map(tabs, ({ src, id }) => (
          <StyledVideo
            key={id}
            data-id={id}
            muted
            playsInline
            ref={element => (videoRef.current[id] = element)}
            src={src}
            selected={id === selectedVideoId}
          />
        ))}
      </StyledVideoWrapper>
    </SectionWrapper>
  );
}

export default InformationSection;
