import React from 'react';
import styled from 'styled-components/macro';
import { LogEventFns } from 'analytics/ga';
import { cdnImageBaseURL } from 'config/paths';
import colors from 'themes/colors';
import { ping } from 'themes/keyframes';
import { mediaMin } from 'themes/media';
import { scrollTo } from 'utils/animatedScrollTo';

import HeroDescription from 'views/Common/HeroDescription';
import HeroTitle from 'views/Common/HeroTitle';
import SignInToAdditor from 'views/Common/SignInToAdditor';
import helpers from 'themes/helpers';

const HeroWrapper = styled.div`
  padding-bottom: 0;
  ${mediaMin.xs`
    padding-bottom: 60px;
  `};
  ${mediaMin.sm`
    padding-bottom: 10px;
  `};
`;

const SignInWrapper = styled.div`
  padding: 0 16px;
`;

const ImageWrapper = styled.div`
  position: relative;
  margin: 80px auto 0 -30px;
  max-width: 1680px;
  height: 450px;

  ${mediaMin.xs`
    margin: 80px auto 0;
  `};

  ${mediaMin.sm`
    height: 575px
  `};
`;

const BackgroundWrapper = styled.div`
  height: 440px;
  position: relative;
  overflow: hidden;

  ${mediaMin.sm`
    height: 575px
  `};
`;

const WorkflowImage = styled.img`
  position: absolute;
  transform: translateX(-50%);
  width: 1680px;
  left: 50%;
`;

const WorkflowPageListDesktopImage = styled.img`
  position: absolute;
  width: 163px;
  top: 65px;
  left: 0;
  display: none;
  ${mediaMin.xs`
    display: block;
  `};
`;

const WorkflowPageListMobileImage = styled.img`
  position: absolute;
  width: 163px;
  top: 65px;
  left: 6px;
  ${mediaMin.xs`
    display: none;
  `};
`;

const ArrowImageLarge = styled.img`
  position: absolute;
  width: 636px;
  left: calc(50% - 310px);
  top: 165px;
  z-index: 1;

  display: none;
  ${mediaMin.xs`
    display: block;
  `};

  ${mediaMin.sm`
    width: 700px;
    left: calc(50% - 354px);
  `};
`;

const ArrowImageSmall = styled.img`
  position: absolute;
  width: 300px;
  left: calc(50% - 116px);
  top: 165px;
  z-index: 1;

  display: block;
  ${mediaMin.xs`
    display: none;  
  `};
`;

const PopupImage = styled.img`
  position: absolute;
  left: calc(50% + 37px);
  width: 174px;
  top: 50px;
  pointer-events: none;
`;

const PingWrapper = styled.div`
  ${helpers.centerFlex};
  position: absolute;
  width: 50px;
  height: 50px;
  left: calc(50% + 98px);
  top: 140px;
  cursor: pointer;
  z-index: 15;
`;

const Ping = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  animation: ${ping} 0.8s ease-in-out infinite forwards;
`;

const GradientBottom = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  height: 300px;
  background-image: linear-gradient(to top, ${colors.white}, ${colors.white0});
`;
const GradientLeft = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  width: 0;
  background-image: linear-gradient(
    to right,
    ${colors.white85},
    ${colors.white0}
  );
  ${mediaMin.xs`
    width: 70px;
  `};
  ${mediaMin.sm`
    width: 200px; 
  `};
  ${mediaMin.md`
    width: 330px; 
  `};
`;
const GradientRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  width: 0;
  background-image: linear-gradient(
    to left,
    ${colors.white85},
    ${colors.white0}
  );
  ${mediaMin.xs`
    width: 70px;
  `};
  ${mediaMin.sm`
    width: 200px; 
  `};
  ${mediaMin.md`
    width: 330px; 
  `};
`;

function HeroSection() {
  const scrollToNextSection = () => {
    LogEventFns.product.clickHeroPing();
    const nextSectionElement = document.querySelector(
      '[data-section-id="video-feedback-section"]',
    );
    if (!nextSectionElement) return;
    scrollTo(nextSectionElement.offsetTop, 500);
  };

  return (
    <HeroWrapper>
      <HeroTitle>
        Wiki that works best
        <br />
        for remote workflow
      </HeroTitle>
      <HeroDescription>
        Additor helps your team to collaborate asynchronously without friction.
        <br />
        No more double-check to catch up on the context.
      </HeroDescription>
      <SignInWrapper>
        <SignInToAdditor
          onClickEmailInput={LogEventFns.product.clickTopEmailInput}
          onSubmit={LogEventFns.product.clickTopGetStartedButton}
          onClickGoogleButton={LogEventFns.product.clickGoogleSignInButton}
          onClickSlackButton={LogEventFns.product.clickSlackSignInButton}
          onClickAppleButton={LogEventFns.product.clickAppleSignInButton}
        />
      </SignInWrapper>
      <ImageWrapper>
        <BackgroundWrapper>
          <WorkflowImage
            src={`${cdnImageBaseURL}/landing/product/workflow-background.png`}
            alt="workflow"
          />
          <WorkflowPageListDesktopImage
            src={`${cdnImageBaseURL}/landing/product/workflow-page-list.png`}
            alt="workflow-page-list"
          />
          <WorkflowPageListMobileImage
            src={`${cdnImageBaseURL}/landing/product/workflow-page-list-mobile.png`}
            alt="workflow-page-list"
          />
        </BackgroundWrapper>
        <ArrowImageLarge
          src={`${cdnImageBaseURL}/landing/product/arrow-1.png`}
          alt="arrow"
        />
        <ArrowImageSmall
          src={`${cdnImageBaseURL}/landing/product/arrow-3.png`}
          alt="arrow"
        />
        <PingWrapper onClick={scrollToNextSection}>
          <Ping />
        </PingWrapper>
        <PopupImage
          src={`${cdnImageBaseURL}/landing/product/workflow-popup.png`}
          alt="popup"
        />
        <GradientBottom />
        <GradientLeft />
        <GradientRight />
      </ImageWrapper>
    </HeroWrapper>
  );
}

export default HeroSection;
