import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { THEME, Link } from './index';
import { ReactComponent as AppStoreSvg } from './apple-store-button.svg';

function AppStoreButton({ onClick = _.noop, theme = THEME.LIGHT.name }) {
  return (
    <Link
      href="https://apps.apple.com/app/additor/id1442745168"
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
      color={THEME[theme].color}
      borderColor={THEME[theme].borderColor}
      hoverBackgroundColor={THEME[theme].hoverBackgroundColor}
    >
      <AppStoreSvg />
    </Link>
  );
}

AppStoreButton.propTypes = {
  onClick: PropTypes.func,
  theme: PropTypes.oneOf([THEME.BRAND.name, THEME.LIGHT.name]),
};

export default AppStoreButton;
