import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { validate as isEmail } from 'isemail';
import styled from 'styled-components/macro';

import colors from 'themes/colors';
import { mediaMin, mediaMax } from 'themes/media';
import { postGetStarted } from 'api/getStarted';
import { ButtonLoadingSVG } from 'resources';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const InputWrapper = styled.div`
  position: relative;
  flex: 1;
  min-width: 202px;
  max-width: 280px;

  ${mediaMin.xs`
    max-width: 350px;
  
  `};
`;

const Input = styled.input`
  height: 45px;
  width: 100%;
  background-color: ${colors.gray80};
  padding: 4px 0 0 22px;
  font-size: 15px;
  color: ${colors.gray800};
  font-family: ObjectSans, sans-serif;
  outline: none;
  box-sizing: border-box;
  border: none;
  border-radius: 22.5px 0 0 22.5px;
  transition: all 0.1s ease;

  &::placeholder {
    color: ${colors.gray500};
  }

  ${mediaMax.xs`
    width: calc(100% - 6px);
    margin-right: 6px;
  `}
`;

const GetStartedButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  border-radius: 22.5px;
  margin: 0 0 0 -22.5px;
  font-weight: 500;
  z-index: 1;
  border: none;
  outline: none;
  background-color: ${colors.brand500};
  font-family: ObjectSans, sans-serif;
  font-size: 16px;
  padding: 4px 25px 0;
  color: ${colors.white};
  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;

  &:hover {
    background-color: ${colors.brand700};
  }

  ${mediaMin.xs`
    font-size: 17px;
  `}
`;

const InvalidMessage = styled.div`
  position: absolute;
  font-size: 12px;
  bottom: -18px;
  color: ${colors.red550};
`;

function EmailInput({ onClickEmailInput = _.noop, onSubmit = _.noop }) {
  const history = useHistory();
  const [inputValue, setInputValue] = useState('');
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitEmail = useCallback(() => {
    if (isEmail(inputValue)) {
      setIsLoading(true);
      postGetStarted(inputValue).then(res => {
        if (res.ok) {
          history.push(`/mail-sent?email=${encodeURIComponent(inputValue)}`);
        }
      });
    } else {
      setIsInvalidEmail(true);
    }
    onSubmit();
  }, [inputValue]);

  const handleChange = useCallback(e => {
    setInputValue(e.target.value);
    setIsInvalidEmail(false);
  }, []);

  const handleKeyDown = useCallback(
    e => {
      if (e.key === 'Enter') {
        handleSubmitEmail();
      }
    },
    [inputValue],
  );

  return (
    <Wrapper className="email-input__wrapper">
      <InputWrapper className="email-input__input-wrapper">
        <Input
          className="email-input__input"
          value={inputValue}
          onChange={handleChange}
          onClick={onClickEmailInput}
          onKeyDown={handleKeyDown}
          placeholder="Email Address"
        />
        {isInvalidEmail && (
          <InvalidMessage>
            The email address is in an invalid format.
          </InvalidMessage>
        )}
      </InputWrapper>
      <GetStartedButton
        className="email-input__get-started"
        onClick={handleSubmitEmail}
      >
        {isLoading ? <ButtonLoadingSVG /> : 'Get started'}
      </GetStartedButton>
    </Wrapper>
  );
}

EmailInput.propTypes = {
  onClickEmailInput: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default EmailInput;
